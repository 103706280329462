import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { listAdapter } from '@/_api/AppConfiguration/listAdapter'

/**
 * Иногда требуется не весь справочник, а только несколько записей.
 * Для получения этого списка будет создан отдельный листовой роут
 */
const META_CATALOGS_ROUTES = {
  metaCatalogRadiationExposure: Routes.short_list_api_internal_nsi_dictionaries_units_path,
}

export class MNSIPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.nsi_dictionaries_entry_path,
        all () {},
        list: Routes.list_nsi_dictionaries_entries_path,
      },
      entity: 'nsi',
      location: 'MNSIPresenter',
    })
  }

  fetchConfig () {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'nsi'
    )

    return this.get({
      url: Routes.nsi_dictionaries_info_path(),
    }).promise
      .catch(MRequestError.withDefault(null, notification))
  }

  fetchEntry (id) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'nsi'
    )

    return this.get({
      url: Routes.nsi_dictionaries_entry_path(id),
    }).promise
      .catch(MRequestError.withDefault(null, notification))
  }

  update (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'updateMessage',
      'nsi'
    )

    return this.patch({
      url: Routes.app_configuration_path(1),
      data: camelToSnake({
        appConfiguration: {
          nsiIdCardTypes: data.idCardTypes.enabled,
          nsiRussianSubjects: data.russianSubjects.enabled,
          nsiUserKey: data.nsiUserKey,
        },
      }),
    }).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }

  ping () {
    const notification = new MRequestNotification(
      this.location,
      'pingMessage',
      'nsi'
    )

    return this.post({
      url: Routes.nsi_dictionaries_ping_path(),
    }).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }

  list (listData, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'nsiCatalogsMessage',
      listData.dictionary
    )

    return this.post({
      url: META_CATALOGS_ROUTES[listData.dictionary]?.() || this.routes.list(),
      data: JSON.stringify(camelToSnake({ sortParams: listData })),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    }).promise
      .then((response) => listAdapter.toClient(response, listData))
      .catch(MRequestError.onRequest(notification))
  }
}
