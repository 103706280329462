export const NETRIKA_CATALOGS = Object.freeze({
  SPECIALITIES: 'specialities',
  POSITIONS: 'positions',
  MKB_DIAGNOSIS_GROUPS: 'mkb_diagnosis_groups',
  RELATION_TYPES: 'relation_types',
  CASE_VISIT_TYPES: 'case_visit_types',
  CASE_TYPES: 'case_types',
  CONFIDENTIALITIES: 'confidentialities',
  CASE_RESULTS: 'case_results',
  DIAGNOSIS_TYPES: 'diagnosis_types',
  VISIT_PLACES: 'visit_places',
  VISIT_PURPOSES: 'visit_purposes',
  PAYMENT_TYPES: 'payment_types',
  MED_DOCUMENT_TYPES: 'medical_document_types',
})
