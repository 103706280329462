import { cloneDeep } from 'lodash'

const INIT_FILTERS_VALUE = {}
export const DEFAULT_SEARCH_VALUE = ''
export const DEFAULT_OFFSET = 0
export const DEFAULT_LIMIT = 25
export const DEFAULT_REPORT_LIMIT = 256
export const DEFAULT_CURRENT_PAGE = 1
export const DEFAULT_TOTAL_PAGES = 1
export const DEFAULT_TOTAL_ITEMS = 0
export const DEFAULT_FILTRATION_DEBOUNCE = 500

const setPage = (state, page) => {
  state.currentPage = page
  state.offset = (page - 1) * state.limit
}

/**
 * Модуль для фильтрации, пагинации и поиска.
 * Позволяет заинжектить геттеры и мутации и дергать их из модуля-родителя.
 * Инициализация: вызов vxResetFilters с Object параметром.
 * Пример:
 *
 * export const CLIENTS_FILTERS_FIELDS = Object.freeze({
 *   PERIOD: 'period',
 *   STATUS: 'status',
 * })
 *
 * export const getDefaultClientsFilters = () => ({
 *   [CLIENTS_FILTERS_FIELDS.PERIOD]: null,
 *   [CLIENTS_FILTERS_FIELDS.STATUS]: null,
 * })
 *
 * vxResetFilters(getDefaultClientsFilters())
 *
 * Данный метод лучше всего вызывать на created хуке компонета.
 *
 *
 * После вызова все фильтры, геттеры и мутации
 * будут доступны по пути 'parentModuleName/filtersBase'
 *
 * ВНИМАНИЕ: ЭТО ТОЛЬКО ДЛЯ ИЗМЕНЯЕМЫХ ФИЛЬТРОВ!!!
 * Например: client_id в медкарте инжектить ОТДЕЛЬНО, тк он постоянный
 */
export const filtersBase = {
  namespaced: true,
  state: () => ({
    filters: INIT_FILTERS_VALUE,
    search_string: DEFAULT_SEARCH_VALUE,
    offset: DEFAULT_OFFSET,
    limit: DEFAULT_LIMIT,

    currentPage: DEFAULT_CURRENT_PAGE,
    totalPages: DEFAULT_TOTAL_PAGES,
  }),

  getters: {
    /**
     * Геттер фильтрации, агрегация всех атрибутов, на которую можно повесить вотчер
     * @param state
     * @return {{offset: number, limit: number, search_string: string}}
     */
    vxGetFilters (state) {
      return ({
        ...state.filters,
        search_string: state.search_string,
        offset: state.offset,
        limit: state.limit,
      })
    },

    /**
     * Скан всех фильтров на непустоту
     * @param state
     * @return {boolean}
     */
    vxGetIsFiltered (state) {
      let isFiltered = false
      Object.keys(state.filters).forEach((key) => {
        const filter = state.filters[key]

        isFiltered = isFiltered ||
          Boolean(
            (filter && filter.length) ||
            (filter && !Array.isArray(filter) && typeof filter !== 'string')
          )
      })

      return Boolean(
        state.search_string.length ||
        state.offset ||
        isFiltered
      )
    },

    vxGetCurrentPage (state) {
      return state.currentPage
    },

    vxGetTotalPages (state) {
      return state.totalPages
    },
  },

  mutations: {
    vxRefreshFilters (state) {
      state.filters = cloneDeep(state.filters)
    },

    /**
     * Сброс фильтров.
     * Если непустой параметр filters, то произойдет [пере]инициализация фильтров
     * @param state
     * @param {Object|null} filters
     */
    vxResetFilters (state, filters = null) {
      state.filters = filters || state.filters
      state.search_string = DEFAULT_SEARCH_VALUE
      setPage(state, DEFAULT_CURRENT_PAGE)
    },

    vxSetSearchValue (state, value) {
      state.search_string = value
      setPage(state, DEFAULT_CURRENT_PAGE)
    },

    /**
     * Основной метод управления значениями фильтров
     * @param state
     * @param {String} filter
     * @param {any} value
     */
    vxSetFilterValue (state, { filter, value }) {
      state.filters[filter] = value
      setPage(state, DEFAULT_CURRENT_PAGE)
    },

    vxSetTotalPages (state, totalPages) {
      state.totalPages = totalPages
    },

    /**
     * Мне не нравится работать с оффсетом, пусть оно само считается, пагинация рулит
     * @param state
     * @param page
     */
    vxSetPage (state, page) {
      setPage(state, page)
    },

    vxResetPagination (state) {
      state.totalPages = DEFAULT_TOTAL_PAGES
      setPage(state, DEFAULT_CURRENT_PAGE)
    },
  },
}
