import { capitalize } from 'lodash'

const OMS_DMS_AND_OTHER = 4

export const FORBIDDEN_CASE_TYPES = [
  'госпитализация',
  'скорая помощь',
  'телемедицинская консультация',
  'мастер-случай',
]

export const catalogAdapter = {
  toClient (data) {
    return data
      .filter((item) => !FORBIDDEN_CASE_TYPES.includes(item.title.toLowerCase()))
      .map((item) => ({
        id: item.id,
        title: item.title.length > OMS_DMS_AND_OTHER
          ? capitalize(item.title)
          : item.title,
      }))
  },
}
