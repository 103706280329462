import { request } from '@/lib/transport/request'
import { HTTP_METHODS } from '@/lib/transport/MRequest'
import {
  fetchRemindersAdapter,
  fetchResponsiblesListAdapter,
  usersAdapter,
} from '@/vue_components/doctor_schedules/adapters'

export const scheduleInitData = (params) => {
  return request({
    type: 'GET',
    url: Routes.doctor_schedules_init_path(),
    data: params,
  }).promise
}

export const fetchSpecialties = (clinicId) => {
  return request({
    type: 'GET',
    url: Routes.fetch_specialties_api_internal_doctor_schedules_path(),
    data: { clinic_id: clinicId },
  }).promise
}

export const fetchAppointmentUsers = (params, toClientAdapter = (data) => data) => {
  const options = {
    type: 'GET',
    url: Routes.fetch_appointment_users_api_internal_doctor_schedules_path(),
    data: params,
  }

  return request(options, toClientAdapter).promise
}

export const fetchCabinets = (params) => {
  return request({
    type: 'GET',
    url: Routes.fetch_cabinets_api_internal_doctor_schedules_path(),
    data: params,
  }).promise
}

export const fetchAttractionSourcesList = (params) => {
  return request({
    type: 'GET',
    url: Routes.fetch_attraction_sources_api_internal_doctor_schedules_path(),
    data: params,
  }).promise
}

/**
 * @param {{
 *   surname?: string,
 *   limit?: number,
 *   offset?: number,
 *   clinic_id?: number
 * }} params
 * @return {Promise<{pageCount: number, doctors: {id: number, short_name: string}[]}>}
 */
export const fetchDoctorsList = (params = {}) => {
  const options = {
    type: HTTP_METHODS.GET,
    url: Routes.fetch_attending_doctors_api_internal_doctor_schedules_path(),
    data: params,
  }

  return request(options, usersAdapter.toClient).promise
}

export const fetchReferralsList = (params) => {
  const options = {
    type: HTTP_METHODS.GET,
    url: Routes.fetch_referrals_api_internal_doctor_schedules_path(),
    data: params,
  }

  return request(options, usersAdapter.toClient).promise
}

export const createReferral = (params) => {
  return request({
    type: 'POST',
    url: Routes.referrals_path(),
    data: params,
  }).promise
}

/**
 * @param {{
 *   search_query?: string,
 *   limit?: number,
 *   offset?: number,
 *   clinic_id?: number
 * }} params
 * @return {Promise<{pageCount: number, doctors: {id: number, short_name: string}[]}>}
 */
export const fetchResponsiblesList = (params) => {
  const options = {
    type: HTTP_METHODS.GET,
    url: Routes.fetch_responsible_api_internal_doctor_schedules_path(),
    data: params,
  }

  return request(options, fetchResponsiblesListAdapter.toClient).promise
}

export const fetchClinicsWorkload = (params) => {
  return request({
    type: 'GET',
    url: Routes.doctor_schedules_fetch_clinics_workload_path(),
    data: params,
  }).promise
}

export const fetchWorkTimesByUser = (params) => {
  return request({
    type: 'GET',
    url: Routes.fetch_user_work_times_work_times_path(),
    data: params,
  }).promise
}
/**
 * @typedef {Record<string, any>} Appointment
 * @typedef {[string, string]} WorkTimeRange
 * @typedef {{
 *   appointments: Appointment[],
 *   schedule: Record<string, Record<string, WorkTimeRange[]>>[],
 *   schedule_time_range: { start: string, end: string },
 *   user_ids: string[]
 * }} ScheduleData
 */
/**
 * Возвращает данные для расписания.
 * `user_ids` должен содержать массив айдишников, сериализованный в json,
 * иначе будет возвращён пустой массив ворктаймов для юзеров.
 *
 * @param {{
 *  date: string,
 *  user_ids: string,
 *  days_count: number,
 *  only_working_days: boolean,
 *  clinic_id: number,
 * }} params
 * @return {Promise<ScheduleData>}
 */
export const fetchDoctorAppointments = (params) => {
  return request({
    type: 'POST',
    url: Routes.query_appointments_path(),
    data: params,
  }).promise
}

export const fetchCabinetsAppointments = (params) => {
  return request({
    type: 'POST',
    url: Routes.cabinets_query_reception_cabinets_path(),
    data: params,
  }).promise
}

export const fetchAppointmentById = (appointmentId) => {
  return request({
    type: 'GET',
    url: Routes.fetch_appointment_by_id_appointments_path(),
    data: { appointment_id: appointmentId },
  }).promise
}

export const getAppointmentLastSms = (appointmentId) => {
  return request({
    type: 'GET',
    url: Routes.appointment_fetch_last_messages_path({ appointment_id: appointmentId }),
  }).promise
}

export const fetchAppointmentByClient = (clientId) => {
  return request({
    type: 'POST',
    url: Routes.fetch_appointments_appointments_path(),
    data: { client_id: clientId },
  }).promise
}

export const createAppointment = (params) => {
  return request({
    type: 'POST',
    url: Routes.appointments_path(),
    data: params,
  }).promise
}

export const updateAppointment = (appointmentId, params) => {
  return request({
    type: 'PATCH',
    url: Routes.appointment_path(appointmentId),
    data: params,
  }).promise
}

export const updateAppointmentStatus = (params) => {
  return request({
    type: 'POST',
    url: Routes.change_appointment_status_appointments_path(),
    data: params,
  }).promise
}

/**
 * @param {{
 *   title?: string,
 *   for_current_clinics?: Boolean,
 *   clinic_ids?: number[],
 *   user_id?: number,
 * }} params
 * @return {Promise<*>}
 */
export const searchEntries = (params) => {
  return request({
    type: 'GET',
    url: Routes.search_entry_types_path(params),
  }).promise
}

export const findEntries = (params) => {
  return request({
    type: 'GET',
    url: Routes.search_capable_users_api_internal_doctor_schedules_path(),
    data: params,
  }).promise
}

export const findEntriesAndClient = (params) => {
  return request({
    type: 'POST',
    url: Routes.user_services_search_clients_and_entry_types_path(),
    data: params,
  }).promise
}

export const findClients = (params) => {
  return request({
    type: 'GET',
    url: Routes.filter_clients_path(params),
  }).promise
}

export const searchClients = (query) => {
  return request({
    type: 'GET',
    url: Routes.select_search_clients_path(),
    data: {
      query,
    },
  }).promise
}

export const fetchClientById = (clientId) => {
  return request({
    type: 'GET',
    url: Routes.find_client_path(clientId),
  }).promise
}

export const fetchClientByPhone = (params) => {
  return request({
    type: 'POST',
    url: Routes.clients_find_by_phone_path(),
    contentType: 'application/json',
    data: params,
  }).promise
}

export const fetchUserWaitingList = (params) => {
  return request({
    type: 'POST',
    url: Routes.users_waiting_list_items_waiting_lists_path(),
    data: params,
  }).promise
}

export const fetchUsersWaitingList = (params) => {
  return request({
    type: 'POST',
    url: Routes.list_waiting_lists_path(),
    data: params,
  }).promise
}

export const fetchWaitingListCount = (params) => {
  const options = {
    type: HTTP_METHODS.POST,
    url: Routes.list_count_waiting_lists_path(),
    data: params,
  }

  return request(options, parseInt).promise
}

export const fetchWaitingListItem = (id) => {
  return request({
    type: 'GET',
    url: Routes.fetch_waiting_lists_path({ waiting_item_id: id }),
  }).promise
}

export const createWaitingItem = (params) => {
  return request({
    type: 'POST',
    url: Routes.waiting_lists_path(),
    data: params,
  }).promise
}

export const updateWaitingItem = (id, params) => {
  return request({
    type: 'PATCH',
    url: Routes.waiting_list_path(id),
    data: params,
  }).promise
}

export const fetchOnlineCount = (params) => {
  return request({
    type: 'GET',
    url: Routes.fetch_online_recording_appointments_count_appointments_path(),
    data: params,
  }).promise
}

/**
 * @param {{date: string, current_clinic_id: number, offset: number, limit: number}} params
 * @return {JQuery.jqXHR} jqXHR вместо промиса, потому что этот запрос может быть отменён
 */
export const fetchReminders = (params) => {
  const options = {
    type: HTTP_METHODS.POST,
    url: Routes.list_reminders_path(),
    data: params,
  }

  return request(options, fetchRemindersAdapter.toClient).ajax
}

export const fetchRemindersCount = (params) => {
  return request({
    type: 'POST',
    url: Routes.reminders_count_appointments_path(),
    data: params,
  }).promise
}

export const createReminder = (params) => {
  return request({
    type: 'POST',
    url: Routes.reminders_path(),
    data: params,
  }).promise
}

export const updateReminder = (id, params) => {
  return request({
    type: 'PATCH',
    url: Routes.reminder_path(id),
    data: params,
  }).promise
}

export const fetchCallLogData = (params) => {
  return Promise.all([
    fetchAppointmentUsers(params),
    fetchSpecialties(params.clinic_id),
  ]).then(([doctors, specialties]) => ({
    doctors,
    specialties: specialties.map(([id, title]) => ({ id, title })),
  }))
}

export const fetchTimeFinderData = (params) => {
  return Promise.all([
    fetchAppointmentUsers(params),
    fetchSpecialties(params),
  ]).then(([doctors, specialties]) => ({
    doctors,
    specialties: specialties.map(([id, title]) => ({ id, title })),
  }))
}

export const findEntryTypes = ({ forCurrentClinics, clinicIds, searchQuery }) => {
  return request({
    url: Routes.search_entry_types_path(),
    method: HTTP_METHODS.POST,
    data: {
      for_current_clinics: forCurrentClinics,
      clinic_ids: clinicIds,
      title: searchQuery,
    },
  })
}

/**
 * @param {number} id
 * @return {Promise<unknown>}
 */
export const deleteWaitingListItem = (id) => {
  return request({
    url: Routes.waiting_list_path(id),
    method: 'DELETE',
  }).promise
}
