export const appointment = {
  namespaced: true,
  state: {
    movedAppointment: null,
  },

  actions: {
    resetForm ({ commit }) {
      commit('SET_MOVED_APPOINTMENT', null)
    },
  },

  mutations: {
    SET_MOVED_APPOINTMENT (state, item) {
      state.movedAppointment = item
    },
  },

  getters: {
    GET_MOVED_APPOINTMENT (state) {
      return state.movedAppointment
    },
  },
}
