import { GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { iconsMap } from '@/modules/utils/medods_ui/iconsMap'

export const entryTypesKindsIcons = Object.freeze({
  [GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS]: iconsMap.analysis,
  [GLOBAL_ENTRY_TYPES_KINDS.SERVICE]: iconsMap.service,
  [GLOBAL_ENTRY_TYPES_KINDS.GOOD]: iconsMap.good,
  [GLOBAL_ENTRY_TYPES_KINDS.COMPLEX]: iconsMap.complex,
})

export const entryTypesIconMap = Object.freeze({
  [GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS]: 'analysis',
  [GLOBAL_ENTRY_TYPES_KINDS.SERVICE]: 'service',
  [GLOBAL_ENTRY_TYPES_KINDS.GOOD]: 'good',
  [GLOBAL_ENTRY_TYPES_KINDS.COMPLEX]: 'complex',
})
