import { reportSuccessText } from '@/_api/_requests/helpers'

export class MRequestSuccess {
  /**
   * @param dataAdapter
   * @return {function(*): Promise<any>}
   */
  static onResponse (dataAdapter = (data) => data) {
    return (data) => Promise.resolve(dataAdapter(data))
  }

  /**
   * @param {Function} dataAdapter
   * @param {MRequestNotification} notification
   * @return {function(*): Promise<any>}
   */
  static withNotify (dataAdapter = (data) => data, { message, entity }) {
    return (data) => {
      if (message && entity) {
        Utils.reportSuccess(reportSuccessText(message, entity))()
      }

      return Promise.resolve(dataAdapter(data))
    }
  }
}
