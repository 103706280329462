import { MRequestHandler } from '@/_api/_requests/MRequestHandler'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { pick } from 'lodash'
import { CRUD_TO_HTTP } from '@/_api/_requests/const'
import { isWithoutSuccessNotify } from '@/_api/_requests/helpers'

/**
 * @typedef {{
 * location: String,
 * url: String,
 * data?: any,
 * toClientAdapter?: Function,
 * type?: 'get' | 'post' | 'patch' | 'delete',
 * dataType?: String,
 * contentType?: String
 * }} MRequestWrapperConfig
 */

/**
 * @typedef {{
 *   errors: Record<string, string[]>
 * }} RequestErrors
 */

export class MRequestWrapper extends MRequestHandler {
  /**
   * @param {MRequestWrapperConfig} config
   * @return MRequestWrapper
   */
  constructor (config) {
    super()
    this.config = config
  }

  /**
   * @return {Promise<*|RequestErrors>}
   */
  buildQuery () {
    const [, entity, CRUDMethod] = this.__useLocationParser(this.config.location)
    if (!CRUDMethod) { throw new Error('Не удалось определить CRUDMethod') }

    const HTTPMethod = this.config.type || CRUD_TO_HTTP[CRUDMethod]
    if (!HTTPMethod) { throw new Error('Не удалось определить HTTPMethod') }

    const notification = new MRequestNotification(this.config.location, `${CRUDMethod}Message`, entity)

    if (isWithoutSuccessNotify(CRUDMethod)) {
      return this.__onWithoutSuccessNotify(HTTPMethod, notification)
    }

    return this.__onSuccessNotificationRequired(HTTPMethod, notification)
  }

  __onWithoutSuccessNotify (method, notification) {
    return this[method](this.__permitParams(this.config)).promise
      .then(MRequestSuccess.onResponse(this.config.toClientAdapter))
      .catch(MRequestError.onRequest(notification))
  }

  __onSuccessNotificationRequired (method, notification) {
    return this[method](this.__permitParams(this.config)).promise
      .then(MRequestSuccess.withNotify(this.config.toClientAdapter, notification))
      .catch(MRequestError.onRequest(notification))
  }

  /**
   * @param {string}location
   * @return {[presenterName: string, entity: string, CRUDMethod: string]}
   * @private
   */
  __useLocationParser (location = '::::') {
    return location.split('::')
  }

  /**
   * @param {MRequestWrapperConfig} config
   * @return {{ url: string, data: any, dataType: string, contentType: string }}
   * @private
   */
  __permitParams (config) {
    return pick(config, ['url', 'data', 'dataType', 'contentType'])
  }
}
