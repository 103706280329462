import { snakeToCamel } from '@/_api/_requests/helpers'
import { request } from '@/lib/transport/request'
import { HTTP_METHODS } from '@/_api/_requests/const'

/**
 * @typedef {{
 *   url: string,
 *   data?: any
 * }} TRequestOptions
 */

export class MRequestHandler {
  /**
   * @param {TRequestOptions} options
   * @private
   */
  __optionsGuard (options) {
    if (options.url) { return }
    throw new Error('Requested url not found')
  }

  /**
   * @param {TRequestOptions} options
   * @param {Function} toClientAdapter
   * @return {{promise: Promise<*>, ajax: jqXHR}}
   * @protected
   */
  get (options, toClientAdapter = snakeToCamel) {
    this.__optionsGuard(options)

    return request({ ...options, type: HTTP_METHODS.GET }, toClientAdapter)
  }

  /**
   * @param {TRequestOptions} options
   * @param {Function} toClientAdapter
   * @return {{promise: Promise<*>, ajax: jqXHR}}
   * @protected
   */
  post (options, toClientAdapter = snakeToCamel) {
    this.__optionsGuard(options)

    return request({ ...options, type: HTTP_METHODS.POST }, toClientAdapter)
  }

  /**
   * @param {TRequestOptions} options
   * @param {Function} toClientAdapter
   * @return {{promise: Promise<*>, ajax: jqXHR}}
   * @protected
   */
  patch (options, toClientAdapter = snakeToCamel) {
    this.__optionsGuard(options)

    return request({ ...options, type: HTTP_METHODS.PATCH }, toClientAdapter)
  }

  /**
   * @param {TRequestOptions} options
   * @param {Function} toClientAdapter
   * @return {{promise: Promise<*>, ajax: jqXHR}}
   * @protected
   */
  delete (options, toClientAdapter = snakeToCamel) {
    this.__optionsGuard(options)

    return request({ ...options, type: HTTP_METHODS.DELETE }, toClientAdapter)
  }
}
