import NumberFormatter from './NumberFormatter'
import DateFormatter from './DateFormatter'
import BaseFormatter from './BaseFormatter'
import { HtmlFormatter } from './HtmlFormatter'

/**
 * Service locator for formatters, keyed by `namespace` static field
 */
class FormattersLocator {
  constructor () {
    /**
     * @readonly
     * @type {Map<string, typeof AbstractFormatter>}
     */
    this._formatters = new Map([
      [NumberFormatter.namespace, NumberFormatter],
      [DateFormatter.namespace, DateFormatter],
      [BaseFormatter.namespace, BaseFormatter],
      [HtmlFormatter.namespace, HtmlFormatter],
    ])
    this._instances = new Map()
  }

  /**
   * Get formatter class by namespace
   *
   * @param namespace
   * @param {any[]} args
   * @return {AbstractFormatter|undefined}
   */
  get (namespace, args = []) {
    if (this._instances.has(namespace)) {
      return this._instances.get(namespace)
    }
    if (this._formatters.has(namespace)) {
      const constructor = this._formatters.get(namespace)
      const instance = new constructor(...args)
      this._instances.set(namespace, instance)

      return instance
    }
    throw new Error(`No formatter defined with namespace: '${namespace}'`)
  }

  /**
   *
   * @param {string} namespace
   * @param {typeof AbstractFormatter} Formatter
   */
  set (namespace, Formatter) {
    this._formatters.set(namespace, Formatter)
  }

  /**
   *
   * @param {AbstractFormatter} Formatter
   */
  define (Formatter) {
    // use index access to prevent editor from complaining
    this.set(Formatter['namespace'], Formatter)
  }
}
const locator = new FormattersLocator()
export default locator
