import { PAGE_SIZE } from './emdState'

export const getters = {
  GET_IS_FOR_CURRENT_USER: (state, getters, rootState) =>
    state.filtersBase.filters.author_id === rootState.appConf.current_user.id,

  GET_PAGE_SIZE: (state) => PAGE_SIZE,

  GET_MED_DOCUMENT_TYPES: (state) => state.emdTypes || [],

  GET_ACTIVE_ENTRY_EMD: (state) => state.activeEntryEmd,

  GET_IS_FILTERED (state) {
    const f = state.filters

    return Boolean(
      (f.period && f.period.length === 2) ||
      f.specialty_id ||
      f.author_id ||
      f.offset
    )
  },
}
