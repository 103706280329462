import { HTTP_METHODS, MRequest } from '@/lib/transport/MRequest'

class RecordingPointsEndpoint extends MRequest {
  fetchAll (data) {
    const options = {
      type: HTTP_METHODS.POST,
      url: Routes.search_recording_points_path(),
      data,
    }

    return this.getAll(options).promise
  }
}

export const recordingPointsEndpoint = new RecordingPointsEndpoint()
