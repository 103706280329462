import { clinicEndpoint } from '@/api_entities/clinics/clinic_endpoint'

export const clinicStore = {
  namespaced: true,
  state: () => ({
    clinicsInFrmo: [],
  }),

  getters: {
    vxGetClinicsInFrmo: (state) => state.clinicsInFrmo,
    vxGetClinicInFrmo: (state) =>
      (clinicId) => state.clinicsInFrmo.find((clinic) => clinic.id === clinicId) || ({}),
  },

  mutations: {
    vxSetClinicsInFrmo (state, payload) {
      state.clinicsInFrmo = payload
    },
  },

  actions: {
    /**
     * @param commit
     * @param rootGetters
     * @param userId
     * @return {Promise<LoadClinicsInFrmoDto[]>}
     */
    vxLoadClinicsInFrmo ({ commit, rootGetters }, userId = null) {
      const id = userId || rootGetters.GET_APP_CONF_CURRENT_USER_ID

      return clinicEndpoint.getFrmoActiveByUserId(id)
        .then(({ data }) => { commit('vxSetClinicsInFrmo', data) })
        .catch(Utils.reportError('store:clinicStore:vxLoadClinicsInFrmo()'))
    },
  },
}
