import { loadNPSDymanics } from '@/vue_components/client_feedbacks/nps/rest.js'

const state = {
  NPSDynamics: [],
}

const getters = {
  GET_NPS_DYNAMICS (state) {
    return state.NPSDynamics
  },
}

const mutations = {
  SET_NPS_DYNAMICS (state, dynamics) {
    state.NPSDynamics = dynamics
  },
}

const actions = {
  LOAD_NPS_DYNAMICS ({ commit }, params) {
    return loadNPSDymanics(params)
      .then(
        (response) => {
          commit('SET_NPS_DYNAMICS', response)

          return response
        },
      )
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
