import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import {
  CALCULATED_ENTRY_STATUS,
  CALCULATED_ENTRY_STATUS_ICONS,
  getCalculatedEntryNextState,
} from '@/vue_apps/CheckupsModule/store/checkupCalculatedEntries'
import {
  CheckupCalculatedEntryCellBase,
} from '@/vue_apps/CheckupsModule/classes/CheckupCalculatedEntry/CheckupCalculatedEntryCellBase'
import { PersonalCheckupEntryItem } from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupEntryItem'

export class PersonalCheckupCalculatedEntryCell extends CheckupCalculatedEntryCellBase {
  /**
   * @param {ICheckupCalculatedEntry} calculatedEntry
   */
  constructor (calculatedEntry) {
    super(calculatedEntry)

    this.entry
      ? this.setEntry(this.entry)
      : this._appendAction()

    this._updateStyles()
  }

  _updateStyles () {
    this.title.addClasses(this.status === CALCULATED_ENTRY_STATUS.CANCELED ? 'striked' : '')
  }

  _updateAction (icon) {
    this.action = this.action
      .addTypeIcon(icon)
      .addTooltip(t(this.status, { scope: 'checkups.invertedEntryStatuses' }))
  }

  _appendAction () {
    this.action = new MTableCell()
      .addTypeIcon(CALCULATED_ENTRY_STATUS_ICONS[this.status])
      .addTooltip(t(this.status, { scope: 'checkups.invertedEntryStatuses' }))
  }

  setEntry (entry) {
    this.action = null
    this.entry = new PersonalCheckupEntryItem(entry)
  }

  removeEntry () {
    this.entry = null
    this._appendAction()
  }

  trigger () {
    if (this.entry) { return }

    const next = getCalculatedEntryNextState(this.status)
    this.status = next.status
    this._updateAction(next.icon)
    this._updateStyles()
  }
}
