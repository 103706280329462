<template>
  <div :class="containerClass">
    <ul :class="listClass">
      <li
        v-for="item in items"
        :key="item[idItemField]"
        v-tooltip="item.tooltip"
        :data-id="item[idItemField]"
        :class="[{active: selectedItemsIds.includes(item[idItemField])}, { disabled }, itemClass, { hidden: item.hidden }]"
        @click="!item.disabled && itemOnClick(item[idItemField])"
      >
        {{ item[displayedItemField] }}
      </li>
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ItemsList',

  model: {
    prop: 'selectedItemsIds',
    event: 'change',
  },

  props: {
    items: {
      type: Array,
      default: undefined,
    },

    selectedItemsIds: {
      type: Array,
      default: undefined,
    },

    multiSelect: {
      type: Boolean,
      default: true,
    },

    maxSelectedItems: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },

    minSelectedItems: {
      type: Number,
      default: 0,
    },

    idItemField: {
      type: String,
      default: undefined,
    },

    displayedItemField: {
      type: String,
      default: undefined,
    },

    containerClass: {
      type: String,
      default: 'vue-items-list',
    },

    listClass: {
      type: String,
      default: 'vue-list',
    },

    itemClass: {
      type: String,
      default: 'vue-list-item',
    },

    unpressed: Boolean,
    disabled: Boolean,
  },

  created () {
    if (!this.multiSelect && this.selectedItemsIds.length > 1) {
      this.selectedItemsIds = this.selectedItemsIds.splice(1, this.selectedItemsIds.length)
      this.$emit('change', this.selectedItemsIds)
    }
  },

  methods: {
    itemOnClick (id) {
      if (this.disabled) return

      const selectedItemsIds = this.selectedItemsIds.slice(0)
      const index = selectedItemsIds.indexOf(id)

      if (!this.multiSelect) {
        if (this.unpressed && index !== -1) {
          selectedItemsIds.splice(index, 1)
        } else {
          selectedItemsIds.length = 0
          selectedItemsIds.push(id)
        }
      } else {
        if (index === -1) {
          if (selectedItemsIds.length === this.maxSelectedItems) return

          selectedItemsIds.push(id)
        } else {
          if (selectedItemsIds.length === this.minSelectedItems) return

          selectedItemsIds.splice(index, 1)
        }
      }

      this.$emit('change', selectedItemsIds)
    },
  },
}
</script>
