import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { personalCheckupAdapter } from '@/vue_apps/CheckupsModule/api/MPersonalCheckupPresenter/personalCheckupAdapter'
import { camelToSnake } from '@/_api/_requests/helpers'
import { extractId } from '@/_medods_standart_library/msl'
import { parseInt } from 'lodash'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import {
  PersonalCheckupCalculatedEntryCell,
} from '@/vue_apps/CheckupsModule/classes/CheckupCalculatedEntry/PersonalCheckupCalculatedEntryCell'
import { GLOBAL_GET_DEFAULT_SEXES } from '@/_global_scripts/GLOBAL_CONSTS'

export class MPersonalCheckupPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: {
        one: Routes.checkups_personal_path,
        all: Routes.checkups_personal_index_path,
        list: Routes.sort_checkups_personal_index_path,
      },
      entity: 'personalCheckup',
      location: 'MPersonalCheckupPresenter',
    })
  }

  list (data, config = {}) {
    return super.list(data, {
      toServerAdapter: personalCheckupAdapter.listFiltersToServer,
    })
      .then(personalCheckupAdapter.toClient)
  }

  submit (data, config = {}) {
    return super.submit(data, {
      toServerAdapter: (data) => JSON.stringify(camelToSnake(personalCheckupAdapter.toServer(data))),
      contentType: 'application/json',
      dataType: 'json',
      ...config,
    })
  }

  destroy (id, config = {}) {
    return super.destroy(id, config)
  }

  lastCheckup (clientId) {
    return this.post({
      url: Routes.last_checkups_personal_index_path(),
      data: camelToSnake({ clientId }),
    }).promise
      .then(MRequestSuccess.onResponse((checkup) => ({ checkup })))
      .catch((errors) => ({ errors }))
  }

  /**
   * @param {IPersonalCheckup} checkupData
   * @return {Promise<*>}
   */
  calculateFactors (checkupData) {
    const data = camelToSnake({
      personalCheckupId: checkupData.id,
      sex: GLOBAL_GET_DEFAULT_SEXES()
        .find((sex) => sex.id === checkupData.client?.sex?.id)?.systemName || null,
      age: parseInt(checkupData.client.age) || 0,
      checkupFactorIds: checkupData.factors.map(extractId),
    })

    const notification = new MRequestNotification(
      'MPersonalCheckupPresenter',
      'calculateMessage',
      'personalCheckup'
    )

    return this.post({
      url: Routes.calculate_checkup_entries_checkups_personal_index_path(),
      data,
    }).promise
      .then((data) => ({ data: data.map((item) => new PersonalCheckupCalculatedEntryCell(item)) }))
      .catch(MRequestError.onRequest(notification))
  }

  recommendationsList () {
    return this.get({ url: Routes.list_api_internal_checkups_recommendations_path() }).promise
      .then((recommendations) => ({ recommendations }))
      .catch(MRequestError.withDefault([], null))
  }

  setOrderId (personalCheckupId, orderId) {
    const notification = new MRequestNotification(
      'MPersonalCheckupPresenter',
      'updateMessage',
      'personalCheckup'
    )

    return this.patch({
      url: Routes.checkups_personal_path(personalCheckupId),
      data: JSON.stringify(camelToSnake({
        personalCheckup: {
          id: personalCheckupId,
          orderId,
        },
      })),
      contentType: 'application/json',
      dataType: 'json',
    }).promise
      .catch(MRequestError.onRequest(notification))
  }

  fetchPathologistMedicalAssessments (config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      'occupationalPathologistMedicalAssessments'
    )

    return super.get({
      url: Routes.api_internal_selectors_checkups_occupational_pathologist_medical_assessments_list_path(),
      ...config,
    }).promise
      .catch(MRequestError.withDefault([], notification))
  }
}
