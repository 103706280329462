import AbstractFormatter from './AbstractFormatter'
/**
 * @typedef {Object} FormatterOptions
 * @property {Object} [rules]
 * @property {string} [locale]
 * @property {string} [code]
 */

/**
 * This class uses all shared formatter
 */
export default class BaseFormatter extends AbstractFormatter {
  /**
   * @param {FormatterOptions} [options]
   */
  constructor ({
    rules,
    locale = gon.localization.locale,
    code = gon.localization.currency.code_string,
    decimalCharacter = gon.localization.currency.decimal_character,
  } = {}) {
    super(rules)
    this.decimalCharacter = decimalCharacter
    this.locale = locale
    this.code = code
  }

  replaceTruthy (value, newValue) {
    return value ? newValue : value
  }

  falsyToEmpty (value) {
    return !value ? this.emptyOfType(value) : value
  }

  emptyOfType (value) {
    switch (typeof value) {
      case 'boolean':
        return false
      case 'function':
        return _.noop
      case 'object':
        return {}
      case 'number':
        return 0
      case 'string':
        return ''
      case 'undefined':
        return undefined
      default:
        return value
    }
  }

  replaceEmpty (value, newValue) {
    return !value ? newValue : value
  }

  replaceNull (value, newValue) {
    return this.replaceValue(value, null, newValue)
  }

  replaceValue (value, oldValue, newValue) {
    return value === oldValue ? newValue : value
  }
}
BaseFormatter.namespace = 'base'
