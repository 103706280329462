<template>
  <transition :name="epicSpinnerTransition">
    <div
      v-show="visibility"
      class="epic-spinner-loading-mask"
    >
      <div
        class="epic-spinner"
        role="progressbar"
      >
        <div
          v-for="n in 13"
          :key="n"
          :style="_getAnimation(n)"
          class="epic-spinner-animation"
        >
          <div
            :style="_getTransform(n)"
            class="epic-spinner-inner-element"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
let deg = 0
let offsetDeg = 2

export default {
  name: 'EpicSpinner',
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    spinnerSize: {
      type: Number,
      default: 30,
    },
    epicSpinnerTransition: {
      type: String,
      default: 'epic-spinner-default-transition',
    },
  },
  methods: {
    _getAnimation (n) {
      return `animation: opacity-60-25-${n - 1}-13 1s linear 0s infinite normal none running;`
    },

    _getTransform (n) {
      if (n === 1) {
        deg = 0
        offsetDeg = 2
      } else if (offsetDeg === 2) {
        deg = deg + 27
        offsetDeg = 0
      } else {
        deg = deg + 28
        offsetDeg = offsetDeg + 1
      }

      return `transform: rotate(${deg}deg) translate(7px, 0px);`
    },
  },
}
</script>
