/**
 * @param {string[]} prefixStack
 * @returns {string|string}
 */
const getPrefix = (prefixStack = []) => {
  return prefixStack.length ? `${prefixStack.join('_')}_` : ''
}

const MAX_DEPTH = 10

/**
 * @param {Object} obj
 * @returns { Record<string, string[]> }
 */
export const flatObjectRecursive = (obj) => {
  if (!obj) { return {} }
  if (typeof obj !== 'object') { return {} }
  if (Array.isArray(obj)) { return {} }

  const stack = []
  const tempObject = {}

  const recursivePart = (obj, depth = 0) => {
    if (depth >= MAX_DEPTH) {
      tempObject[`${stack.join('_')}`] = obj

      return obj
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        stack.push(key)
        recursivePart(obj[key], depth + 1)

        return
      }

      if (Array.isArray(obj[key])) {
        for (const item of obj[key]) {
          if (typeof item === 'string') {
            tempObject[`${getPrefix(stack)}${key}`] = obj[key]

            continue
          }

          stack.push(key)
          recursivePart(item, depth + 1)
        }

        return
      }

      tempObject[`${getPrefix(stack)}${key}`] = obj[key]
    })

    stack.pop()
  }

  recursivePart(obj)

  return tempObject
}
