import { CHECKUP_STATUS_ICONS, getCheckupStatuses } from '@/vue_apps/CheckupsModule/store/checkupStatuses'
import {
  MPersonalCheckupPresenter,
} from '@/vue_apps/CheckupsModule/api/MPersonalCheckupPresenter/MPersonalCheckupPresenter'

export const checkupsStore = {
  namespaced: true,

  state () {
    return {
      /** @type {ICheckupStatus[]}*/
      statuses: getCheckupStatuses(),

      /** @type {ICheckupRecommendation[]} */
      recommendations: [],
    }
  },

  getters: {
    vxGetStatuses: (state) => state.statuses,
    vxGetStatusesWithIcons: (state) => state.statuses.map((status) => ({
      ...status,
      ...CHECKUP_STATUS_ICONS[status.id],
    })),
    vxGetRecommendations: (state) => state.recommendations,
  },

  mutations: {
    vxSetRecommendations (state, recommendations) {
      state.recommendations = recommendations
    },
  },

  actions: {
    async vxFetchRecommendations ({ commit, state }) {
      if (state.recommendations.length) { return }

      const { errors, recommendations } = await new MPersonalCheckupPresenter().recommendationsList()
      if (errors) { return }

      commit('vxSetRecommendations', recommendations)
    },
  },
}
