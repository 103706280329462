<template functional>
  <div class="si-footer">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'SiFooter',
}
</script>
