// call types
export const INCOMING_CALL_TYPE = 'incoming'
export const OUTCOMING_CALL_TYPE = 'outcoming'

// call statuses
export const FINISHED_CALL_STATUS = 'finished_call'

// call results
export const IN_PROGRESS_CALL_RESULT = 'in_progress'
export const REFUSED_CALL_RESULT = 'refused'

// classes to find and make phone numbers active
export const PHONE_NUMBER_CLASS = 'phone_number'
export const ACTIVE_PHONE_NUMBER_MODIFIER = 'callable'

// call notifications info
export const CALL_NTF_STATUS_RESULT = 'result'
export const CALL_NTF_PRESET_NAME = 'call'
export const CALL_NTF_CLASS = '.call_notification'

// appointment automated call statuses
export const CONFIRMATION_PENDING = 'pending'
export const CONFIRMATION_CONFIRMED = 'confirmed'
export const CONFIRMATION_CLIENT_CANCELED = 'client_canceled'
export const CONFIRMATION_FAILED = 'failed_to_connect'

export const REMINDER_PENDING = 'pending'
export const REMINDER_REMINDED = 'reminded'
export const REMINDER_FAILED = 'failed_to_connect'

// constants for template
export const PREVIEW_TEMPLATE_PATH = Routes.preview_telephony_uis_configuration_index_path()

export const APPOINTMENT_VARIABLES_LIST = [
  {
    source: 'client',
    children: [
      { source: 'full_name' },
      { source: 'name' },
      { source: 'surname' },
      { source: 'second_name' },
    ],
  },
  {
    source: 'clinic',
    children: [
      { source: 'message_title' },
      { source: 'message_address' },
      { source: 'formatted_phone1' },
      { source: 'formatted_phone2' },
    ],
  },
  {
    source: 'user',
    children: [
      { source: 'name' },
      { source: 'surname' },
      { source: 'short_name' },
    ],
  },
  {
    source: 'appointment',
    children: [
      { source: 'date_dd_mm' },
      { source: 'time' },
    ],
  },
]

export const APPOINTMENT_CONFIRMATION = {
  variablesList: {
    variables: APPOINTMENT_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_TEMPLATE_PATH,
  catSelector: false,
}

export const APPOINTMENT_REMINDER = {
  variablesList: {
    variables: APPOINTMENT_VARIABLES_LIST,
  },
  previewUrl: PREVIEW_TEMPLATE_PATH,
  catSelector: false,
}
