export const getDefaultCompanyData = () => {
  return {
    title: '',
    company_type_ids: [],
    type_of_company: '',
    individual_tax_number: '',
    kpp: '',
    ogrn: '',
    okved: '',
    discount: '0.0',
    registration_date: '',
    note: '',
    phone1: '',
    phone2: '',
    fax: '',
    email: '',
    web: '',
    head_name: '',
    head_surname: '',
    head_second_name: '',
    head_position: '',
    head_operates_by: '',
    address_fact: {
      index: '',
      country: '',
      region: '',
      area: '',
      city: '',
      street: '',
      house: '',
      flat: '',
      nsi_russian_subject_id: null,
    },
    address_formal: {
      index: '',
      country: '',
      region: '',
      area: '',
      city: '',
      street: '',
      house: '',
      flat: '',
      nsi_russian_subject_id: null,
    },
  }
}

export const ORDER_STATUS_CLASSES = Object.freeze({
  FULLY_PAID: 'paid-status--fully',
  NOT_PAID: 'paid-status--not',
  PAID_BY_CREDIT: 'paid-status--credit',
  FREE: 'paid-status--free',
})

export const STORAGE_KEY = 'company_storage'

export const DATE_ISO_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ'

export const PAYMENT_SPECIES = Object.freeze({
  BY_CASH: 'by_cash',
  BY_CASHLESS: 'by_cashless',
  BY_BALANCE: 'by_balance',
})

export const INSURANCE_COMPANY_TYPE_ID = 2
