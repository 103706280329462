import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { GLOBAL_ENTRY_STATE, GLOBAL_ENTRY_TYPES_KINDS } from '@/_global_scripts/GLOBAL_CONSTS'
import { tScoped } from '@/helpers/i18nHelpers'

const ts = tScoped('checkups.entries')

/**
 * @param {ICheckupEntry} checkupEntry
 */
export class PersonalCheckupEntryItem extends MListServiceItem {
  constructor (data) {
    super()

    this.id = data.id

    this.kind = data.kind

    this.checkupsEntryId = data.checkupsEntryId

    this.userId = data.userId

    this.title = new MTableCell(data.title)
      .addTooltip(data.title)
      .addCut()

    this.amount = new MTableCell(data.amount)

    this.date = new MTableCell(Utils.getFormattedDate(data.date))

    this.userShortName = new MTableCell(data.userShortName)
      .addTooltip(data.userShortName)
      .addCut()

    this.state = data.state

    this._setStateIcon()

    this.edit = new MTableCell()
      .addTypeIcon('edit')
      .addTooltip(ts('edit'))
      .addClasses('warning')
      .addPreventDefault()

    this.changeUser = Services.security.canManageCheckupsCompany
      ? new MTableCell()
        .addTypeIcon('doctor')
        .addTooltip(ts('changeDoctor'))
        .addClasses('primary')
        .addPreventDefault()
      : null
  }

  _setStateIcon () {
    const setIcon = () => {
      this.stateIcon = new MTableCell()
        .addTypeIcon(this.state === GLOBAL_ENTRY_STATE.READY
          ? 'readyCircle'
          : 'notReadyCircle'
        )
        .addTooltip(this.state === GLOBAL_ENTRY_STATE.READY
          ? t('ready')
          : t('not_ready')
        )
        .addClasses(this.state === GLOBAL_ENTRY_STATE.READY
          ? 'success'
          : 'gray'
        )
    }

    if (gon.application.entry_status &&
      (this.kind === GLOBAL_ENTRY_TYPES_KINDS.SERVICE || this.kind === GLOBAL_ENTRY_TYPES_KINDS.COMPLEX)) {
      setIcon()

      this.__selectable = this.state !== GLOBAL_ENTRY_STATE.READY

      return
    }

    if (gon.application.entry_analysis_status && this.kind === GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS) {
      setIcon()

      this.__selectable = this.state !== GLOBAL_ENTRY_STATE.READY

      return
    }

    this.stateIcon = null
  }
}
