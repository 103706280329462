import { ACTIVE_TAB_STORAGE_KEY, FEEDBACKS_TAB_ID } from '@/vue_components/client_feedbacks/tabs.js'

const state = {
  doctors: [],
  specialties: [],
  currentUserClinics: [],
  feedbackStatuses: {},
  feedbacks: [],
  totalPages: 0,
  feedbacksNumber: 0,
  CFUS: {},
  activeTab: store.get(ACTIVE_TAB_STORAGE_KEY) || store.set(ACTIVE_TAB_STORAGE_KEY, FEEDBACKS_TAB_ID),
}

const getters = {
  GET_FEEDBACKS (state) {
    return state.feedbacks
  },
  GET_FEEDBACK_BY_ID: (_, getters) => (id) => {
    const found = getters.GET_FEEDBACKS.find(({ feedback }) => feedback.id === id)
    if (found) return found.feedback
  },
  GET_DOCTORS (state) {
    return state.doctors
      .sort((a, b) => a.surname.localeCompare(b.surname))
  },
  GET_SPECIALTIES (state) {
    return state.specialties
  },
  GET_CURRENT_USER_CLINICS (state) {
    return state.currentUserClinics
      .map((clinic) => ({ id: clinic[0], title: clinic[1] }))
  },
  GET_CFUS (state) {
    return state.CFUS
  },
}

const mutations = {
  CHANGE_FEEDBACK (state, attributes) {
    const { feedback } = state.feedbacks.find(({ feedback }) => feedback.id === attributes.id)
    if (!feedback) return

    const attributesKeys = Object.keys(attributes)
    const feedbackKeys = Object.keys(feedback)

    attributesKeys.forEach((attributeKey) => {
      if (feedbackKeys.includes(attributeKey)) {
        feedback[attributeKey] = attributes[attributeKey]
      }
    })
  },
}

const actions = {
  RUN_FEEDBACK_UPDATE_SERVICE (ctx) {
    Services.pubSub.subscribe('CLIENT_FEEDBACKS.UPDATED_FEEDBACK', (updatedFeedback) => {
      if (ctx.getters.GET_FEEDBACKS.length < 1) return

      const oldFeedback = ctx.getters.GET_FEEDBACK_BY_ID(updatedFeedback.id)
      const CFUS = ctx.getters.GET_CFUS[updatedFeedback.id]

      if (!oldFeedback) return
      if (!CFUS) console.warn('CUFS was to be found')

      CFUS.simulate(updatedFeedback, oldFeedback)
      ctx.commit('CHANGE_FEEDBACK', updatedFeedback)
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
