import { isEmpty, pick } from 'lodash'
import {
  DEFAULT_END_TIME,
  DEFAULT_END_TIME_MINUS_MINUTE,
  FILTERS_MAP,
  STABLE_FILTERS,
} from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/const/filters'
import { getFormattedDate } from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/const/const'

export const recordingPointsFetchAllAdapter = {
  toServer (filters, catalogs) {
    const __filters = pick(filters, STABLE_FILTERS)

    /**
     * Фильтр клиник
     * https://www.notion.so/medods/502faa9a04274de4bdb87b3460b1d550
     */
    __filters.clinicIds = isEmpty(filters.clinicIds)
      ? Object.keys(catalogs.clinics)
      : filters.clinicIds?.map((item) => item.id)

    __filters.startDate = Utils.getBaseFormattedDate(filters.date[0])
    __filters.endDate = Utils.getBaseFormattedDate(filters.date[1])

    if (filters.startTime) {
      __filters.startTime = filters.startTime
    }

    if (filters.endTime) {
      __filters.endTime = filters.endTime !== DEFAULT_END_TIME
        ? filters.endTime
        : DEFAULT_END_TIME_MINUS_MINUTE
    }

    const filterType = FILTERS_MAP[filters.filterType]
    __filters[filterType] = filters[filterType]?.map((item) => item.id)

    __filters.clientUTCTimeOffset = moment(new Date()).utcOffset()

    return __filters
  },

  toClient (response, catalogs) {
    response.recordingPoints = response.recordingPoints.map((item) => {
      const user = catalogs.doctors[item.userId]

      // костыль, т.к. запись уникальная сама по себе
      const id = Object.values(item).join('_')

      return {
        id,
        ...item,
        ...getFormattedDate(item.date),
        clinicTitle: catalogs.clinics[item.clinicId]?.title,
        userName: user?.short_name,
        specialtiesTitle: user?.specialty_ids.map((id) => catalogs.specialties[id]?.title).join(', '),
      }
    })

    return response
  },
}
