import { DEFAULT_LIMIT } from '@/vue_components/store/modules/filters_base'
import { isEmpty, omit } from 'lodash'
import formatter from '@/lib/formatters/formatter'
import { camelToSnake } from '@/_api/_requests/helpers'
import { FILTERS } from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/const'
import { DateHelpers } from '@/helpers/DateHelpers'

export const appointmentCallLogAdapter = {
  toServer (data) {
    return camelToSnake({
      ...omit(data, [
        FILTERS.CLINIC,
        FILTERS.STATUS,
        FILTERS.CALL_CONFIRMATION_STATUS,
        FILTERS.SPECIALTY,
        FILTERS.USER,
      ]),

      clinicId: data.clinic?.id || null,
      status: (data.status || []).map(({ id }) => id),
      callConfirmationStatus: (data.callConfirmationStatus || []).map(({ id }) => id),
      specialtyId: data.specialty?.id,
      date: isEmpty(data.date)
        ? []
        : [Utils.getBaseFormattedDate(data.date[0]), Utils.getBaseFormattedDate(data.date[1])],
      userId: data.user?.id,
    })
  },

  /**
   * @param {Array} appointments
   * @param {number} count
   * @return {{totalItems: number, data: callLogAppointment[], totalPages: number}}
   */
  toClient ({ appointments, count }) {
    return {
      data: appointments.map((item) => {
        return {
          ...omit(item, [FILTERS.CLINIC, FILTERS.USER]),
          clinicTitle: item.clinic?.title,
          specialties: (item.specialties || []).map(({ title }) => title).join(', '),
          date: Utils.getFormattedDate(item.date),
          doctor: formatter.formatValue(item.user, ['user:shortName']),
          userId: item.user?.id,
          ...DateHelpers.getTodayTomorrowYesterday(item.date),
        }
      }),

      totalItems: count,
      totalPages: Math.ceil(count / DEFAULT_LIMIT),
    }
  },
}
