import AbstractFormatter from '@/lib/formatters/AbstractFormatter'

export default class ObjectListFormatter extends AbstractFormatter {
  realIds (list = []) {
    return list.map((item) => item.id).filter(Boolean)
  }

  extractProp (list, prop, delimiter = ', ') {
    return list.map((item) => item[prop]).join(delimiter)
  }
}
ObjectListFormatter.namespace = 'list'
