export const waitingList = {
  namespaced: true,
  state: {
    activeItem: null,
    user: null,
    form: {
      legalRepresentatives: [],
    },
  },
  actions: {
    resetForm ({ commit }) {
      commit('SET_LEGAL_REPS', [])
    },
  },
  mutations: {
    SET_USER (state, user) {
      state.user = user
    },

    SET_LEGAL_REPS (state, legalReps) {
      state.form.legalRepresentatives = legalReps
    },

    ADD_LEGAL_REP (state, legalRep) {
      state.form.legalRepresentatives = state.form.legalRepresentatives.concat(legalRep)
    },
  },
  getters: {
    GET_LEGAL_REPS (state) {
      return state.form.legalRepresentatives
    },

    GET_USER (state) {
      return state.user
    },
  },
}
