import { NETRIKA_CATALOGS } from '@/api_entities/egisz/catalogs/const'
import { request } from '@/lib/transport/request'
import { catalogAdapter } from '@/api_entities/egisz/catalogs/catalog_adapter'

export const medDocumentTypeEndpoint = {
  name: 'medDocumentTypeEndpoint',
  getAll () {
    const options = {
      type: 'get',
      url: Routes.egisz_netrika_catalogs_list_path({ title: NETRIKA_CATALOGS.MED_DOCUMENT_TYPES }),
    }

    return request(options, catalogAdapter.toClient).promise
  },
}
