import { CALENDAR_RANGE_NAME } from '@/vue_components/calendar_ranges'
import { DEFAULT_TIME_PICKER_OPTIONS } from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/const/filters'
import { DateHelpers } from '@/helpers/DateHelpers'

export const DEFAULT_FILTRATION_DEBOUNCE = 200

export const APPOINTMENT_TIME_FINDER_STORAGE_KEY = 'appointmentTimeFinderFilters'

export const appointmentTimeFinderTableSchema = {
  headers: {
    clinicTitle: t('clinic'),
    formattedDate: t('common.date_time'),
    userName: t('doctor'),
    specialtiesTitle: t('specialty'),
    scheduleActions: '',
  },
}

export const appointmentTimeFinderRangePickerButtons = () => {
  const today = moment()
  const tomorrow = moment().add(1, 'days')

  return Object.freeze([
    { range: () => [today.startOf('day').toDate(), today.endOf('day').toDate()], id: 1, title: t('today') },
    { range: () => [tomorrow.startOf('day').toDate(), tomorrow.endOf('day').toDate()], id: 2, title: t('tomorrow') },
  ])
}

export const appointmentTimeFinderDateRangePickerShortcuts = () => ([
  CALENDAR_RANGE_NAME.TODAY,
  CALENDAR_RANGE_NAME.TOMORROW,
  CALENDAR_RANGE_NAME.SEVEN_DAYS,
  CALENDAR_RANGE_NAME.THIRTY_DAYS,
])

export const appointmentTimeFinderTimePickerOptions = () => ({
  start: DEFAULT_TIME_PICKER_OPTIONS.START,
  step: DEFAULT_TIME_PICKER_OPTIONS.STEP,
  end: DEFAULT_TIME_PICKER_OPTIONS.END,
})

export const getFormattedDate = (date) => {
  return DateHelpers.getTodayTomorrowYesterday(date)
}
