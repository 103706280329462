<template>
  <div
    :style="margin"
    class="pagination-vue"
  >
    <el-pagination
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    marginTop: {
      default: 0,
      type: Number,
    },
    marginBottom: {
      default: 0,
      type: Number,
    },
    marginLeft: {
      default: 0,
      type: Number,
    },
    marginRight: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    margin () {
      const margin = Object.create(null)
      if (this.marginTop > 0) {
        margin['margin-top'] = `${this.marginTop}px`
      }
      if (this.marginBottom > 0) {
        margin['margin-bottom'] = `${this.marginBottom}px`
      }
      if (this.marginLeft > 0) {
        margin['margin-left'] = `${this.marginLeft}px`
      }
      if (this.marginRight > 0) {
        margin['margin-right'] = `${this.marginRight}px`
      }

      return margin
    },
  },
  mounted () {
    const replacementIcons = {
      'el-icon-arrow-right': 'fa-chevron-right',
      'el-icon-arrow-left': 'fa-chevron-left',
    }

    this.$el.querySelectorAll('.el-icon').forEach((el) => {
      el.classList.add('fad')
      el.classList.remove('el-icon')
      const iconClass = el.className.match(/(el-icon-[a-zA-Z-_]+)/)
      if (iconClass) {
        el.classList.replace(iconClass[0], replacementIcons[iconClass[0]])
      }
    })
  },

}
</script>
