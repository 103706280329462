export const TIME = 2
export const AVAILABLE = 4
export const UNAVAILABLE = 8
export const USERNAME = 16
export const CABINET = 32
export const DATE = 64
export const META = 128
export const ICONS = 256

export const KEEP_RESERVATION_TIMEOUT = 5000
export const REQUEST_TIMEOUT_DELAY = 1000

// in pixels
export const CELL_HEIGHT = 25
export const CELL_MARGIN_BOTTOM = 3

// grid modes
export const MODE_DEFAULT = 2
export const MODE_MOVE = 4
export const MODE_CLONE = 8
export const MODE_CLIENT_INSERT = 16
export const MODE_PHONE_INSERT = 32
export const MODE_WAITING_LIST_INSERT = 64
export const MODE_WAITING_LIST_MOVE = 128

export const PASTE_MODE_ARRAY =
  Object.freeze([MODE_MOVE, MODE_CLONE, MODE_CLIENT_INSERT, MODE_PHONE_INSERT, MODE_WAITING_LIST_INSERT, MODE_WAITING_LIST_MOVE])

export const GRID_ICONS_TIPPY_CONFIG = Object.freeze({ delay: [330, 20] })

export const DOCTOR = 'doctor'
export const ADMINISTRATOR = 'administrator'

export const CREATE_WORK_TIME = 64
export const REMOVE_WORK_TIME = 128

export const DEFAULT_START_TIME = '09:00'
export const DEFAULT_END_TIME = '18:00'

export const APPOINTMENT_PACKET = 'appointment'
export const RESERVATION_PACKET = 'reservation'
export const WORK_TIME_PACKET = 'work_time'
export const CLIENT_PACKET = 'client'

export const PAYMENTS_ACTION = 'payments'

export const CREATED_ACTION = 'created'
export const UPDATED_ACTION = 'updated'
export const DELETED_ACTION = 'deleted'
export const RESERVE_ACTION = 'reserve'
export const CANCEL_ACTION = 'cancel'

export const APPOINTMENT_TRANSIT_STORAGE_KEY = 'schedule-grid-transit-storage'

export const MAX_TRY_COUNT = 2
