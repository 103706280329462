import { createStore } from '@/vue_components/store/store'
import {
  extractDataByEntityField,
} from '@/vue_components/egisz/egisz_module/const/egisz_errors/extract_data_by_entity_field'
import {
  extractDataByNetrikaFields,
} from '@/vue_components/egisz/egisz_module/const/egisz_errors/extract_data_by_netrika_fields'

const ERROR_TYPES = Object.freeze({
  FIELD: 'field',
})

const errorTypeMethods = Object.freeze({
  [ERROR_TYPES.FIELD]: extractDataByEntityField,
})

const extractNotificationData = async (store, responseJSON) => {
  if (!responseJSON) { return {} }

  const {
    entity,
    id,
    error_type: errorType,
    value,
    messages,
  } = responseJSON

  if (!errorType) {
    return extractDataByNetrikaFields(responseJSON)
  }

  const errorTypeMethod = errorTypeMethods[errorType]
  if (!errorTypeMethod) {
    return { message: t('abstract_error_message') }
  }

  return errorTypeMethod(store, { entity, id, value, messages })
}

const notify = (message, title = '') => {
  Notificator.error(message, title)
}

const sendToConsole = (location, responseError) => {
  console.error(location, responseError)
}

export const egiszErrorsNotify = (location, customMessage = '') =>
  async (responseError) => {
    const { message, title } = await extractNotificationData(
      createStore(),
      responseError.responseJSON
    )

    if (!message) {
      Utils.reportError(location, customMessage)(responseError)

      return
    }

    sendToConsole(location, responseError)
    notify(message, title)
  }

// utils_report_error.ru.yml
export const reportErrorText = (errorMessage, errorEntity) =>
  t(errorMessage, { scope: 'utils_report_error', entity: t(errorEntity, { scope: 'utils_report_error.entities' }) })

// utils_report_success.ru.yml
export const reportSuccessText = (successMessage, successEntity) =>
  t(successMessage, { scope: 'utils_report_success', entity: t(successEntity, { scope: 'utils_report_success.entities' }) })
