import { paymentTypesEndpoint } from '@/api_entities/egisz/catalogs/payment_types/payment_type'
import { doctorEndpoint } from '@/api_entities/doctors/doctor_endpoint'
import { visitTypeEndpoint } from '@/api_entities/egisz/catalogs/visit_type/visit_type'
import { diseaseResultEndpoint } from '@/api_entities/egisz/catalogs/disease_results/disease_result'
import { stepTypeEndpoint } from '@/api_entities/egisz/catalogs/step_type/step_type_endpoint'
import { netrikaPlaceEndpoint } from '@/api_entities/egisz/catalogs/place/netrika_place_endpoint'
import { netrikaPurposeEndpoint } from '@/api_entities/egisz/catalogs/purpose/netrika_purpose_endpoint'
import { confidentialityEndpoint } from '@/api_entities/egisz/catalogs/confidentiality/confidentiality'
import { caseTypeEndpoint } from '@/api_entities/egisz/catalogs/case_type/case_type'
import { diagnosisStatusEndpoint } from '@/api_entities/egisz/catalogs/diagnosis_statuses/diagnosis_status'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'

export const actions = {
  loadCatalogs ({ dispatch, commit, state }) {
    if (state.catalogs.isLoaded) { return }

    return Promise.all([
      dispatch('loadSpecialties').then((data) => commit('SET_SPECIALTIES', data)),
    ])
      .then(() => { commit('SET_CATALOGS_LOADED') })
  },

  loadCaseCatalogs ({ dispatch, commit, state }) {
    if (state.caseCatalogs.isLoaded) { return }

    return Promise.all([
      // case catalogs
      dispatch('loadPaymentTypes').then((data) => commit('SET_PAYMENT_TYPES', data)),
      dispatch('loadVisitTypes').then((data) => commit('SET_VISIT_TYPES', data)),
      dispatch('loadCaseTypes').then((data) => commit('SET_CASE_TYPES', data)),
      dispatch('loadConfidentialityLevels').then((data) => commit('SET_CONFIDENTIALITY_LEVELS', data)),
      dispatch('loadDiseaseResults').then((data) => commit('SET_DISEASE_RESULTS', data)),
      dispatch('loadRelativeTypes').then((data) => commit('SET_RELATIVE_TYPES', data)),

      // for steps
      dispatch('loadStepTypes').then((data) => commit('SET_STEP_TYPES', data)),
      dispatch('loadStepPlaces').then((data) => commit('SET_STEP_PLACES', data)),
      dispatch('loadStepPurposes').then((data) => commit('SET_STEP_PURPOSES', data)),

      // for med records
      dispatch('loadMedRecordDiagnosisStatuses').then((data) => commit('SET_MED_RECORD_DIAGNOSIS_TYPES', data)),
    ])
      .then(() => { commit('SET_CASE_CATALOGS_LOADED') })
  },

  loadSpecialties () {
    return new Promise((resolve, reject) => {
      doctorEndpoint.getSpecialties()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadSpecialties()',
            reportErrorText('catalogs_message', 'egisz_specialties')
          )(err)
          reject(err)
        })
    })
  },

  loadPaymentTypes () {
    return new Promise((resolve, reject) => {
      paymentTypesEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadPaymentTypes()',
            reportErrorText('catalogs_message', 'payment_types')
          )(err)
          reject(err)
        })
    })
  },

  loadVisitTypes () {
    return new Promise((resolve, reject) => {
      visitTypeEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadVisitTypes()',
            reportErrorText('catalogs_message', 'case_visit_types')
          )(err)
          reject(err)
        })
    })
  },

  loadCaseTypes () {
    return new Promise((resolve, reject) => {
      caseTypeEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadCaseTypes()',
            reportErrorText('catalogs_message', 'case_types')
          )(err)
          reject(err)
        })
    })
  },

  loadConfidentialityLevels () {
    return new Promise((resolve, reject) => {
      confidentialityEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadConfidentialityLevels()',
            reportErrorText('catalogs_message', 'confidentialities')
          )(err)
          reject(err)
        })
    })
  },

  loadDiseaseResults () {
    return new Promise((resolve, reject) => {
      diseaseResultEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadDiseaseOutcomes()',
            reportErrorText('catalogs_message', 'case_results')
          )(err)
          reject(err)
        })
    })
  },

  loadStepTypes () {
    return new Promise((resolve, reject) => {
      stepTypeEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadStepTypes()',
            reportErrorText('catalogs_message', 'step_types')
          )(err)
          reject(err)
        })
    })
  },

  loadStepPlaces () {
    return new Promise((resolve, reject) => {
      netrikaPlaceEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadStepPlaces()',
            reportErrorText('catalogs_message', 'visit_places')
          )(err)
          reject(err)
        })
    })
  },

  loadStepPurposes () {
    return new Promise((resolve, reject) => {
      netrikaPurposeEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadStepPurposes()',
            reportErrorText('catalogs_message', 'visit_purposes')
          )(err)
          reject(err)
        })
    })
  },

  loadMedRecordDiagnosisStatuses () {
    return new Promise((resolve, reject) => {
      diagnosisStatusEndpoint.getAll()
        .then((data) => { resolve(data) })
        .catch((err) => {
          Utils.reportError(
            'store:medCasesState:loadMedRecordDiagnosisStatuses()',
            reportErrorText('catalogs_message', 'diagnosis_types')
          )(err)
          reject(err)
        })
    })
  },

  loadRelativeTypes () {
    return clientsEndpoint.getRelativeTypes()
      .catch(Utils.reportError(
        'legalReps:loadRelativeTypes()',
        t('egisz.messages.errors.request.netrika_load_catalog_error')
      ))
  },
}
