import modifyFetchedCompany from '@/vue_components/companies/helpers/modify_fetched_company'
import { companiesEndpoint } from '@/api_entities/companies/companies_endpoint'

const state = {
  company: {
    id: '',
    title: '',
    type_of_company: '',
    individual_tax_number: '...',
    discount: '...',
    kpp: '...',
    ogrn: '...',
    okved: '...',
    smo_code: '...',
    registration_date: '...',
    note: '...',
    phone1: '00000000000',
    phone2: '00000000000',
    fax: '...',
    email: '...',
    web: '...',
    head_name: '...',
    head_surname: '...',
    head_second_name: '...',
    head_position: '...',
    head_operates_by: '...',
    full_fact_address: '...',
    full_formal_address: '...',
    finance: '0.0',
    all_time_paid_sum: '0.0',
    company_types: [],
    company_finance: {
      credit_debt: '0.0',
    },
    address_fact: {
      index: '...',
      region: '...',
      area: '...',
      city: '...',
      street: '...',
      house: '...',
      flat: '...',
    },
    address_formal: {
      index: '...',
      region: '...',
      area: '...',
      city: '...',
      street: '...',
      house: '...',
      flat: '...',
    },
  },

  ui: {
    companies_menu_show: true,
    companyLoading: false,
  },
}

const getters = {
  GET_COMPANY_TITLE (state) {
    return state.company.title
  },

  GET_COMPANY_TYPE_OF_COMPANY (state) {
    return state.company.type_of_company
  },

  GET_COMPANY_INDIVIDUAL_TAX_NUMBER (state) {
    return state.company.individual_tax_number
  },

  GET_COMPANY_KPP (state) {
    return state.company.kpp
  },

  GET_COMPANY_OGRN (state) {
    return state.company.ogrn
  },

  GET_COMPANY_OKVED (state) {
    return state.company.okved
  },

  GET_COMPANY_SMO_CODE (state) {
    return state.company.smo_code
  },

  GET_COMPANY_REGISTRATION_DATE (state) {
    return state.company.registration_date
  },

  GET_COMPANY_NOTE (state) {
    return state.company.note
  },

  GET_COMPANY_PHONE_1 (state) {
    return state.company.phone1
  },

  GET_COMPANY_PHONE_2 (state) {
    return state.company.phone2
  },

  GET_COMPANY_FAX (state) {
    return state.company.fax
  },

  GET_COMPANY_EMAIL (state) {
    return state.company.email
  },

  GET_COMPANY_WEB (state) {
    return state.company.web
  },

  GET_COMPANY_HEAD_NAME (state) {
    return state.company.head_name
  },

  GET_COMPANY_HEAD_SURNAME (state) {
    return state.company.head_surname
  },

  GET_COMPANY_HEAD_SECOND_NAME (state) {
    return state.company.head_second_name
  },

  GET_COMPANY_HEAD_POSITION (state) {
    return state.company.head_position
  },

  GET_COMPANY_HEAD_OPERATES_BY (state) {
    return state.company.head_operates_by
  },

  GET_COMPANY_FULL_FACT_ADDRESS (state) {
    return state.company.full_fact_address
  },

  GET_COMPANY_FULL_FORMAL_ADDRESS (state) {
    return state.company.full_formal_address
  },

  GET_COMPANY_DISCOUNT (state) {
    return state.company.discount
  },

  GET_COMPANY_FINANCE (state) {
    return state.company.finance
  },

  GET_COMPANY_ALL_TIME_PAID_SUM (state) {
    return state.company.all_time_paid_sum
  },

  GET_COMPANY_COMPANY_TYPES (state) {
    return state.company.company_types
  },

  GET_COMPANY_COMPANY_FINANCE_CREDIT_DEBT (state) {
    return state.company.company_finance.credit_debt
  },

  GET_COMPANY_CREATOR_USER (state) {
    return state.company.creator
  },

  GET_COMPANY_UPDATER_USER (state) {
    return state.company.updater
  },

  GET_COMPANY_CREATED_AT (state) {
    return state.company.created_at
  },

  GET_COMPANY_UPDATED_AT (state) {
    return state.company.updated_at
  },

  GET_COMPANY_LOADING (state) {
    return state.ui.companyLoading
  },

  GET_MENU_SHOW (state) {
    return state.ui.companies_menu_show
  },
}

const mutations = {
  SET_COMPANY (state, company) {
    state.company = company
  },

  SET_COMPANY_LOADING (state, loading) {
    state.ui.companyLoading = loading
  },

  SET_MENU_SHOW (state, payload) {
    state.ui.companies_menu_show = payload
  },
}

const actions = {
  LOAD_COMPANY ({ commit }, companyId) {
    commit('SET_COMPANY_LOADING', true)
    const { promise, ajax } = companiesEndpoint.get(companyId)

    return promise
      .then((fetchedCompany) => {
        switch (ajax.status) {
          case 200:
            const company = modifyFetchedCompany(fetchedCompany)
            commit('SET_COMPANY', company)

            return company
          case 404:
            Notificator.error(t('invalid_company_id'))
        }
      })
      .finally(() =>
        commit('SET_COMPANY_LOADING', false),
      )
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
