import { MCallsConfigurationsPresenter } from '@/_api/Deliveries/MCallsPresenter'

export const flashCallsConfigurationsStore = {
  namespaced: true,

  state: () => ({
    clientWidgetLimitPerHour: null,
    clientWidgetPriority: null,
    clientWidgetWaitingTime: null,
    login: null,
    password: null,
    balance: null,
    sendFromTime: null,
    sendToTime: null,
    callsPriorities: null,
  }),

  getters: {
    vxGetFlashCallsLogin: (state) => state.login,

    vxGetFlashCallsPassword: (state) => state.password,

    vxGetFlashCallsBalance: (state) => state.balance,

    vxGetCallsPriorities: (state) => state.callsPriorities,
  },

  mutations: {
    vxSetFlashCallsConfigurations (state, configurations) {
      Object.keys(configurations).forEach((key) => {
        state[key] = configurations[key]
      })
    },

    vxSetFlashCallsLogin (state, value) {
      state.login = value
    },

    vxSetFlashCallsPassword (state, value) {
      state.password = value
    },

    vxSetFlashCallsBalance (state, value) {
      state.balance = value
    },

    vxSetCallsConfigurationsPriorities (state, value) {
      state.callsPriorities = value
    },
  },

  actions: {
    async vxFetchCallsConfigurations ({ commit }) {
      const presenter = new MCallsConfigurationsPresenter()
      const configurations = await presenter.fetchCallsConfigurations()
      if (configurations?.errors) { return }

      commit('vxSetFlashCallsConfigurations', configurations)
    },

    async vxFetchBalanceCallsConfigurations ({ state, commit }) {
      const presenter = new MCallsConfigurationsPresenter()
      const balance = await presenter.fetchBalanceCallsConfigurations({
        login: state.login,
        password: state.password,
      })
      if (balance?.errors) { return }

      commit('vxSetFlashCallsBalance', balance)
    },

    async vxFetchCallsPriorities ({ commit }) {
      commit('vxSetCallsConfigurationsPriorities', [])

      const presenter = new MCallsConfigurationsPresenter()
      const configurations = await presenter.fetchPriorities()
      if (configurations?.errors) { return }

      commit('vxSetCallsConfigurationsPriorities', configurations)
    },
  },
}
