import { toDateString } from '@/vue_components/head_desktop/helpers'

const SELECTED_CLINIC_KEY = 'head_desktop/selectedClinic'
/**
 * @param state
 * @param {string} cardName
 * @param {string} selectorName
 * @param {any} value
 */
const setSelector = (state, { cardName, selectorName, value }) => {
  state.selectors[cardName][selectorName] = value
}

const yearAgo = () => [
  moment().subtract(12, 'months').startOf('month'),
  moment().endOf('month'),
].map(toDateString)

const currentMonth = () => [
  moment().startOf('month'),
  moment().endOf('month'),
].map(toDateString)

const defaultCards = [
  'appointment',
  'income',
  'average-check',
  'department',
  'attraction-source',
]

export const headDesktopStore = {
  namespaced: true,
  state: {
    cards: [
      {
        value: 'nps-dynamics',
        label: t('client_feedbacks.nps_abbr'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-users',
        link: '/reports/nps_dynamics',
      },
      {
        value: 'client-feedbacks',
        label: t('client_feedbacks.feedbacks'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-comments-alt',
        link: '/client-feedbacks',
      },
      {
        value: 'income',
        label: t('head_desktop.widgets.income'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-chart-bar',
        link: '/reports/income',
      },
      {
        value: 'appointment',
        label: t('head_desktop.widgets.appointment'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-calendar-day',
        link: '/reports/appointment_dynamic',
      },
      {
        value: 'attraction-source',
        label: t('head_desktop.widgets.attraction_source'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-compress-arrows-alt',
        link: '/reports/attraction_source',
      },
      {
        value: 'department',
        label: t('head_desktop.widgets.department'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-copy',
        link: '/reports/departments',
      },
      {
        value: 'average-check',
        label: t('head_desktop.widgets.average_check'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-money-bill',
        link: '/reports/orders', //TODO сделать отчет по среднему чеку
      },
      {
        value: 'call',
        label: t('head_desktop.widgets.call'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-phone-office',
        link: '/reports/calls',
      },
      {
        value: 'call-conversion',
        label: t('head_desktop.widgets.call_conversion'),
        style: 'grid-column: span 1',
        icon: 'fad fa-fw fa-phone-alt',
        link: '/reports/call_effect',
      },
    ],
    selectors: {
      'nps-dynamics': {
        clinic_id: null,
        date_range: yearAgo(),
      },
      income: {
        clinic_id: null,
        date_range: yearAgo(),
      },
      appointment: {
        clinic_id: null,
        appointment_source_id: '',
        patient_type: '',
        date_range: yearAgo(),
      },
      'attraction-source': {
        clinic_id: null,
        date_range: currentMonth(),
      },
      department: {
        clinic_id: null,
        date_range: currentMonth(),
        kind: '',
      },
      'average-check': {
        clinic_id: null,
        date_range: yearAgo(),
      },
      'call-conversion': {
        clinic_id: null,
        date_range: yearAgo(),
      },
      call: {
        clinic_id: null,
        date_range: yearAgo(),
        call_type: '',
        call_status: '',
      },
      'client-feedbacks': {
        with_comment_text: false,
      },
    },
    globalClinicSelectorValue: store.get(SELECTED_CLINIC_KEY, null),
    pickedCardIds: store.get('pickedCards', defaultCards),
  },
  mutations: {
    replacePickedCardIds (state, newPickedCardIds) {
      state.pickedCardIds = newPickedCardIds
    },
    removeCard (state, value) {
      state.pickedCardIds = state.pickedCardIds.filter((e) => e !== value)
    },
    setGlobalClinicSelectorValue (state, value) {
      state.globalClinicSelectorValue = value
    },
    /**
     *
     * @param state
     * @param {string} selectorName
     * @param {any} value
     */
    setAllSelectors (state, {
      selectorName,
      value,
    }) {
      for (const cardName in state.selectors) {
        setSelector(state, {
          cardName,
          selectorName,
          value,
        })
      }
    },
    setSelector,
    /**
     * @param state
     * @param {string} cardName
     * @param {Record<string, any>} params
     */
    setCardParams (state, {
      cardName,
      params,
    }) {
      for (const selectorName of Object.keys(params)) {
        setSelector(state, {
          cardName,
          selectorName,
          value: params[selectorName],
        })
      }
    },
  },
  getters: {
    pickedCards (state) {
      const filteredCards = state.cards.filter((card) => ~state.pickedCardIds.indexOf(card.value))

      const sortedCards = filteredCards.sort((a, b) => (
        state.pickedCardIds.indexOf(a.value) -
        state.pickedCardIds.indexOf(b.value)
      ))

      store.set('pickedCards', state.pickedCardIds)

      return sortedCards
    },
  },
  actions: {
    /**
     * Check if previously selected clinic is allowed for the user,
     * reset selector if not
     *
     * @param state
     * @param dispatch
     */
    checkSelectedClinic ({
      state,
      dispatch,
    }) {
      const selectedClinicId = state.globalClinicSelectorValue
      /**
       * @type {string[]}
       */
      const availableClinics = gon.specific.head_desktop.clinics.map((c) => c.value)
      /**
       * If this after all checks still will be null, user will not see any cards.
       * It will happen though only if user has no clinics available.
       *
       * @type {string | null}
       */
      let clinicId = null

      if (selectedClinicId) {
        // check if stored clinic is  still available for current user
        if (availableClinics.includes(selectedClinicId)) {
          clinicId = selectedClinicId
        }
      } else {
        const currentClinicId = gon.application.current_clinic.id.toString()

        if (availableClinics.includes(currentClinicId)) {
          clinicId = currentClinicId
        } else {
          if (availableClinics.length) {
            clinicId = availableClinics[0]
          }
        }
      }

      dispatch('selectClinic', clinicId)
    },

    /**
     * @param commit
     * @param {string} clinicId
     */
    selectClinic ({ commit }, clinicId) {
      store.set(SELECTED_CLINIC_KEY, clinicId)
      commit('setGlobalClinicSelectorValue', clinicId)
      commit('setAllSelectors', {
        selectorName: 'clinic_id',
        value: clinicId,
      })
    },
  },
}
