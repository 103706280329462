import { getters } from '@/vue_components/store/modules/egiszEmd/emdGetters'
import { mutations } from '@/vue_components/store/modules/egiszEmd/emdMutations'
import { actions } from '@/vue_components/store/modules/egiszEmd/emdActions'
import { filtersBase } from '@/vue_components/store/modules/filters_base'

export const PAGE_SIZE = 50

export const emdState = {
  namespaced: true,

  state: () => ({
    emdList: [],
    selectedEmd: null,
    emdLoaderActive: false,

    emdTypes: null,

    // для обновления состояния ЭМД активной энтри
    activeEntryEmd: null,

    sign: {
      submitWithSign: true,
      workplaceId: null,
      certificateId: null,
      localMachine: false,
    },
  }),

  getters,
  mutations,
  actions,

  modules: {
    filtersBase,
  },
}
