import { request } from '@/lib/transport/request'
import {
  getEgiszReadyAdapter,
  getRelationsAdapter,
  getRelativeTypesAdapter,
} from '@/api_entities/clients/clients_adapters'
import { addLegalRepresentativesToForm } from '@/forms/presets/client/update_form'
import { NETRIKA_CATALOGS } from '@/api_entities/egisz/catalogs/const'
import { cloneDeep } from 'lodash'
import { HTTP_METHODS } from '@/lib/transport/MRequest'

export const clientsEndpoint = {
  getRelations (clientId) {
    const options = {
      type: 'get',
      url: Routes.legal_representatives_list_client_path(clientId),
    }

    return request(options, getRelationsAdapter.toClient).promise
  },

  getRelativeTypes () {
    const options = {
      type: 'get',
      url: Routes.egisz_netrika_catalogs_list_path({ title: NETRIKA_CATALOGS.RELATION_TYPES }),
    }

    return request(options, getRelativeTypesAdapter.toClient).promise
  },

  setRelations (relations, relationsSelector) {
    return new Promise((resolve) => {
      addLegalRepresentativesToForm(relations, relationsSelector)
      resolve()
    })
  },

  setRelationRemote (clientId, data) {
    const options = {
      type: HTTP_METHODS.PATCH,
      url: Routes.determinate_legal_representative_info_client_path(clientId),
      data,
    }

    return request(options).promise
  },

  getEgiszReady (filters) {
    const options = {
      url: Routes.list_egisz_clients_path(),
      method: 'POST',
      data: { sort_params: getEgiszReadyAdapter.toServer(cloneDeep(filters)) },
    }

    return request(options, getEgiszReadyAdapter.toClient).promise
  },

  addPatient (data) {
    const options = {
      type: 'post',
      url: Routes.egisz_iemk_add_patient_path(),
      data,
    }

    return request(options).promise
  },

  updatePatient (data) {
    const options = {
      type: 'post',
      url: Routes.egisz_iemk_update_patient_path(),
      data,
    }

    return request(options).promise
  },

  getEgiszClientById (id) {
    const options = {
      type: 'get',
      url: Routes.egisz_client_path(id),
    }

    return request(options).promise
  },

  getShortName (id) {
    const options = {
      type: 'get',
      url: Routes.short_name_egisz_client_path(id),
    }

    return request(options).promise
  },

  fetchCreditDebtValue (clientId) {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.credit_debt_client_path(clientId),
    }

    return request(options).promise
  },

  fetchEntriesWithoutOrderInfo (clientId) {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.entries_without_order_client_path(clientId),
    }

    return request(options).promise
  },

  fetchLastEmkNumber () {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.emk_last_clients_path(),
    }

    return request(options)
  },
}
