import BaseFormatter from './BaseFormatter'

export default class AddressFormatter extends BaseFormatter {
  fullAddress (addressInfo) {
    if (!addressInfo) return ''

    const parts = [
      addressInfo.country,
      addressInfo.region,
      addressInfo.area,
      addressInfo.city ? `${t('short_city')}${addressInfo.city}` : '',
      addressInfo.street ? `${t('short_street')} ${addressInfo.street}` : '',
      addressInfo.house ? `${t('short_house')}${addressInfo.house}` : '',
      addressInfo.building ? `${t('short_building')} ${addressInfo.building}` : '',
      addressInfo.housing ? `${t('short_housing')} ${addressInfo.housing}` : '',
      addressInfo.flat ? `${t('short_flat')} ${addressInfo.flat}` : '',
    ]

    return parts.filter(Boolean).join(', ')
  }

  shortAddress (addressInfo) {
    if (!addressInfo) return ''

    const parts = [
      addressInfo.city ? `${t('short_city')}${addressInfo.city}` : '',
      addressInfo.street ? `${t('short_street')} ${addressInfo.street}` : '',
      addressInfo.house ? addressInfo.house : '',
      addressInfo.flat ? addressInfo.flat : '',
    ]

    return parts.filter(Boolean).join(', ')
  }
}

AddressFormatter.namespace = 'address'
