import formatter from '@/lib/formatters/formatter'

const MAX_ITER = 20

const recursiveUpdate = (item, iter = 0) => {
  if (!item.children) {
    item.disabled = true // мы против чайлдфри

    return
  }

  if (item.children && !item.children.length) {
    item.isLeaf = true

    return
  }

  if (iter > MAX_ITER) {
    console.info(`item: ${item} stopped on iteration: ${iter}`)

    return
  }

  if (item.children.length) {
    item.disabled = true // нельзя выбирать группы
  }

  item.children.forEach((child) => recursiveUpdate(child, iter + 1))
}

export const buildFlatObjectArray = (data = []) => {
  return data.map((item) => {
    return {
      ...item,
      isLeaf: true,
    }
  })
}

export const doctorTreeAdapter = {
  toClient (data, isFlat = false) {
    if (isFlat) { return buildFlatObjectArray(data) }

    data.forEach((item) => { recursiveUpdate(item) })

    return data
  },
}

export const fetchAttendingDoctorsAdapter = {
  toClient (response) {
    response.data = response.data.map((item) => ({
      ...item,
      fullName: formatter.formatValue(item, ['client:fullName']),
    }))

    return response
  },
}
