export const DEFAULT_END_TIME = '24:00'
export const DEFAULT_END_TIME_MINUS_MINUTE = '23:59'

export const DEFAULT_TIME_PICKER_OPTIONS = Object.freeze({
  STEP: '01:00',
  START: '00:00',
  END: DEFAULT_END_TIME,
})

export const FILTER_TYPE = Object.freeze({
  USER: 'user',
  SPECIALTY: 'specialty',
  ENTRY_TYPE: 'entry_type',
})

export const getFilterTypes = () => Object.values(FILTER_TYPE)
  .map((key) => ({ id: key, title: t('reception.time_finder.search_by')[key] }))

export const FILTERS = Object.freeze({
  CLINICS: 'clinicIds',
  SPECIALTIES: 'specialtyIds',
  ENTRY_TYPES: 'entryTypeIds',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  DATE: 'date',
  USERS: 'userIds',
  TARGET_DURATION: 'targetDuration',
  CLOSEST_RECORDING_POINT: 'closestRecordingPoint',
  TYPE: 'filterType',
})

export const STABLE_FILTERS = Object.freeze([
  FILTERS.TARGET_DURATION,
  FILTERS.CLOSEST_RECORDING_POINT,
  'limit',
  'offset',
])

export const FILTERS_MAP = Object.freeze({
  [FILTER_TYPE.USER]: FILTERS.USERS,
  [FILTER_TYPE.SPECIALTY]: FILTERS.SPECIALTIES,
  [FILTER_TYPE.ENTRY_TYPE]: FILTERS.ENTRY_TYPES,
})

export const getDefaultAppointmentTimeFinderFilters = () => {
  const now = [
    moment().startOf('day').toDate(),
    moment().startOf('day').add(7, 'days').toDate(),
  ]

  return {
    [FILTERS.CLINICS]: null,
    [FILTERS.SPECIALTIES]: null,
    [FILTERS.ENTRY_TYPES]: null,
    [FILTERS.START_TIME]: DEFAULT_TIME_PICKER_OPTIONS.START,
    [FILTERS.END_TIME]: DEFAULT_TIME_PICKER_OPTIONS.END,
    [FILTERS.DATE]: now,
    [FILTERS.USERS]: null,
    [FILTERS.TARGET_DURATION]: null,
    [FILTERS.CLOSEST_RECORDING_POINT]: false,
    [FILTERS.TYPE]: FILTER_TYPE.USER,
  }
}
