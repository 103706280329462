import { MIN_DURATION } from '@/vue_components/appointment/const.js'

export function getDefaultFormClient () {
  return {
    additional: '',
    birthdate: null,
    company: null,
    department: '',
    email: '',
    groups: [],
    legalRepresentatives: [],
    inn: '',
    oms: '',
    onlineAccess: '',
    name: '',
    noSms: false,
    noWhatsApp: gon.application.new_clients_deny_default || false,
    noSmsDispatches: false,
    noCalls: false,
    noEmail: false,
    personalDiscount: 0,
    phone: '',
    position: '',
    nsiProfession: null,
    secondName: '',
    serviceCard: '',
    sex: null,
    snils: '',
    surname: '',
    treatingDoctor: null,
    sendDataToEgisz: false,
    patientCardNumber: null,

    ...getDefaultFormClientTabs(),
  }
}

export function getDefaultFormClientTabs () {
  return {
    index: '',
    country: '',
    region: '',
    area: '',
    city: '',
    street: '',
    house: '',
    flat: '',
    nsiRussianSubjectId: null,
    documentType: null,
    documentSeries: '',
    documentNumber: '',
    whoIssue: '',
    issueDate: '',
  }
}

export function getDefaultFormClientSummary () {
  return {
    clientId: null,
    medicalRecordId: null,
    paidSum: 0,
    creditDebt: 0,
    lastVisit: '',
    previousAppointmentFailed: false,
    lastVisitToTheSameDoctorDaysAgo: '',
    lastVisitToTheSameDoctor: '',
    advDistribution: [],
    clientGroups: [],
    clientGroupBlackList: '',
    clientGroupVip: '',
    hasActiveMedicalPolicy: false,
  }
}

/**
 * @param {Object} extra
 * @returns {{date: string, note: string, attractionSourceId: null, updatedBy: string, entryTypes: *[], notifyAfterSave: boolean, clientComment: string, appointmentSourceId: null, appointmentTypeId: number, byDms: boolean, sendSmsConfirmation: boolean, lastSmsRemind: {}, lastSmsNotify: {}, duration: number, referral: null, addToWaitingList: boolean, confirmByCall: boolean, createdBy: string, time: string, remindByCall: boolean, remindBeforeDate: boolean, lastSmsConfirmation: {}}}
 */
export function getDefaultFormAppointment (extra = {}) {
  return {
    entryTypes: [],
    note: '',
    clientComment: '',
    duration: MIN_DURATION,
    appointmentTypeId: 1, // Первичный приём
    referral: null,
    attractionSourceId: extra.defaultAttractionSourceId,
    appointmentSourceId: null,
    byDms: false,
    addToWaitingList: false,
    remindBeforeDate: false,
    notifyAfterSave: false,
    sendSmsConfirmation: false,
    remindByCall: false,
    confirmByCall: false,
    lastSmsConfirmation: {},
    lastSmsNotify: {},
    lastSmsRemind: {},
    createdBy: '',
    updatedBy: '',
    date: '',
    time: '',
  }
}

export function getDefaultFormReferral () {
  return {
    name: '',
    surname: '',
    secondName: '',
    phone: '',
    email: '',
    code: '',
    medicalDoctor: null,
    company: null,
    specialtyIds: [],
    about: '',
    kind: 'external',

    selectAddress: '',
    index: '',
    country: '',
    region: '',
    area: '',
    city: '',
    street: '',
    house: '',
    flat: '',
    nsiRussianSubjectId: null,
  }
}

export function getDefaultFormReminderItem () {
  return {
    title: '',
    date: '',
    type: null,
    status: null,
    client: null,
    responsible: [],
    doctor: null,
    note: '',
    createdAt: '',
    updatedAt: '',
    createdBy: '',
    updatedBy: '',
  }
}

export function getDefaultFormWaitingItem (doctor = null) {
  return {
    currentDoctor: doctor,
    currentClinicId: null,
    appointment_id: null,
    hasDependAppointment: false,
    note: '',
    isUrgent: false,
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
  }
}

export function getDefaultValidationsClient () {
  return {
    surname: [],
    name: [],
    second_name: [],
    birthdate: [],
    phone: [],
    groups: [],
    email: [],
    treatingDoctor: [],
    personalDiscount: [],
    legalRepresentatives: [],
    additional: [],
    sex_id: [],
    snils: [],
    patient_card_number: [],
  }
}

export function getDefaultValidationsClientTabs () {
  return {
    address: {
      index: [],
      country: [],
      region: [],
      area: [],
      city: [],
      street: [],
      house: [],
      flat: [],
    },

    document: {
      series: [],
      number: [],
      whoIssue: [],
      issueDate: [],
    },

    job: {
      company: [],
      position: [],
      department: [],
    },

    other: {
      oms: [],
      inn: [],
      snils: [],
    },
  }
}

export function getDefaultValidationsAppointment () {
  return {
    duration: [],
    appointment_type_id: [],
    attraction_source_id: [],
    status: [],
  }
}

export function getDefaultValidationsReferral () {
  return {
    surname: [],
    email: [],
    address: {
      index: [],
      country: [],
      region: [],
      area: [],
      city: [],
      street: [],
      house: [],
      flat: [],
    },
  }
}

export function getDefaultValidationsReminderItem () {
  return {
    title: [],
    date: [],
    reminder_type: [],
    status: [],
    responsible: [],
  }
}

export function getDefaultValidationsCompany () {
  return {
    title: [],
    company_types: [],
    individual_tax_number: [],
    kpp: [],
    phone1: [],
    phone2: [],
  }
}

export function getDefaultValidationsWaitingList () {
  return {
    ...getDefaultValidationsClient(),
    date: [],
    user_id: [],
  }
}
