
export class MemoRequest {
  /**
   * @type {Map<string, JQuery.jqXHR>}
   * @private
   */
  _memo = new Map()

  constructor () {
    if (MemoRequest.instance) {
      return MemoRequest.instance
    }

    MemoRequest.instance = this

    return this
  }

  _isAbortable (url) {
    return false
  }

  /**
   * @param {string} url
   * @param {JQuery.jqXHR} ajax
   */
  save (url, ajax) {
    this.abort(url)
    this._memo.set(url, ajax)
  }

  /**
   * @param {string} url
   */
  remove (url) {
    this._memo.delete(url)
  }

  /**
   * @param {string} url url
   */
  abort (url) {
    if (!this._memo.has(url)) { return }

    if (this._isAbortable(url)) {
      this._memo.get(url).abort()
    }

    this._memo.delete(url)
  }
}
