import {
  OUTCOMING_CALL_TYPE,
  INCOMING_CALL_TYPE,
  CALL_NTF_STATUS_RESULT,
  FINISHED_CALL_STATUS,
  CONFIRMATION_PENDING,
  CONFIRMATION_CONFIRMED,
  CONFIRMATION_CLIENT_CANCELED,
  CONFIRMATION_FAILED,
  REMINDER_PENDING,
  REMINDER_REMINDED,
  REMINDER_FAILED,
} from './const.js'

const callNotificationsIcons = {}
// ah0774 - replace these when FontAwesome 6 available, there are no icons for calls now
callNotificationsIcons[OUTCOMING_CALL_TYPE] = 'fad fa-headset'
callNotificationsIcons[INCOMING_CALL_TYPE] = 'fad fa-phone-alt'
callNotificationsIcons[CALL_NTF_STATUS_RESULT] = 'fad fa-phone-square-alt'
callNotificationsIcons[FINISHED_CALL_STATUS] = 'fad fa-phone-rotary'

const callModalColors = {}
callModalColors[OUTCOMING_CALL_TYPE] = 'modal-header-orange'
callModalColors[INCOMING_CALL_TYPE] = 'modal-header-green'

const confirmationCallIcons = {}
confirmationCallIcons[CONFIRMATION_PENDING] = 'fad fa-phone-volume'
confirmationCallIcons[CONFIRMATION_CONFIRMED] = 'fad fa-phone-plus'
confirmationCallIcons[CONFIRMATION_CLIENT_CANCELED] = 'fad fa-user-times'
confirmationCallIcons[CONFIRMATION_FAILED] = 'fad fa-phone-slash'

const reminderCallIcons = {}
reminderCallIcons[REMINDER_PENDING] = 'fad fa-rss'
reminderCallIcons[REMINDER_REMINDED] = 'fad fa-bell'
reminderCallIcons[REMINDER_FAILED] = 'fad fa-bell-slash'

export { callNotificationsIcons, callModalColors, confirmationCallIcons, reminderCallIcons }
