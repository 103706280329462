import { EGISZ_STATUSES } from '@/vue_components/egisz/egisz_module/const/egisz_statuses'

export const mutations = {
  /**
   * @param state
   * @param {Object | null} [medDocument] - при пустом вызове SET_SELECTED_MED_DOCUMENT, убирается выбранный док
   * @constructor
   */
  SET_SELECTED_MED_DOCUMENT (state, medDocument = null) {
    state.selectedEmd = medDocument
  },

  DELETE_MED_DOCUMENT (state, medDocumentId) {
    if (!medDocumentId) { return }

    state.emdList = state.emdList
      .filter((item) => item.id !== medDocumentId)
  },

  REGISTER_MED_DOCUMENT (state, medDocumentId) {
    const medRecord = state.emdList.find((item) => item.id === medDocumentId)
    if (!medRecord) { return }

    medRecord.status = EGISZ_STATUSES.SUCCESS
  },

  REGISTER_MED_DOCUMENT_FAILED (state, medDocument) {
    const medRecord = state.emdList.find((item) => item.id === medDocument)
    if (!medRecord) { return }

    medRecord.status = EGISZ_STATUSES.FAILURE
  },

  SET_MED_DOCUMENT_LOADER_STATE (state, active) {
    state.emdLoaderActive = active
  },

  SET_MED_DOCUMENTS (state, medDocuments = []) {
    state.emdList = medDocuments
  },

  SET_MED_DOCUMENT_TYPES (state, medDocumentTypes = []) {
    state.emdTypes = medDocumentTypes
  },

  SET_ACTIVE_ENTRY_EMD (state, activeEntryEmd) {
    state.activeEntryEmd = activeEntryEmd
  },

  /**
   * @param state
   * @param {{ field: String, value: any }} payload
   */
  vxSetSignAttribute (state, payload) {
    const { field, value } = payload
    if (!field) {
      throw new Error('Changed field not found')
    }

    state.sign[field] = value
  },

  vxUpdateSignStatus (state, { medDocumentId, holderScope }) {
    const medDocument = state.emdList.find((emd) => emd.id === medDocumentId)
    if (!medDocument) { return }

    const signStatus = medDocument.attachment_signature_status
    if (!signStatus) { return }

    signStatus[`${holderScope}_signed`] = true
  },
}
