
export const windowStore = {
  namespaced: true,
  state: {
    clientHeight: document.documentElement.clientHeight,
    clientWidth: document.documentElement.clientWidth,
    zoom: Math.round(window.devicePixelRatio * 100) / 100,
  },

  getters: {
    vxGetWindowClientHeight: (state) => state.clientHeight,
    vxGetWindowClientWidth: (state) => state.clientWidth,
    vxIsSmallWindow: (state) => state.clientWidth < 1600,
    vxGetWindowSize: (state) => ({
      clientHeight: state.clientHeight,
      clientWidth: state.clientWidth,
    }),
    vxGetZoom: (state) => state.zoom,
  },

  mutations: {
    vxSetClientHeight (state, payload) {
      state.clientHeight = payload
    },

    vxSetClientWidth (state, payload) {
      state.clientWidth = payload
    },

    vxSetZoom (state, payload) {
      state.zoom = payload
    },
  },

  actions: {
    vxSubscribeOnWindowEvents ({ commit }) {
      window.addEventListener('resize', () => {
        commit('vxSetClientHeight', document.documentElement.clientHeight)
        commit('vxSetClientWidth', document.documentElement.clientWidth)
        commit('vxSetZoom', Math.round(window.devicePixelRatio * 100) / 100)
      })
    },
  },
}
