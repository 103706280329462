import { egiszWs } from '@/vue_components/store/modules/egisz/ws/egisz_ws'
import { clients } from '@/vue_components/store/modules/egisz/clients'
import { user } from '@/vue_components/store/modules/egisz/user'

export const egisz = {
  namespaced: true,
  modules: {
    egiszWs,
    clients,
    user,
  },
}
