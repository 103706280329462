// Постановление Правительства РФ от 09.02.2022 N 140
const EXPIRE_DAYS = 1

export const getRelativeTypesAdapter = {
  toClient (data) {
    return data.map((item) => {
      const newItem = { ...item }
      newItem.id = parseInt(newItem.id)

      return newItem
    })
  },
}

const concatNameAndRelation = (name, relation) => {
  return relation
    ? `${name} (${relation})`
    : name
}

export const getRelationsAdapter = {
  toClient (data) {
    return data.map((item) => ({
      legal_representative_id: item.id,
      full_name: item.full_name,
      full_name_with_relation: concatNameAndRelation(item.full_name, item.relation_type_title),
      relation_type_title: item.relation_type_title,
      relation_type_id: item.relation_type_id,
    }))
  },
}

export const getEgiszReadyAdapter = {
  toServer (filters) {
    if (filters.period && filters.period.length) {
      filters.period = [
        Utils.getBaseFormattedDate(filters.period[0]),
        Utils.getBaseFormattedDate(filters.period[1]),
      ]
    }

    return filters
  },

  toClient (response) {
    response.data = response.data.map((item) => {
      if (item.registration_updated_at) {
        const registrationUpdatedAt = moment(item.registration_updated_at, 'DD.MM.YYYY, HH:mm')
        item.updated_at_formatted = Utils.getFormattedDate(item.updated_at, Utils.shortDateTimeFormat)
        item.exp = -1 * (
          registrationUpdatedAt.diff(moment(item.updated_at), 'days')
        ) >= EXPIRE_DAYS

        item.registration_updated_at = Utils.getFormattedDate(registrationUpdatedAt, Utils.shortDateTimeFormat)
      }

      return item
    })

    return response
  },
}
