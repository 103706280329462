import { MExpirableCache } from '@/_api/_storage/MExpirableCache'
import { MNSIPresenter } from '@/_api/AppConfiguration/MNSIPresenter'
import { GLOBAL_TIMES } from '@/_global_scripts/GLOBAL_CONSTS'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'

const MAX_LIMIT = 30

const cache = new MExpirableCache('NSIIdCardTypes', {
  timeout: GLOBAL_TIMES.ONE_WEEK,
})

export const idCardTypesDictionaryStore = {
  namespaced: true,
  state: {
    idCardTypesEnabled: gon.application.nsi.idCardTypesDictionary,
    /**
     * @type {{
     *     id: number,
     *     nsiId: number,
     *     title: string,
     *     code: string,
     * }[]}
     */
    idCardTypes: [],
    idCardTypesIsLoaded: false,
  },

  getters: {
    vxGetNSIIdCardTypesEnabled: (state) => state.idCardTypesEnabled,
    vxGetNSIIdCardTypes: (state) => state.idCardTypes,
    vxGetNSIIdCardTypesIsLoaded: (state) => state.idCardTypesIsLoaded,
    vxGetNSIIdById: (state) =>
      (id) => state.idCardTypes.find((idCardType) => +idCardType.id === +id)?.nsiId,
  },

  mutations: {
    vxSetNSIIdCardTypes (state, idCardTypes) {
      state.idCardTypes = idCardTypes
      state.idCardTypesIsLoaded = true
    },

    vxAppendNSIIdCardTypes (state, idCardTypes) {
      state.idCardTypes = [
        ...state.idCardTypes,
        ...idCardTypes,
      ]
    },

    vxSetIdCardTypesParams (state) {
      state.idCardTypesIsLoaded = true
      cache.save(state.idCardTypes)
    },
  },

  actions: {
    async vxLoadNSIIdCardTypes ({ commit, dispatch }, offset = 0) {
      const restoredData = cache.restore()
      if (restoredData && !offset) {
        commit('vxSetNSIIdCardTypes', restoredData)

        return
      }

      const { errors, data, totalItems } = await new MNSIPresenter()
        .list({ offset, limit: MAX_LIMIT, dictionary: NSI_DICTIONARIES.ID_CARD_TYPES })

      if (errors) { return }

      commit('vxAppendNSIIdCardTypes', data)

      const nextOffset = offset + MAX_LIMIT
      totalItems && totalItems > nextOffset
        ? dispatch('vxLoadNSIIdCardTypes', nextOffset)
        : commit('vxSetIdCardTypesParams')
    },
  },
}
