import { APPOINTMENT_TIME_FINDER_STORAGE_KEY } from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/const/const'

export const saveToCache = (data, key = APPOINTMENT_TIME_FINDER_STORAGE_KEY) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const loadFromCache = (key = APPOINTMENT_TIME_FINDER_STORAGE_KEY) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || {}
  } catch {
    return {}
  }
}

export const removeFromCache = (key = APPOINTMENT_TIME_FINDER_STORAGE_KEY) => {
  localStorage.removeItem(key)
}
