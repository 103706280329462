import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class PersonalCheckupListTotalCell {
  /**
   * @param {{ totalItems: number }} rawData
   */
  constructor (rawData) {
    this.number = new MTableCell(t('total'))
    this.date = new MTableCell(rawData.totalItems)
  }
}
