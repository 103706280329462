/**
 * This class defines behaviour for formatters.
 * Formatting is based on rules: rule is a function which is referred
 * by name in the `formatValue` method. They can be defined in class methods
 * or in exported from separate files. You can provide an argument for the rule
 * by using an array of type `[string, ...args]` instead of just the rule name,
 * where the first element is the rule name, and all other elements are arguments for the rule.
 *
 * When defining a formatter function in a formatter class, you need to specify
 * its name in the `defaultRules` static getter.
 *
 * You SHOULD return a concatenation of superclass rules array and rules array of your class
 * if you what to inherit superclass rules in your formatter class.
 */
/**
 * @typedef {string|any[]} FormatterRule
 */
export default class AbstractFormatter {
  static get defaultRules () {
    return []
  }

  static get ignoreList () {
    return new Set(['cloneRules', 'addRule', 'removeRule', 'formatValue', 'applyRule'])
  }

  /**
   *
   * @param {Object} [rules]
   */
  constructor (rules) {
    /**
     *
     * @type {Map<string, Function>}
     */
    this.rules = new Map()
    this.cloneRules(this, this.constructor.ignoreList)
    if (rules) {
      this.cloneRules(rules)
    }
  }

  /**
   * Clone rules from the object
   *
   * @param {Object} source
   * @param {Set<string>} [ignoreList]
   */
  cloneRules (source, ignoreList) {
    const methodNames = new Set()
    let currentObject = source
    do {
      Object.getOwnPropertyNames(currentObject).forEach((name) => {
        if (typeof currentObject[name] === 'function' && name !== 'constructor') {
          methodNames.add(name)
        }
      })
    } while ((currentObject = Object.getPrototypeOf(currentObject)))

    if (ignoreList) {
      for (const ignored of ignoreList) {
        methodNames.delete(ignored)
      }
    }
    methodNames.forEach((ruleName) => {
      this.addRule(ruleName, source[ruleName])
    })
  }

  /**
   * This adds a bound rule to the rules map
   *
   * @param {string} ruleName
   * @param {Function} fn
   */
  addRule (ruleName, fn) {
    if (fn && typeof fn === 'function') {
      this.rules.set(ruleName, fn.bind(this))
    }
  }

  /**
   *
   * @param {string} ruleName
   */
  removeRule (ruleName) {
    this.rules.delete(ruleName)
  }

  /**
   * This method runs specified rules on the given value
   *
   * @param {any} value
   * @param {FormatterRule[]} rules
   * @return {string}
   */
  formatValue (value, rules = []) {
    return rules.reduce((currentValue, rule) => {
      return this.applyRule(rule, currentValue)
    }, value)
  }

  /**
   * @param {string|*[]} ruleName
   * @param {*} value
   * @return {*}
   */
  applyRule (ruleName, value) {
    // check if there are arguments for the rule
    const args = Array.isArray(ruleName) ? ruleName : [ruleName]
    const name = args[0]
    const rule = this.rules.get(name)
    if (!rule) {
      console.warn(`Formatter: Rule ${ruleName} is not defined`)

      return value
    }

    return rule(value, ...args.slice(1))
  }
}
