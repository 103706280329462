import { getDefaultFormClientSummary } from '@/vue_components/doctor_schedules/methods/appointment_default_data'

export const DOCTOR_SCHEDULES_STORAGE = 'doctor_schedules_page_storage'
export const DOCTOR_SCHEDULES_SIDEBAR_STORAGE = 'doctor_schedules_page_sidebar_storage'
export const DOCTOR_SCHEDULE = 'doctorSchedule'
export const CABINET_SCHEDULE = 'cabinetSchedule'
export const ONLY_NEED_APPROVAL_APPOINTMENTS_STORAGE_KEY =
  'online_recording_list_only_need_approval_appointments'
export const SIDEBAR_CALENDAR = 'calendar'
export const SIDEBAR_REMINDERS = 'reminders'
export const SIDEBAR_WAITING_LIST = 'waiting-list'
export const SIDEBAR_ONLINE_RECORDING = 'online-recording'

export const ADMINISTRATOR_PRESET = 'administrator'
export const DOCTOR_PRESET = 'doctor'
export const ONLINE_RECORDING_LIST_ANIMATION_TIMEOUT = 300

export const MODAL_TYPES = Object.freeze({
  CREATE_APPOINTMENT: 'createAppointment',
  EDIT_APPOINTMENT: 'editAppointment',
  LEGAL_REPRESENTATIVE: 'legal',
  CREATE_REFERRAL: 'referral',
  COMPANY: 'company',
  CREATE_WAITING_ITEM: 'createWaitingItem',
  EDIT_WAITING_ITEM: 'editWaitingItem',
  CONFIRMATION: 'confirmation',
  ALERT: 'alert',
  CREATE_WORK_TIME: 'createWorkTime',
  LAST_APPOINTMENT: 'lastAppointment',
  CREATE_REMINDER: 'createReminderItem',
  EDIT_REMINDER: 'editReminderItem',
  COPY_APPOINTMENT_WITH_SERVICES: 'copyAppointmentWithServices',
  USER_SELECT: 'userSelect',
})

export const APPOINTMENT_SOURCE_MEDODS = 1

export const ACTIVE_SCHEDULE_INFO = Object.freeze({
  DOCTOR: { title: t('doctor'), icon: 'fad fa-fw fa-user-friends panel-icon' },
  CABINET: { title: t('cabinet'), icon: 'fad fa-fw fa-door-open panel-icon' },
})

export const ACTIVE_SIDEBAR_INFO = Object.freeze({
  CALENDAR: { title: t('calendar'), icon: 'fad fa-calendar-alt panel-icon', color: 'calendar' },
  REMINDERS: { title: t('reminders'), icon: 'fad fa-tasks panel-icon', color: 'reminder' },
  WAITING_LIST: { title: t('waiting_list'), icon: 'fad fa-clipboard-list panel-icon', color: 'waiting' },
  ONLINE_RECORDING: { title: t('entry_online_access'), icon: 'fad fa-globe panel-icon', color: 'online' },
})

export const DAYS_COUNT = Object.freeze(
  [1, 2, 3, 5, 7].map((d) => ({ value: d, title: d }))
)

export const ALLOWED_PROHIBITED_FORM = Object.freeze(
  Object.entries(t('enums.entry_type.send_message_on_ready_status'))
    .map(([id, title]) => ({ id, title }))
)

export const SEXES_VALUES = Object.freeze([
  { id: 1, title: 'Мужской' },
  { id: 2, title: 'Женский' },
])
export const APPOINTMENT_STATUSES_ORDER = Object.freeze([
  'need_approval',
  'confirmed_by_administrator',
  'approved',
  'arrived',
  'serviced',
  'billed',
  'paid',
  'canceled',
  'failed',
])

export const APPOINTMENT_STATUS_VALUES = Object.freeze(
  APPOINTMENT_STATUSES_ORDER
    .map((id) => ({
      id,
      title: t(`enums.appointment.status.${id}`),
    }))
)

export const INSERT_APPOINTMENT_TYPES = Object.freeze({
  CLIENT: 'client',
  PHONE: 'phone',
  WAITING: 'waiting',
  REMINDER: 'reminder',
})

export const REMINDER_TYPE_VALUES = Object.freeze([
  { id: 'call', title: t('call') },
  { id: 'business', title: t('business') },
])

export const REMINDER_STATUS_VALUES = Object.freeze([
  { id: 'pending', title: t('reminder_status_pending') },
  { id: 'done', title: t('reminder_status_done') },
  { id: 'suspend', title: t('reminder_status_suspend') },
  { id: 'fail', title: t('reminder_status_fail') },
  { id: 'cancel', title: t('reminder_status_cancel') },
])

export const getFormData = (store) => ({
  doctor: {},
  sexes: [...SEXES_VALUES],
  onlineAccessOptions: [...ALLOWED_PROHIBITED_FORM],
  remindBeforeDate: store.getters.GET_SMS_REMIND_DEFAULT,
  notifyAfterSave: store.getters.GET_SMS_NOTIFY_DEFAULT,
  sendSmsConfirmation: store.getters.GET_SMS_CONFIRMATION_DEFAULT,

  similarClients: {
    clients: [],
    selected: null,
    currentModal: null,
  },

  clientSummary: { ...getDefaultFormClientSummary() },

  address: {
    searchQuery: '',
  },

  maxDuration: 0,

  service: {
    list: [],
    error: false,
    loading: false,
    searchQuery: '',
  },

  specialties: {
    loading: false,
    error: false,
    list: [],
    searchQuery: '',
  },

  uisRemindOption: false,
  uisConfirmOption: false,
  remindBeforeDateStubModalVisible: false,
  notifyAfterSaveStubModalVisible: false,
  sendSmsConfirmationStubModalVisible: false,
  remindByCallStubModalVisible: false,
  confirmByCallStubModalVisible: false,
})

export const WAITING_LIST_ITEM_HEIGHT = 50
export const REMINDERS_LIST_ITEM_HEIGHT = 32
export const ONLINE_RECORDING_ITEM_HEIGHT = 50
export const SIDEBAR_LAZY_LOAD_DEBOUNCE = 50
export const SIDEBAR_LAZY_LOAD_THRESHOLD = 0.75

export const ITEM_CREATED = 'created'
export const ITEM_UPDATED = 'updated'
export const ITEM_DELETED = 'deleted'

export const isAppointmentMessage = (packet) => packet.type === 'appointment'

export const PROMOBOT_PERMIT_MODAL_TYPES = Object.freeze([
  MODAL_TYPES.CREATE_APPOINTMENT,
  MODAL_TYPES.EDIT_APPOINTMENT,
  MODAL_TYPES.LEGAL_REPRESENTATIVE,
])

export const promobotFieldMap = Object.freeze({
  authority: 'whoIssue',
  birth_date_mrz: 'birthdate',
  birthdate: 'birthdate',
  birth_date: 'birthdate',
  gender: 'sex',
  gender_mrz: 'sex',
  gender_predicted: 'sex',
  issue_date: 'issueDate',
  issue_date_mrz: 'issueDate',
  name: 'name',
  name_mrz: 'name',
  number: 'documentNumber',
  number_mrz: 'documentNumber',
  number2: 'documentNumber',
  patronymic: 'secondName',
  patronymic_mrz: 'secondName',
  series: 'documentSeries',
  series_mrz: 'documentSeries',
  surname: 'surname',
  surname_mrz: 'surname',
  region: 'region',
  // locality: 'locality' // todo?
  city: 'city',
  street: 'street',
  apartment: 'flat',
  house: 'house',
  inn: 'inn',
  snils: 'snils',
  oms: 'oms',
})

export const promobotDocumentTypeToMedodsDocumentTypeMap = Object.freeze({
  'rus.birth_certificate.type1': 'Свидетельство о рождении',
  'rus.drvlic.type1': 'Водительское удостоверение',
  'rus.drvlic.type2': 'Водительское удостоверение',
  'rus.drvlic.type3': 'Водительское удостоверение',
  'rus.passport.biometric': 'Паспорт',
  'rus.passport.international': 'Загранпаспорт гражданина РФ',
  'rus.passport.national': 'Паспорт',
  'rus.passport.registration': 'Паспорт',
  'rus.snils.type1': 'Другой документ',
  'rus.snils.type2': 'Другой документ',
  none: 'Другой документ',
  'rus.oms.type1': 'Полис ОМС',
  'rus.oms.type2': 'Полис ОМС',
  'rus.inn.citizen.type1': 'Другой документ',
  'rus.inn.citizen.type2': 'Другой документ',
  'rus.inn.citizen.type3': 'Другой документ',
  'rus.inn.citizen.type4': 'Другой документ',
  'rus.residence.biometric': 'Вид на жительство',
  'rus.residence.old': 'Вид на жительство',
})

export const PROMOBOT_SNILS = ['rus.snils.type1', 'rus.snils.type2']
export const PROMOBOT_OMS = ['rus.oms.type1', 'rus.oms.type2']
