export const loadNPSDymanics = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: Routes.nps_dynamics_client_feedbacks_feedbacks_path(),
      data: params,
      success (data) {
        resolve(data)
      },
      error: (error) => reject(error),
    })
  })
}
