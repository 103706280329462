import { clientsEndpoint } from '@/api_entities/clients/clients_endpoint'

const ENTITIES = Object.freeze({
  USER: 'User',
  CLIENT: 'Client',
})

const ENTITIES_TEXTS = Object.freeze({
  USER: t('doctor'),
  CLIENT: t('client'),
})

const netrikaErrorFields = t('egisz.fields')
const getText = (fieldName) => {
  const text = netrikaErrorFields[fieldName]

  return text || fieldName
}

const buildUserMessage = (id, value, store) => {
  const doctorShortName = store.getters['globalCatalogs/doctorStore/getDoctorShortName'](id)

  return `${ENTITIES_TEXTS.USER} ${doctorShortName}: "${getText(value)}" `
}

const buildClientMessage = async (id, value) => {
  return new Promise((resolve) => {
    clientsEndpoint.getShortName(id)
      .then((shortName) => resolve(`${ENTITIES_TEXTS.CLIENT} ${shortName}: "${getText(value)}" `))
      .catch(() => { resolve(`${ENTITIES_TEXTS.CLIENT} (${id}): "${getText(value)}" `) })
  })
}

export const extractDataByEntityField = async (store, { entity, id, value, messages }) => {
  let preMessage = `${getText(value)} `
  if (entity === ENTITIES.USER) {
    preMessage = buildUserMessage(id, value, store)
  }

  if (entity === ENTITIES.CLIENT) {
    preMessage = await buildClientMessage(id, value)
  }

  return {
    title: t('egisz.title'),
    message: preMessage + messages.join(', '),
  }
}
