import { HTTP_METHODS, MRequest } from '@/lib/transport/MRequest'

class ClinicEndpoint extends MRequest {
  getFrmoActiveByUserId (userId) {
    const options = {
      url: Routes.selector_collection_clinics_path(),
      type: this.HTTP_METHODS.POST,
      data: {
        sort_params: {
          in_frmo: true,
          only_active: true,
        },
      },
    }

    return this.customRequest(options).promise
  }

  fetchAll (sortParams) {
    const options = {
      url: Routes.selector_collection_clinics_path(),
      type: this.HTTP_METHODS.POST,
      data: {
        sort_params: sortParams,
      },
    }

    return super.getAll(options).promise
  }

  fetchLicensesNumber () {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.licenses_number_clinics_path(),
    }

    return super.get(options).promise
  }

  fetchClinicAddress (clinicId) {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.address_clinic_path(clinicId),
    }

    return super.get(options).promise
  }
}

export const clinicEndpoint = new ClinicEndpoint()
