import locator from './FormattersLocator'

export default class ValueFormatter {
  constructor () {
    /**
     * @type {Map<string, AbstractFormatter>}
     */
    this.formatters = new Map()
    /**
     * @type {Map<string, FormatterRule>}
     */
    this.aliases = new Map()
  }

  /**
   *
   * @param {string} alias
   * @param {FormatterRule[]} rules
   */
  alias (alias, rules) {
    this.aliases.set(alias, rules)
  }

  /**
   *
   * @param {any} value
   * @param {FormatterRule[]} rules
   */
  formatValue (value, rules) {
    return rules.reduce((current, rule) => {
      return this.applyRule(rule, current)
    }, value)
  }

  /**
   *
   * @param {FormatterRule} rule
   * @private
   * @throws Error
   * @return {AbstractFormatter}
   */
  _getFormatterFor (rule) {
    const name = this._getFullRuleName(rule)
    const parts = name.split(':')
    let namespace = 'base'
    if (parts.length > 1) {
      namespace = parts[0]
    }
    if (this.formatters.has(namespace)) {
      return this.formatters.get(namespace)
    }
    const instance = locator.get(namespace)
    if (instance) {
      this.formatters.set(namespace, instance)

      return instance
    }
    throw new Error(`No formatter for rule: '${rule}'.`)
  }

  /**
   *
   * @param {FormatterRule} rule
   * @param {any} value
   */
  applyRule (rule, value) {
    const name = this._getFullRuleName(rule)
    if (this.aliases.has(name)) {
      const aliasedRules = this.aliases.get(name)

      return this.formatValue(value, aliasedRules)
    }
    const extractedRule = this._extractRule(rule)
    const formatter = this._getFormatterFor(rule)

    return formatter.applyRule(extractedRule, value)
  }

  /**
   * Returns rule with name without namespace
   *
   * @param {FormatterRule} rule
   * @return {FormatterRule}
   * @private
   */
  _extractRule (rule) {
    if (Array.isArray(rule)) {
      return [this._extractRuleName(rule[0]), ...rule.slice(1)]
    }

    return this._extractRuleName(rule)
  }

  /**
   * Returns full rule name, with namespace (if present)
   *
   * @param {FormatterRule} rule
   * @return {string}
   * @private
   */
  _getFullRuleName (rule) {
    return Array.isArray(rule) ? rule[0] : rule
  }

  /**
   * Returns rule name without namespace
   *
   * @param {FormatterRule} rule
   * @return {string}
   * @private
   */
  _extractRuleName (rule) {
    const name = this._getFullRuleName(rule)
    const parts = name.split(':')

    return parts[parts.length - 1]
  }
}
