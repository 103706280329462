import { doctorStore } from '@/api_entities/doctors/doctor_store'
import { clinicStore } from '@/api_entities/clinics/clinic_store'
import { nsiStore } from '@/vue_components/store/modules/globalCatalogs/nsi/nsiStore'
import { workplacesStore } from '@/vue_components/store/modules/globalCatalogs/workplacesStore'

export const globalCatalogs = {
  namespaced: true,
  modules: {
    doctorStore,
    clinicStore,
    nsiStore,
    workplacesStore,
  },
}
