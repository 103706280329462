import { getLegalsFormatted } from '@/api_entities/client/clients_adapters'
import formatter from '@/lib/formatters/formatter'
import { pick } from 'lodash'
import { camelToSnake } from '@/_api/_requests/helpers'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { orNull } from '@/_medods_standart_library/msl'

export const formatUser = (user) => ({
  avatar_tag: null,
  ...user,
  full_name: formatter.formatValue(user, ['user:fullName']),
  short_name: formatter.formatValue(user, ['user:shortName']),
})

export const waitingItemAdapter = {
  toServer (formData) {
    return {
      appointment_id: formData.appointmentId,
      depended_appointment: formData.hasDependAppointment,
      user_id: formData.currentDoctor && formData.currentDoctor.id,
      clinic_id: formData.currentClinicId,
      additional: formData.note,
      urgent: formData.isUrgent,
      client_id: formData.clientId,
      date: formData.date,
    }
  },

  toClient (response) {
    return {
      appointmentId: response.appointment_id,
      hasDependAppointment: response.dependedAppointment,
      currentDoctorId: response.user_id,
      currentClinicId: response.clinic_id,
      note: response.additional,
      isUrgent: response.urgent,
      createdAt: response.created_at,
      createdBy: response.created_by_id,
      updatedAt: response.updated_at,
      updatedBy: response.updated_by_id,
      clientId: response.client_id,
      waitingItemId: response.id,
    }
  },
}

export const clientAdapter = {
  toServer (formData) {
    return {
      additional: formData.additional,
      birthdate: formData.birthdate,
      client_group_ids: formData.groups,
      company_id: formData.company && formData.company.id,
      deny_sms_notifications: formData.noSms,
      deny_whatsapp_messages: formData.noWhatsApp,
      deny_sms_dispatches: formData.noSmsDispatches,
      deny_calls: formData.noCalls,
      deny_email: formData.noEmail,
      department: formData.department,
      discount: formData.personalDiscount,
      email: formData.email,
      inn: formData.inn,
      legal_representatives: JSON.stringify(getLegalsFormatted(formData.legalRepresentatives)),
      name: formData.name,
      oms_number: formData.oms,
      online_access: formData.onlineAccess,
      // при вставке демо клиента телефон в форме значение получается с форматированием
      // возможно это будет случаться и в других условиях,
      // поэтому перед отправкой удаляем всё лишнее
      phone: Services.phoneMask.removeMask(formData.phone),
      position: formData.position,
      nsi_profession_id: orNull(formData.nsiProfession?.id),
      second_name: formData.secondName,
      service_card: formData.serviceCard,
      send_data_to_egisz: formData.sendDataToEgisz,
      sex_id: formData.sex,
      snils: formData.snils,
      surname: formData.surname,
      doctor_id: formData.treatingDoctor && formData.treatingDoctor.id,

      address: {
        index: formData.index,
        country: formData.country,
        region: formData.region,
        area: formData.area,
        city: formData.city,
        street: formData.street,
        house: formData.house,
        flat: formData.flat,
      },

      id_card: {
        id_card_title_id: formData.documentType,
        series: formData.documentSeries,
        number: formData.documentNumber,
        who_issue: formData.whoIssue,
        issue_date: formData.issueDate,
      },
    }
  },

  toClient (response) {
    const useNSIIdCardTypesDictionary = gon.application.nsi.idCardTypesDictionary
    const documentType = useNSIIdCardTypesDictionary
      ? response.id_card?.nsi_document_id
      : response.id_card?.id_card_title_id

    const clientAddressData = response.address
      ? {
          index: response.address.index,
          country: response.address.country,
          region: response.address.region,
          area: response.address.area,
          city: response.address.city,
          street: response.address.street,
          house: response.address.house,
          flat: response.address.flat,
          nsiRussianSubjectId: response.address.nsi_russian_subject_id,
          documentType,
          documentSeries: response.id_card?.series,
          documentNumber: response.id_card?.number,
          whoIssue: response.id_card?.who_issue,
          issueDate: response.id_card?.issue_date,
        }
      : {}

    return {
      additional: response.additional,
      birthdate: response.birthdate,
      company: response.company,
      department: response.department,
      email: response.email,
      groups: getClientGroupsIds(response),
      legalRepresentatives: response.legal_representatives.map((item) => {
        return {
          ...item,
          label: [
            vueFilters.fullName(item),
            vueFilters.date(item.birthdate),
            Services.phoneMask.setMask(item.phone),
          ].filter(Boolean).join(', '),
        }
      }),
      inn: response.inn,
      oms: response.oms_number,
      onlineAccess: response.online_access,
      name: response.name,
      advDistribution: response.adv_distribution,
      noSms: response.deny_sms_notifications,
      noWhatsApp: response.deny_whatsapp_messages,
      noSmsDispatches: response.deny_sms_dispatches,
      noCalls: response.deny_calls,
      noEmail: response.deny_email,
      personalDiscount: parseFloat(response.discount),
      phone: response.phone,
      position: response.position,
      nsiProfession: {
        id: response.nsi_profession_id,
      },
      secondName: response.second_name,
      serviceCard: response.service_card,
      sendDataToEgisz: response.send_data_to_egisz,
      sex: response.sex_id,
      snils: response.snils,
      surname: response.surname,
      treatingDoctor: formatUser(response.treating_doctor_object),
      patientCardNumber: response.patient_card_number,
      ...clientAddressData,
    }
  },
}

export const clientSummaryAdapter = {
  toClient (response) {
    return {
      clientId: response.client_id,
      medicalRecordId: response.medical_record_id,
      paidSum: parseFloat(response.paid_sum),
      creditDebt: parseFloat(response.credit_debt),
      lastVisit: response.last_visit,
      previousAppointmentFailed: response.previous_appointment_failed,
      lastVisitToTheSameDoctorDaysAgo: response.last_visit_to_the_same_doctor_days_ago,
      lastVisitToTheSameDoctor: response.last_visit_to_the_same_doctor,
      advDistribution: response.adv_distribution,
      clientGroups: response.client_groups,
      clientGroupBlackList: response.client_group_black_list,
      clientGroupVip: response.client_group_vip,
      serviceCard: response.service_card,
    }
  },
}

export const appointmentAdapter = {
  toServer (formData) {
    const entryTypeIds = formData.entryTypes.map((e) => e.id)
    if (!entryTypeIds.length) {
      entryTypeIds.push('')
    }

    return {
      appointment_source_id: formData.appointmentSourceId,
      client: { id: formData.clientId },
      clinic_id: formData.clinicId,
      date: formData.date,
      status: formData.status,
      time: formData.time,
      user_id: formData.userId,
      add_to_waiting_list: formData.addToWaitingList,
      appointment_type_id: formData.appointmentTypeId,
      attraction_source_id: formData.attractionSourceId,
      by_dms: formData.byDms,
      call_id: formData.callId || '',
      client_id: formData.clientId,
      duration: formData.duration,
      entry_type_ids: entryTypeIds,
      note: formData.note,
      referral_id: formData.referral && formData.referral.id,
      send_confirmation_message: formData.sendSmsConfirmation,
      client_comment: formData.clientComment,
      remind_before_date: formData.remindBeforeDate,
      notify_after_save: formData.notifyAfterSave,
      remind_by_call: formData.remindByCall,
      confirm_by_call: formData.confirmByCall,
      last_sms_confirmation: formData.lastSmsConfirmation,
      last_sms_notify: formData.lastSmsNotify,
      last_sms_remind: formData.lastSmsRemind,
      dental_order_id: formData.dentalOrderId,
    }
  },

  toClient (response) {
    return {
      addToWaitingList: response.add_to_waiting_list,
      appointmentTypeId: response.appointment_type_id,
      attractionSourceId: response.attraction_source_id,
      byDms: response.by_dms,
      duration: response.duration,
      entryTypes: response.entry_types,
      note: response.note,
      referral: formatUser(response.referral),
      sendSmsConfirmation: response.send_confirmation_message,
      clientComment: response.client_comment,
      appointmentSourceId: response.appointment_source_id,
      status: response.status,
      remindBeforeDate: response.remind_before_date,
      notifyAfterSave: response.notify_after_save,
      remindByCall: response.remind_by_call,
      confirmByCall: response.confirm_by_call,
      lastSmsConfirmation: response.last_sms_confirmation,
      lastSmsNotify: response.last_sms_notify,
      lastSmsRemind: response.last_sms_remind,
      createdBy: response.created_by,
      updatedBy: response.updated_by,
    }
  },
}

export const referralAdapter = {
  toServer (formData) {
    return camelToSnake({
      ...pick(formData, ['surname', 'kind', 'name', 'secondName', 'phone', 'email']),
      address_attributes: {
        ...pick(formData, ['index', 'country', 'region', 'area', 'city', 'street', 'house', 'flat']),
        nsiRussianSubjectId: formData.nsiRussianSubjectId,
      },
      company_id: (formData.company && formData.company.id),
      code_string: formData.code,
      medical_user_id: formData.medicalDoctor?.id,
      specialty_ids: formData.specialtyIds.map((specialty) => specialty.id),
    })
  },
}
export const reminderAdapter = {
  toServer (formData) {
    return {
      client_id: formData.client && formData.client.id,
      clinic_id: formData.clinicId,
      doctor_id: formData.doctor && formData.doctor.id,
      responsible_ids: formData.responsible.map(({ id }) => id),
      note: formData.note,
      reminder_type: formData.type,
      status: formData.status,
      date: formData.date,
      title: formData.title,
    }
  },
}

function getClientGroupsIds (response) {
  if (response.client_group_ids) return response.client_group_ids

  return (response.client_groups || []).map((group) => group.id)
}

export const usersAdapter = {
  toClient (response) {
    response.data = response.data.map((item) => {
      item.short_name = formatter.formatValue(item, ['user:shortName'])
      item.full_name = formatter.formatValue(item, ['user:fullName'])

      return item
    })

    return response
  },
}

export const fetchResponsiblesListAdapter = {
  toClient (response) {
    response.data = response.data.map((item) => formatUser(item))

    return response
  },
}

export const fetchRemindersAdapter = {
  toClient (response) {
    response.map((item) => {
      item.doctor = formatUser(item.doctor)
      item.responsibles = item.responsibles.map((responsible) => formatUser(responsible))

      return item
    })

    return response
  },
}
