import { orUndefined } from '@/_medods_standart_library/msl'
import {
  PersonalCheckupDoctorResultsListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDoctorResults/PersonalCheckupDoctorResultsListItem'
import { capitalize } from 'lodash/string'

export const getDoctorResults = (doctorResults: PersonalCheckupDoctorResultsListItem[]) => doctorResults.map((d) => ({
  id: orUndefined(d.dbId),
  userId: orUndefined(d.user?.id),
  checkupsSpecialtyId: d.specialty.id,
  medicalAssessmentId: d.medicalAssessment.id,
  date: Utils.getBaseFormattedDate(d.date, Utils.momentDateFormat),
  foreignUser: d.foreignUser,
  foreignUserName: orUndefined(capitalize(d.foreignUserName)),
  foreignUserSurname: orUndefined(capitalize(d.foreignUserSurname)),
  foreignUserSecondName: orUndefined(capitalize(d.foreignUserSecondName)),
  _destroy: orUndefined(d._destroy),
}))

export const personalCheckupDoctorResultsAdapter = {
  toServer ({ ownerId, doctorResults }) {
    return {
      personalCheckup: {
        id: ownerId,
        doctorResultsAttributes: getDoctorResults(doctorResults),
      },
    }
  },
}
