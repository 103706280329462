import { DEFAULT_TOTAL_PAGES } from '@/vue_components/store/modules/filters_base'

const getFormattedDate = (rawDate) => {
  return moment(rawDate).format(Utils.momentDateTimeFormat)
}

const getDate = (rawDate) => {
  return moment(rawDate).toDate()
}

export const signCertificateAdapter = {
  /**
   * @param {{
   *   systemCertificates: {
   *     commonName: string,
   *     issuedBy: string,
   *     expire: Date,
   *     thumbprint: string,
   *   }[]
   * }}data
   * @return {{
   *   totalItems,
   *   data: ICatalog,
   *   totalPages: number
   * }}
   */
  toClient (data) {
    const now = new Date()

    return {
      data: data.systemCertificates
        .filter((cert) => getDate(cert.expire) > now)
        .map((cert) => {
          cert.id = cert.thumbprint
          cert.title = `${cert.commonName}, ${cert.issuedBy}, ${getFormattedDate(cert.expire)}`

          return cert
        }),
      totalItems: data.systemCertificates.length,
      totalPages: DEFAULT_TOTAL_PAGES,
    }
  },
}
