import { doctorEndpoint } from '@/api_entities/doctors/doctor_endpoint'

export const user = {
  namespaced: true,
  state: () => ({
    accessRights: {
      canUserSendToEgisz: false,
    },

    catalogs: {
      specialties: [],
    },
  }),

  getters: {
    GET_SPECIALTIES: (state) => state.catalogs.specialties,
    vxGetCanUserSendToEgiszValue: (state) => state.accessRights.canUserSendToEgisz,
  },

  mutations: {
    SET_SPECIALTIES (state, specialties) {
      state.catalogs.specialties = specialties
    },

    vxSetAccessRights (state, { rule, value }) {
      if (!Object.hasOwn(state.accessRights, rule)) { return }
      state.accessRights[rule] = value
    },
  },

  actions: {
    loadCatalogs ({ dispatch }) {
      return dispatch('loadSpecialties')
    },

    loadSpecialties ({ commit }) {
      return doctorEndpoint.getSpecialties()
        .then((data) => commit('SET_SPECIALTIES', data))
        .catch(Utils.reportError('store:egisz:user:loadSpecialties()'))
    },
  },
}
