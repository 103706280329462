export enum HTTP_METHODS {
  GET = 'get',
  POST = 'post',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum CRUD_OPS {
  FETCH = 'fetch',
  FETCH_ALL = 'fetchAll',
  LIST = 'list',
  CREATE = 'create',
  UPDATE = 'update',
  DESTROY = 'destroy',
}

export const CRUD_TO_HTTP = Object.freeze({
  [CRUD_OPS.FETCH]: HTTP_METHODS.GET,
  [CRUD_OPS.FETCH_ALL]: HTTP_METHODS.POST,
  [CRUD_OPS.LIST]: HTTP_METHODS.POST,
  [CRUD_OPS.CREATE]: HTTP_METHODS.POST,
  [CRUD_OPS.UPDATE]: HTTP_METHODS.PATCH,
  [CRUD_OPS.DESTROY]: HTTP_METHODS.DELETE,
})

export const WITHOUT_SUCCESS_NOTIFY = Object.freeze([
  CRUD_OPS.FETCH,
  CRUD_OPS.FETCH_ALL,
  CRUD_OPS.LIST,
])
