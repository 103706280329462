import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MListService } from '@/_api/_requests/MListService'
import { signCertificateAdapter } from '@/_api/Workplace/signCertificateAdapter'

export class MSignCertificatePresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'signCertificate',
      location: 'MSignCertificatePresenter',
      routes: {
        one () {},
        all () {},
      },
    })
  }

  /**
   * @param {{
   *   workplaceId: number,
   *   localMachine: boolean,
   * }} data
   * @param config
   * @return {Promise<{totalItems: number, data: ICatalog[], totalPages: number} | *>}
   */
  list (data, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchAllMessage',
      'signCertificates'
    )

    const options = {
      url: Routes.certificate_list_workplace_path(data.workplaceId),
      data: camelToSnake({
        localMachine: data.localMachine,
      }),
    }

    return this.get(
      options,
      (data) => signCertificateAdapter.toClient(snakeToCamel(data))
    )
      .promise
      .catch(MRequestError.withDefault(MListService.getDefaultList(), notification))
  }
}
