<template>
  <div
    class="si-field"
    :class="{ 'si-field__striked': deleted }"
    @click="_emitClick"
    v-on="$listeners"
  >
    <div
      v-if="checkboxVisibility"
      class="options"
      :title="title"
    >
      <input
        type="checkbox"
        class="checkbox"
        :checked="checked"
        :disabled="disabled"
        @input="$emit('checkbox-change', $event)"
      >
    </div>

    <slot />

    <div
      v-if="deleted"
      class="si-field__restore"
    >
      <button
        class="btn btn-xs btn-link"
        type="button"
        @click="$emit('restore')"
      >
        {{ t('restore') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiField',

  props: {
    checked: {
      type: Boolean,
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: null,
    },

    ignoreClick: {
      type: String,
      default: 'ignore-click',
    },

    deleted: Boolean,
  },

  data: () => ({
    clickParams: { count: 0, timer: null, delay: 160 },
    active: false,
  }),

  computed: {
    checkboxVisibility () {
      return this.checked !== undefined
    },
  },

  methods: {
    _emitClick (event) {
      if (event.target.classList.contains(this.ignoreClick)) return

      const {
        clickParams,
        clickParams: { timer, delay },
      } = this

      const {
        isCollapsed,
      } = document.getSelection()

      clearTimeout(timer)
      clickParams.count++

      this.timer =
        setTimeout(() => {
          if (clickParams.count === 1 && isCollapsed) {
            this.$emit('safe-click', event)
          }

          clickParams.count = 0
        }, delay)
    },
  },
}
</script>
