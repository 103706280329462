
export const CHECKUP_STATUS = Object.freeze({
  PLANNED: 'planned',
  IN_PROGRESS: 'in_progress',
  READY: 'ready',
  CANCELED: 'canceled',
})

export const CHECKUP_STATUS_ICONS = Object.freeze({
  [CHECKUP_STATUS.PLANNED]: { icon: 'plannedCheckupStatus', color: 'primary' },
  [CHECKUP_STATUS.IN_PROGRESS]: { icon: 'inProgressCheckupStatus', color: 'primary' },
  [CHECKUP_STATUS.READY]: { icon: 'readyCheckupStatus', color: 'success' },
  [CHECKUP_STATUS.CANCELED]: { icon: 'canceledCheckupStatus', color: 'danger' },
})

export const getCheckupStatusByIcon = (icon) => Object.values(CHECKUP_STATUS)
  .find((status) => CHECKUP_STATUS_ICONS[status].icon === icon)

/**
 * @return ICheckupStatus[]
 */
export const getCheckupStatuses = () => Object.values(CHECKUP_STATUS)
  .map((status) =>
    ({ id: status, title: t(status, { scope: 'checkups.statuses' }) })
  )
