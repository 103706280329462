export const WHATSAPP_TIMEOUT_SETTINGS_SCOPE = 'whatsapp.whatsappTimeoutSettings'
export const WHATSAPP_TIMEOUT_VARIABLES_SCOPE = 'whatsapp.whatsappTimeoutVariables'

export const WABA_CHANNEL_TYPE = 'wapi'
export const WABA_CHANNEL = 'WABA'
export const WHATSAPP_API = 'Whatsapp API'

export const WABA_TEMPLATE_TEXT_SECTION = 'BODY'
export const WABA_PASSWORD_TEMPLATE_TEXT_SECTION = 'password'

export const MAX_SMS_SYMBOLS_LENGTH = 67

export const PRIORITY_BUTTONS_ITEMS = Object.freeze([
  {
    id: 'whatsapp',
    title: t('whatsapp.whatsapp'),
  },
  {
    id: 'sms',
    title: t('whatsapp.sms'),
  },
])

export const CLIENTS_FEEDBACK_MIN_SCORE = 0
export const CLIENTS_FEEDBACK_MAX_SCORE = 10
export const CLIENTS_FEEDBACK_SCORES_COUNT = CLIENTS_FEEDBACK_MAX_SCORE + 1 - CLIENTS_FEEDBACK_MIN_SCORE

export const MIN_APPOINTMENT_CONFIRMATION_BEFORE_TIME_IN_HOURS = 2
export const MAX_APPOINTMENT_CONFIRMATION_BEFORE_TIME_IN_HOURS = 72
export const MIN_APPOINTMENT_CONFIRMATION_AFTER_TIME_IN_HOURS = 1
export const MAX_APPOINTMENT_CONFIRMATION_AFTER_TIME_IN_HOURS = 23

export const MIN_APPOINTMENT_REMIND_BEFORE_TIME_IN_HOURS = 1
export const MAX_APPOINTMENT_REMIND_BEFORE_TIME_IN_HOURS = 23
export const MIN_APPOINTMENT_REMIND_AFTER_TIME_IN_HOURS = 1
export const MAX_APPOINTMENT_REMIND_AFTER_TIME_IN_HOURS = 23

export const CLIENT_MIN_AGE_FOR_BIRTHDAY_MESSAGE = 0
export const CLIENT_MAX_AGE_FOR_BIRTHDAY_MESSAGE = 100

export const MIN_CLIENTS_FEEDBACK_SENDING_FREQUENCY_IN_DAYS = 7
export const MIN_CLIENTS_FEEDBACK_SENDING_DELAY_IN_HOURS = 4

export const MIN_AGGREGATOR_FEEDBACK_SENDING_FREQUENCY_IN_DAYS = 7
export const MIN_AGGREGATOR_FEEDBACK_REQUIRED_TEXT_LENGTH = 0

export const NOTIFY_DELIVERY_WAITING_TIME_VARIABLES = [
  { id: 'PT30S', title: t('30sec', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT1M', title: t('1min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT2M', title: t('2min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT5M', title: t('5min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT10M', title: t('10min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT20M', title: t('20min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const NOTIFY_READ_WAITING_TIME_VARIABLES = [
  { id: 'PT0S', title: t('disabled', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT5M', title: t('5min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT10M', title: t('10min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT20M', title: t('20min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT30M', title: t('30min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const REMIND_DELIVERY_WAITING_TIME_VARIABLES = [
  { id: 'PT5M', title: t('5min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT10M', title: t('10min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT20M', title: t('20min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const REMIND_READ_WAITING_TIME_VARIABLES = [
  { id: 'PT0S', title: t('disabled', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT20M', title: t('20min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT30M', title: t('30min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT1H', title: t('60min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const BIRTHDAY_DELIVERY_WAITING_TIME_VARIABLES = [
  { id: 'PT10M', title: t('10min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT20M', title: t('20min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT30M', title: t('30min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const BIRTHDAY_READ_WAITING_TIME_VARIABLES = [
  { id: 'PT0S', title: t('disabled', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT30M', title: t('30min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT1H', title: t('60min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const SERVICE_STATUS_READ_WAITING_TIME_VARIABLES = [
  { id: 'PT0S', title: t('disabled', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT20M', title: t('20min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT30M', title: t('30min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT1H', title: t('60min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const ONLINE_RECORD_DELIVERY_WAITING_TIME_VARIABLES = [
  { id: 'PT20S', title: t('20sec', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT30S', title: t('30sec', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT1M', title: t('60sec', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const ONLINE_RECORD_READ_WAITING_TIME_VARIABLES = [
  { id: 'PT0S', title: t('disabled', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT1M', title: t('1min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT2M', title: t('2min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT3M', title: t('3min', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const CLIENT_WIDGET_WAITING_TIME_VARIABLES = [
  { id: 'PT60S', title: t('60sec', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT70S', title: t('70sec', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT80S', title: t('80sec', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
  { id: 'PT90S', title: t('90sec', { scope: WHATSAPP_TIMEOUT_VARIABLES_SCOPE }) },
]

export const SERVICES_STATUS_DISPATCH_METHODS = [
  { id: 'by_order', title: t('whatsapp.triggerType.byOrder') },
  { id: 'by_entry', title: t('whatsapp.triggerType.byEntry') },
]

export const CLIENTS_FEEDBACK_SCORES_VARIABLES = Array(CLIENTS_FEEDBACK_SCORES_COUNT)
  .fill()
  .map((_, i) => ({
    id: CLIENTS_FEEDBACK_MIN_SCORE + i,
    title: `${CLIENTS_FEEDBACK_MIN_SCORE + i}`,
  }))
