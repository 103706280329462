import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { vueFilters } from '@/plugins/vue/filtersPlugin/vueFilters'
import { CHECKUP_STATUS_ICONS } from '@/vue_apps/CheckupsModule/store/checkupStatuses'

export class PersonalCheckupListCellItem extends MListServiceItem {
  /**
   * @param {IPersonalCheckupListItem} rawData
   */
  constructor (rawData) {
    super()

    this.id = rawData.id

    this.status = rawData.status
      ? new MTableCell()
        .addTypeIcon(CHECKUP_STATUS_ICONS[rawData.status].icon)
        .addClasses(CHECKUP_STATUS_ICONS[rawData.status].color)
        .addTooltip(t(rawData.status, { scope: 'checkups.statuses' }))
      : new MTableCell()

    this.number = new MTableCell(rawData.id)

    this.date = new MTableCell(`${vueFilters.date(rawData.dateStart)} - ${vueFilters.date(rawData.dateEnd)}`)

    this.client = new MTableCell(rawData.client.fullName)
      .addTypeUrl(Routes.client_path(rawData.client.id))

    this.company = new MTableCell(rawData.company?.title)
      .addTypeUrl(Routes.company_path(rawData.company.id))

    this.responsible = new MTableCell(rawData.responsibleUserShortName)

    this.order = rawData.orderId
      ? new MTableCell(rawData.orderId)
        .addTypeUrl(Routes.order_path(rawData.orderId))
        .addTooltip(t('checkups.goToOrder'))
      : new MTableCell()

    this.comment = rawData.comment
      ? new MTableCell(rawData.comment)
        .addTooltip(rawData.comment)
        .addTypeIcon('comment')
        .addClasses('primary')
      : new MTableCell()

    this.position = new MTableCell(rawData.position)
      .addTooltip(true)
      .addCut()

    this.age = new MTableCell(rawData.client.age)
      .addTooltip(rawData.client.age)
      .addCut()
  }
}
