export const legalRepresentatives = {
  namespaced: true,
  state: {
    form: {
      company: null,
    },
  },
  actions: {
    resetForm ({ commit }) {
      commit('SET_COMPANY', null)
    },
  },
  mutations: {
    SET_COMPANY (state, company) {
      state.form.company = company
    },
  },
  getters: {
    GET_COMPANY (state) {
      return state.form.company
    },
  },
}
