import { request } from '@/lib/transport/request'

export const HTTP_METHODS = Object.freeze({
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  DELETE: 'delete',
})

export class MRequest {
  constructor () {
    this.HTTP_METHODS = HTTP_METHODS
    this.request = request
  }

  __optionsGuard (options) {
    if (options.url) { return }
    throw new Error('Requested url not found')
  }

  /**
   * @param {JQuery.AjaxSettings | Object} options
   * @param {Function} [adapter]
   * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
   */
  getAll (options, adapter) {
    this.__optionsGuard(options)

    return this.request({ ...options, type: options.type || this.HTTP_METHODS.GET }, adapter)
  }

  /**
   * @param {JQuery.AjaxSettings | Object} options
   * @param {Function} [adapter]
   * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
   */
  get (options, adapter) {
    this.__optionsGuard(options)

    return this.request({ ...options, type: this.HTTP_METHODS.GET }, adapter)
  }

  /**
   * @param {JQuery.AjaxSettings | Object} options
   * @param {Function} [adapter]
   * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
   */
  create (options, adapter) {
    this.__optionsGuard(options)

    return this.request({ ...options, type: this.HTTP_METHODS.POST }, adapter)
  }

  /**
   * @param {JQuery.AjaxSettings | Object} options
   * @param {Function} [adapter]
   * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
   */
  update (options, adapter) {
    this.__optionsGuard(options)

    return this.request({ ...options, type: this.HTTP_METHODS.PATCH }, adapter)
  }

  /**
   * @param {JQuery.AjaxSettings | Object} options
   * @param {Function} [adapter]
   * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
   */
  destroy (options, adapter) {
    this.__optionsGuard(options)

    return this.request({ ...options, type: this.HTTP_METHODS.DELETE }, adapter)
  }

  /**
   * @param {JQuery.AjaxSettings | Object} options
   * @param {Function} [adapter]
   * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
   */
  restore (options, adapter) {
    this.__optionsGuard(options)

    return this.request({ ...options, type: this.HTTP_METHODS.PATCH }, adapter)
  }

  /**
   * @param {JQuery.AjaxSettings | Object} options
   * @param {Function} [adapter]
   * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
   */
  customRequest (options, adapter) {
    return this.request(options, adapter)
  }

  /**
   * @param {JQuery.AjaxSettings | Object} options
   * @return {{promise: Promise<any>, ajax: JQuery.jqXHR}}
   */
  post (options) {
    this.__optionsGuard(options)
    const __options = {
      ...options,
      type: HTTP_METHODS.POST,
    }

    return this.request(__options)
  }
}
