import { DateHelpers } from '@/helpers/DateHelpers'
import { DAYS_DIFFS } from '@/_global_scripts/GLOBAL_CONSTS'

export const CALENDAR_RANGE_NAME = Object.freeze({
  YESTERDAY: 'yesterday',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  SEVEN_DAYS: 'seven_day',
  LAST_SEVEN_DAYS: 'last_seven_days',
  THIRTY_DAYS: 'thirty_days',
  LAST_THIRTY_DAYS: 'last_thirty_days',
  NINETY_DAYS: 'ninety_days',
  LAST_NINETY_DAYS: 'last_ninety_days',
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month',
  THIS_YEAR: 'this_year',
  LAST_YEAR: 'last_year',
})

export const CALENDAR_RANGE = Object.freeze({
  [CALENDAR_RANGE_NAME.TODAY]: {
    text: t('date_rage_picker.today'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().startOf('day').toDate(), moment().endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.YESTERDAY]: {
    text: t('date_rage_picker.yesterday'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.TOMORROW]: {
    text: t('date_rage_picker.tomorrow'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().add(1, 'days').startOf('day').toDate(), moment().add(1, 'days').endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.LAST_SEVEN_DAYS]: {
    text: t('date_rage_picker.seven_days'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().subtract(6, 'days').startOf('day').toDate(), moment().endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.SEVEN_DAYS]: {
    text: t('date_rage_picker.seven_days'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().startOf('day').toDate(), moment().add(6, 'days').endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.LAST_THIRTY_DAYS]: {
    text: t('date_rage_picker.thirty_days'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().subtract(29, 'days').startOf('day').toDate(), moment().endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.THIRTY_DAYS]: {
    text: t('date_rage_picker.thirty_days'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().startOf('day').toDate(), moment().add(29, 'days').endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.LAST_NINETY_DAYS]: {
    text: t('date_rage_picker.ninety_days'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().subtract(89, 'days').startOf('day').toDate(), moment().endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.NINETY_DAYS]: {
    text: t('date_rage_picker.ninety_days'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().startOf('day').toDate(), moment().add(89, 'days').endOf('day').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.THIS_MONTH]: {
    text: t('date_rage_picker.this_month'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.LAST_MONTH]: {
    text: t('date_rage_picker.last_month'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.THIS_YEAR]: {
    text: t('date_rage_picker.this_year'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().startOf('year').toDate(), moment().endOf('year').toDate()]
      )
    },
  },
  [CALENDAR_RANGE_NAME.LAST_YEAR]: {
    text: t('date_rage_picker.last_year'),
    onClick (picker) {
      picker.$emit(
        'pick',
        [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()]
      )
    },
  },
})

export const DEFAULT_CALENDAR_RANGE_NAMES = [
  CALENDAR_RANGE_NAME.TODAY,
  CALENDAR_RANGE_NAME.YESTERDAY,
  CALENDAR_RANGE_NAME.LAST_SEVEN_DAYS,
  CALENDAR_RANGE_NAME.LAST_THIRTY_DAYS,
  CALENDAR_RANGE_NAME.LAST_NINETY_DAYS,
  CALENDAR_RANGE_NAME.THIS_MONTH,
  CALENDAR_RANGE_NAME.LAST_MONTH,
  CALENDAR_RANGE_NAME.THIS_YEAR,
  CALENDAR_RANGE_NAME.LAST_YEAR,
]

export const DEFAULT_CALENDAR_RANGES = DEFAULT_CALENDAR_RANGE_NAMES.map(
  (calendarRange) => CALENDAR_RANGE[calendarRange]
)

/**
 * Набор функций, проверяющих соответствие переданного периода одному из
 * ключевых периодов.
 *
 * Для каждой функции справедливо:
 * @param {TDateLikePeriod} period
 * @returns {boolean}
 */

export const checkPeriodIsToday = (period) => {
  const today = moment()

  return DateHelpers.areSamePeriods(period, [today, today])
}

export const checkPeriodIsYesterday = (period) => {
  const yesterday = moment().subtract(1, 'days')

  return DateHelpers.areSamePeriods(period, [yesterday, yesterday])
}

export const checkPeriodIsCurrentMonth = (period) => {
  const firstDayOfThisMonth = moment().startOf('month')
  const lastDayOfThisMonth = moment().endOf('month')

  return DateHelpers.areSamePeriods(period, [firstDayOfThisMonth, lastDayOfThisMonth])
}

export const checkPeriodIsPreviousMonth = (period) => {
  const firstDayOfPrevMonth = moment().startOf('month').subtract(1, 'month')
  const lastDayOfPrevMonth = moment().endOf('month').subtract(1, 'month')

  return DateHelpers.areSamePeriods(period, [firstDayOfPrevMonth, lastDayOfPrevMonth])
}

export const checkPeriodIsCurrentYear = (period) => {
  const firstDayOfThisYear = moment().startOf('year')
  const lastDayOfThisYear = moment().endOf('year')

  return DateHelpers.areSamePeriods(period, [firstDayOfThisYear, lastDayOfThisYear])
}

export const checkPeriodIsPreviousYear = (period) => {
  const firstDayOfPrevYear = moment().startOf('year').subtract(1, 'year')
  const lastDayOfPrevYear = moment().endOf('year').subtract(1, 'year')

  return DateHelpers.areSamePeriods(period, [firstDayOfPrevYear, lastDayOfPrevYear])
}

export const checkPeriodIsWeek = ([start, end]) => {
  return moment(end).diff(moment(start), 'days') === DAYS_DIFFS.WEEK
}

export const checkPeriodIsThirtyDays = ([start, end]) => {
  return moment(end).diff(moment(start), 'days') === DAYS_DIFFS.THIRTY_DAYS
}

export const checkPeriodIsNinetyDays = ([start, end]) => {
  return moment(end).diff(moment(start), 'days') === DAYS_DIFFS.NINETY_DAYS
}

/* --- */

export const PERIOD_CHECKER_BY_CALENDAR_RANGE_NAME = {
  [CALENDAR_RANGE_NAME.TODAY]: checkPeriodIsToday,
  [CALENDAR_RANGE_NAME.YESTERDAY]: checkPeriodIsYesterday,
  [CALENDAR_RANGE_NAME.THIS_MONTH]: checkPeriodIsCurrentMonth,
  [CALENDAR_RANGE_NAME.LAST_MONTH]: checkPeriodIsCurrentMonth,
  [CALENDAR_RANGE_NAME.THIS_YEAR]: checkPeriodIsCurrentYear,
  [CALENDAR_RANGE_NAME.LAST_YEAR]: checkPeriodIsPreviousYear,
  [CALENDAR_RANGE_NAME.SEVEN_DAYS]: checkPeriodIsWeek,
  [CALENDAR_RANGE_NAME.THIRTY_DAYS]: checkPeriodIsThirtyDays,
  [CALENDAR_RANGE_NAME.NINETY_DAYS]: checkPeriodIsNinetyDays,
}

/**
 * Определяет, соответствует ли переданный период одному из ключевых периодов.
 * Если да - возвращает уникальное название этого периода, если нет - возвращает
 * пустую строку
 * @param {TDateLikePeriod} period
 * @returns {string}
 */
export const periodToCalendarRangeName = (period) => {
  const calendarRangeName = Object
    .keys(PERIOD_CHECKER_BY_CALENDAR_RANGE_NAME)
    .find((rangeName) => PERIOD_CHECKER_BY_CALENDAR_RANGE_NAME[rangeName](period))

  return calendarRangeName || ''
}

const PERIOD_GETTER_BY_CALENDAR_RANGE_NAME = {
  [CALENDAR_RANGE_NAME.TODAY]: DateHelpers.getTodayPeriod,
  [CALENDAR_RANGE_NAME.YESTERDAY]: DateHelpers.getYesterdayPeriod,
  [CALENDAR_RANGE_NAME.SEVEN_DAYS]: DateHelpers.getWeekPeriod,
  [CALENDAR_RANGE_NAME.THIRTY_DAYS]: DateHelpers.getThirtyDaysPeriod,
  [CALENDAR_RANGE_NAME.NINETY_DAYS]: DateHelpers.getNinetyDaysPeriod,
  [CALENDAR_RANGE_NAME.THIS_MONTH]: DateHelpers.getCurrentMonthPeriod,
  [CALENDAR_RANGE_NAME.LAST_MONTH]: DateHelpers.getPreviousMonthPeriod,
  [CALENDAR_RANGE_NAME.THIS_YEAR]: DateHelpers.getCurrentYearPeriod,
  [CALENDAR_RANGE_NAME.LAST_YEAR]: DateHelpers.getPreviousYearPeriod,
}

/**
 * Переводит название ключевого периода в период
 * @param {string} calendarRangeName
 * @returns {TDatePeriod}
 */
export const calendarRangeNameToPeriod = (calendarRangeName) =>
  PERIOD_GETTER_BY_CALENDAR_RANGE_NAME[calendarRangeName]()

/**
 * Проверяет, является ли переданное значение уникальным названием
 * одного из ключевых периодов
 * @param {*} value
 * @returns {boolean}
 */
export const isCalendarRangeName = (value) =>
  Object
    .values(CALENDAR_RANGE_NAME)
    .some((rangeName) => rangeName === value)
