/**
 * @typedef {{location: string, message: string, entity: string}} TMRequestNotification
 */
export class MRequestNotification {
  /**
   * @param {String} location
   * @param {String} message
   * @param {String} entity
   * @return {TMRequestNotification}
   */
  constructor (location, message, entity) {
    return { location, message, entity }
  }
}
