import { isEmpty } from 'lodash'

/**
 * Вставка строки в строку
 * @param {String} str
 * @param {String} insertedStr
 * @param {Number} position
 * @return {String}
 */
export const insertToString = (str, insertedStr, position) => {
  return str.slice(0, position) + insertedStr + str.slice(position)
}

/**
 * Value или undefined
 *
 * @warning: Рекомендуется использовать только при фильтрации, остальное на свой страх и риск!
 *
 * @param {*} value
 * @param {boolean} deep - true - проверка пустоты объектов
 * @return {undefined|*}
 */
export const orUndefined = (value, deep = false) => {
  if (!deep) { return value || undefined }

  return isEmpty(value)
    ? undefined
    : value
}

/**
 * [Value] или undefined
 *
 * @warning: Рекомендуется использовать только при фильтрации, остальное на свой страх и риск!
 *
 * @param {*} value
 * @param {boolean} deep - true - проверка пустоты объектов
 * @returns {undefined|[*]}
 */
export const inArrayOrUndefined = (value, deep = false) => {
  return orUndefined(value, deep) && [value]
}

/**
 * Value или null
 *
 * @warning: Рекомендуется использовать только при фильтрации, остальное на свой страх и риск!
 *
 * @param {*} value
 * @param {boolean} deep - true - проверка пустоты объектов
 * @return {null|*}
 */
export const orNull = (value, deep = false) => {
  if (!deep) { return value || null }

  return isEmpty(value)
    ? null
    : value
}

/**
 *
 * @param {{ id: string | number }}
 * @return { string | number }
 */
export const extractId = ({ id }) => id

/**
 * @typedef {{ id: number | string, title: string }} ICatalog
 */

/**
 * @param {Record<any, any> & ICatalog}
 * @return {ICatalog}
 */
export const extractCatalog = ({ id, title }) => ({ id, title })

/**
 * Комбо pubSub + Promise
 * Отправляется событие ${eventName}:emit с данными из data
 * Ожидается событие ${eventName}:resolve с данными из вне
 * В случае, если не удалось получить данные, то возвращается defaultData
 *
 * @param {string} eventName
 * @param {unknown} [data]
 * @param {unknown} [defaultData]
 * @return {Promise<unknown>}
 */
export const pubSubPromise = (eventName, data, defaultData) => {
  const logError = (...args) => console.error(...args)

  let timeout = null
  let success = false

  return new Promise((resolve) => {
    Services.pubSub.subscribe(`${eventName}:resolve`, (...args) => {
      timeout = clearTimeout(timeout) || null
      Services.pubSub.unsubscribeAll(`${eventName}:resolve`)
      success = true

      return resolve(...args)
    })

    Services.pubSub.emit(`${eventName}:emit`, data)

    timeout = setTimeout(() => {
      !success && logError('Не удалось выполнить pubSubPromise:', eventName)
      timeout = clearTimeout(timeout) || null
      success = false
      Services.pubSub.unsubscribeAll(`${eventName}:resolve`)

      return resolve(defaultData)
    }, 1000)
  })
}
