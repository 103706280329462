import { isEmpty } from 'lodash'

class MLocalStorageAbstract {
  // eslint-disable-next-line no-useless-constructor
  constructor (key) {}

  save (data) {}

  restore () {}

  drop () {}

  get () {}
}

export class MLocalStorage {
  constructor (key) {
    if (!key) {
      return new MLocalStorageAbstract()
    }

    this.key = key
  }

  save (data) {
    if (!this.key) { return }
    try {
      localStorage.setItem(this.key, JSON.stringify(data))
    } catch (e) {
      console.error(e)
    }
  }

  restore () {
    if (!this.key) { return {} }

    try {
      const data = JSON.parse(localStorage.getItem(this.key))

      if (!data) { return null }
      if (typeof data === 'number' || !isEmpty(data)) { return data }

      return data
    } catch (e) { return null }
  }

  drop (key = this.key) {
    localStorage.removeItem(key)
  }

  get (key = this.key) {
    return localStorage.getItem(key)
  }
}
