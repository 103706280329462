import { DateHelpers } from '@/helpers/DateHelpers'
import {
  MESSAGE_DELIVERY_STATUS_ICONS,
  MESSAGE_DELIVERY_TYPE,
} from '@/vue_apps/CommunicationModule/Messages/const/const'
import { omit } from 'lodash'

export const deliveryListAdapter = {
  toServer (data) {
    return {
      ...omit(data, ['__clientId', '__cacheKey']),
      period: DateHelpers.toBasePeriod(data.period),
      clientId: data.__clientId || undefined,
    }
  },

  /**
   * @return {TMessagesList}
   */
  toClient (response) {
    response.data = (response.data || []).map((item) => {
      return {
        ...item,
        dateTime: DateHelpers.getTodayTomorrowYesterday(item.createdAt, true),

        number: {
          isStruct: true,
          value: item.id?.split('-')[0],
          tooltip: item.id,
        },

        text: {
          isStruct: true,
          value: item.text,
          tooltip: item.text,
        },

        deliveryTypeIcon: {
          isIcon: true,
          value: item.deliveryType,
          tooltip: t(item.deliveryType, { scope: 'enums.delivery.type' }),
          color: item.deliveryType === MESSAGE_DELIVERY_TYPE.WHATSAPP ? 'success' : 'primary',
          useBrand: item.deliveryType === MESSAGE_DELIVERY_TYPE.WHATSAPP,
        },

        statusIcon: {
          isIcon: true,
          value: `${item.status}Message`,
          tooltip: t(item.status, { scope: 'enums.delivery.status' }),
          color: MESSAGE_DELIVERY_STATUS_ICONS[item.status]?.color,
        },

        messageType: t(item.messageType, { scope: 'enums.message.message_type' }),

        errorText: {
          isStruct: true,
          value: item.error ? t(item.error, { scope: 'enums.delivery.error_code' }) : '',
          tooltip: item.error ? t(item.error, { scope: 'enums.delivery.error_code' }) : '',
          css: 'danger',
        },
      }
    })

    return response
  },
}
