import { request } from '@/lib/transport/request'
import { NETRIKA_CATALOGS } from '@/api_entities/egisz/catalogs/const'
import { catalogAdapter } from '@/api_entities/egisz/catalogs/catalog_adapter'

export const visitTypeEndpoint = {
  name: 'visitTypeEndpoint',
  getAll () {
    const options = {
      type: 'get',
      url: Routes.egisz_netrika_catalogs_list_path({ title: NETRIKA_CATALOGS.CASE_VISIT_TYPES }),
    }

    return request(options, catalogAdapter.toClient).promise
  },
}
