import { debounce } from 'lodash'

const DEBOUNCE_TIME = 5000
let debounceUpdate = null

export const onWsEntryActions = (data) => {
  if (data?.client && parseInt(data.client?.id) !== parseInt(gon.page.client_id)) { return }

  if (!debounceUpdate) {
    debounceUpdate = debounce(() => Services.pubSub.emit('updateEntriesList_onWsEntryActions'), DEBOUNCE_TIME)
  }

  debounceUpdate()
}
