export const GLOBAL_SEARCH_MIN_LENGTH = 3
export const GLOBAL_DEBOUNCE_DEFAULT = 500

export const GLOBAL_DOUBLE_DEBOUNCE_DEFAULT = GLOBAL_DEBOUNCE_DEFAULT * 2

export const GLOBAL_MAX_USER_WAITING_MS = 350

export const GLOBAL_GET_DEFAULT_SEXES = () => [
  { id: 1, title: t('male'), systemName: 'male' },
  { id: 2, title: t('female'), systemName: 'female' },
]

export const GLOBAL_ONLINE_ACCESS = Object.freeze({
  ALLOWED: 'allowed',
  PROHIBITED: 'prohibited',
})

export const GLOBAL_CLIENT_GROUPS = Object.freeze({
  VIP: 'VIP',
  BLACK_LIST: 'black_list',
})

export const GLOBAL_ENTRY_TYPES_KINDS = Object.freeze({
  ANALYSIS: 1,
  SERVICE: 4,
  GOOD: 5,
  COMPLEX: 6,
})

export const GLOBAL_CURRENCY_TYPES = Object.freeze({
  PERCENT: 'percent',
  FIXED: 'fixed',
})

export const GLOBAL_CATEGORY_ROOT_ID = 0

export const GLOBAL_REFERRAL_KINDS = Object.freeze({
  INTERNAL: 'internal',
  EXTERNAL: 'external',
})

export const GLOBAL_DEFAULT_COLORS = Object.freeze({
  PRIMARY: 'primary',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
})

export const GLOBAL_DEFAULT_ADDRESS = Object.freeze({
  area: null,
  building: null,
  city: null,
  country: null,
  flat: null,
  house: null,
  housing: null,
  index: null,
  region: null,
  street: null,
})

export const GLOBAL_BRANCH_TYPE = Object.freeze({
  MED: 10,
  DENT: 20,
  CUSTOM: 30,
})

export const USER_GROUP_IDS = Object.freeze({
  DEVELOPERS: [1],
  DOCTORS: [2],
  STAFF: [3],
  DENTISTS: [4],
  MEDICAL_GROUP: [2, 4],
  CLINIC_PERSONNEL: [2, 3, 4],
})

export const GLOBAL_TIMES = Object.freeze({
  ONE_SECOND: 1000,
  ONE_MINUTE: 60_000,
  ONE_HOUR: 3_600_000,
  ONE_DAY: 86_400_000,
  ONE_WEEK: 604_800_000,
  ONE_YEAR: 31_536_000_000,
})

export const GLOBAL_CATEGORY_KINDS = Object.freeze({
  DRUG: 1,
  DISEASE: 2,
  FORM_COMPONENT: 6,
  ENTRY_TYPE: 7,
  TEMPLATE_RECORDS: 8,
  MESSAGES_TEMPLATE: 13,
  DOCUMENT_TYPES: 14,
  ENTRY_PACKS: 15,
})

export const GLOBAL_ENTRY_STATE = Object.freeze({
  NOT_READY: 1,
  READY: 2,
  NO_STATE: 3,
})

export const GLOBAL_MEDICAL_POLICY_KINDS = Object.freeze({
  OMS: 'oms',
  DMS: 'dms',
})

export const GLOBAL_MEDICAL_POLICY_KINDS_ENUM = Object.freeze({
  [GLOBAL_MEDICAL_POLICY_KINDS.OMS]: 1,
  [GLOBAL_MEDICAL_POLICY_KINDS.DMS]: 2,
})

export const GLOBAL_HOLDER_SCOPES = Object.freeze({
  PERSONAL: 'personal',
  ORGANIZATION: 'organization',
})

export const DAYS_DIFFS = Object.freeze({
  WEEK: 6,
  THIRTY_DAYS: 29,
  NINETY_DAYS: 89,
})

export const GLOBAL_ENTRY_TYPES_KINDS_LIST = Object.freeze([
  { id: GLOBAL_ENTRY_TYPES_KINDS.ANALYSIS, title: t('analysis') },
  { id: GLOBAL_ENTRY_TYPES_KINDS.SERVICE, title: t('entry_service') },
  { id: GLOBAL_ENTRY_TYPES_KINDS.COMPLEX, title: t('complex') },
  { id: GLOBAL_ENTRY_TYPES_KINDS.GOOD, title: t('entry_good') },
])
