import { flatObject } from '@/helpers/object_helpers'

export const getAllAdapter = {
  toClient (data) {
    data.data = data.data.map((item) => {
      const result = flatObject(item)

      result.type_id = item.type_id && item.type_id.toString()
      result.formatted_date = Utils.getFormattedDate(result.date, Utils.shortDateFormat)
      result.date = new Date(result.date)

      return result
    })

    return data
  },

  toServer (filters) {
    if (filters.date && filters.date.length) {
      filters.date = [
        Utils.getBaseFormattedDate(filters.date[0]),
        Utils.getBaseFormattedDate(filters.date[1]),
      ]
    }

    return filters
  },
}
