import { MListServiceItem } from '@/_api/_requests/MListServiceItem'
import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'
import { CALCULATED_ENTRY_STATUS } from '@/vue_apps/CheckupsModule/store/checkupCalculatedEntries'

export class CheckupCalculatedEntryCellBase extends MListServiceItem {
  action = null

  /**
   * @param {ICheckupCalculatedEntry} calculatedEntry
   */
  constructor (calculatedEntry) {
    super()

    this.id = calculatedEntry.id || Utils.newGUID()
    this.kind = calculatedEntry.checkupEntryType.kind

    this.checkupEntryType = calculatedEntry.checkupEntryType
    this.period = calculatedEntry.period
    this.status = calculatedEntry.status || CALCULATED_ENTRY_STATUS.ACTIVE
    this.unformattedRequirements = calculatedEntry.unformattedRequirements

    this.entry = calculatedEntry.entry

    const titleTemplate = this.period === 'P1Y' || !this.period
      ? calculatedEntry.checkupEntryType.title
      : `${calculatedEntry.checkupEntryType.title} (${t(this.period, { scope: 'enums.period' })})`
    this.title = new MTableCell(titleTemplate)
      .addCut()
      .addTooltip(titleTemplate)

    this.additionalInfo = calculatedEntry.unformattedRequirements?.length
      ? new MTableCell()
        .addTypeIcon('warning')
        .addTooltip(calculatedEntry.unformattedRequirements.join('; '))
        .addClasses('warning')
      : new MTableCell()
  }

  trigger () {}
}
