import { getItemAttributeFromArrById } from '@/vue_components/store/composables/get_item_attribute_from_array_by_id'
import { DEFAULT_FIRST } from '@/vue_components/egisz/egisz_module/const/set_first_const'

export const getters = {
  isMedCasesFiltered (state) {
    const f = state.filters

    return Boolean(
      (f.period && f.period.length === 2) ||
      f.nsi_specialty_id ||
      f.doctor_id ||
      f.offset
    )
  },

  forCurrentUser (state) {
    return state.filtersBase.filters.doctor_id === gon.application.current_user.id
  },

  GET_CURRENT_PAGE: (state) => state.page,
  GET_PER_PAGE: (state) => state.filters.limit,
  GET_TOTAL_PAGES: (state) => state.total_pages,

  GET_SPECIALTIES: (state) => state.catalogs.specialties,

  GET_CASE_CATALOGS_LOADED: (state) => state.caseCatalogs.isLoaded,

  GET_RELATIVE_TYPES: (state) => state.caseCatalogs.relativeTypes,
  GET_RELATIVE_TYPE: (state) => (id, attribute = 'title', emptyResult = '-') =>
    getItemAttributeFromArrById(state.caseCatalogs.relativeTypes, id, attribute, emptyResult),

  GET_PAYMENT_TYPES: (state) => state.caseCatalogs.paymentTypes,

  GET_VISIT_TYPES: (state) => state.caseCatalogs.visitTypes,

  GET_CASE_TYPES: (state) => state.caseCatalogs.caseTypes,

  GET_CONFIDENTIALITY_LEVELS: (state) => state.caseCatalogs.confidentialityLevels,

  GET_DISEASE_RESULTS: (state) => state.caseCatalogs.diseaseResults,

  GET_STEP_TYPES: (state) => state.caseCatalogs.stepTypes,
  GET_STEP_TYPE: (state) => (id, attribute = 'title') =>
    getItemAttributeFromArrById(state.caseCatalogs.stepTypes, id, attribute),

  GET_STEP_PLACES: (state) => state.caseCatalogs.stepPlaces,
  GET_STEP_PLACE: (state) => (id, attribute = 'title') =>
    getItemAttributeFromArrById(state.caseCatalogs.stepPlaces, id, attribute),

  GET_STEP_PURPOSES: (state) => state.caseCatalogs.stepPurposes,
  GET_STEP_PURPOSE: (state) => (id, attribute = 'title') =>
    getItemAttributeFromArrById(state.caseCatalogs.stepPurposes, id, attribute),

  GET_MED_RECORD_DIAGNOSIS_TYPES: (state) =>
    state.caseCatalogs.medRecordDiagnosisTypes
      .filter((item) => item.title.toLowerCase() === DEFAULT_FIRST.CASE_MAIN_DIAGNOSIS_TYPE.toLowerCase()),
}
