import { request } from '@/lib/transport/request'
import { NETRIKA_CATALOGS } from '@/api_entities/egisz/catalogs/const'
import { doctorTreeAdapter } from '@/api_entities/doctors/doctor_adapters'
import { HTTP_METHODS } from '@/lib/transport/MRequest'
import { usersAdapter } from '@/vue_components/doctor_schedules/adapters'

export const doctorEndpoint = {
  name: 'doctorEndpoint',
  getAll (sortParams) {
    const options = {
      type: 'post',
      url: Routes.doctors_list_users_path(),
      data: JSON.stringify({
        sort_params: sortParams,
      }),
      contentType: 'application/json',
      dataType: 'json',
    }

    return request(options).promise
  },

  getSpecialties () {
    const options = {
      type: 'get',
      url: Routes.egisz_netrika_catalogs_list_path({ title: NETRIKA_CATALOGS.SPECIALITIES }),
    }

    return request(options, doctorTreeAdapter.toClient).promise
  },

  getPositions () {
    const options = {
      type: 'get',
      url: Routes.egisz_netrika_catalogs_list_path({ title: NETRIKA_CATALOGS.POSITIONS }),
    }

    return request(
      options,
      (data) => doctorTreeAdapter.toClient(data, true)
    ).promise
  },

  selectClinic (userId, data) {
    const options = {
      type: HTTP_METHODS.PATCH,
      url: Routes.user_path(userId),
      data,
    }

    return request(options).promise
  },

  fetchRoles (data, toClientAdapter = (response) => response) {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.by_user_id_roles_path(),
      data,
    }

    return request(options, toClientAdapter).promise
  },

  fetchAttendingDoctors (params) {
    const options = {
      type: HTTP_METHODS.GET,
      url: Routes.doctor_schedules_fetch_attending_doctors_path(),
      data: params,
    }

    return request(options, usersAdapter.toClient)
  },

  fetchUsers (params, toClientAdapter = (response) => response) {
    const data = typeof params === 'string' || params?.searchQuery
      ? { search_query: params || params?.searchQuery }
      : { sort_params: params }

    const options = {
      url: Routes.list_users_path(),
      method: HTTP_METHODS.POST,
      data,
    }

    return request(options, toClientAdapter)
  },
}
