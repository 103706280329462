import { orUndefined } from '@/_medods_standart_library/msl'

export const getCheckupEntries = (checkupEntries) => checkupEntries?.map((e) => ({
  id: typeof e.id === 'number' ? e.id : undefined,
  checkupsEntryTypeId: e.checkupEntryType?.id,
  period: e.period,
  status: e.status,
  unformattedRequirements: e.unformattedRequirements,
  _destroy: e?._destroy,
}))

export const personalCheckupCheckupEntriesAdapter = {
  toServer ({ personalCheckupId, checkupEntries }) {
    return {
      personalCheckup: {
        id: orUndefined(personalCheckupId),
        checkupsEntriesAttributes: getCheckupEntries(checkupEntries),
      },
    }
  },
}
