import BaseFormatter from '@/lib/formatters/BaseFormatter'
import formatter from '@/lib/formatters/formatter'

export default class ClientFormatter extends BaseFormatter {
  fullName (client) {
    if (!client) {
      return ''
    }

    return [
      client.surname,
      client.name,
      client.secondName || client.second_name,
    ]
      .filter(Boolean)
      .join(' ')
  }

  link (client) {
    if (!client) {
      return ''
    }

    return `/clients/${client.id}`
  }

  /**
   * @param {string} snils
   * @return {string}
   */
  snils (snils) {
    if (!snils) return snils

    const numbers = snils.split('')
    numbers.splice(3, 0, '-')
    numbers.splice(7, 0, '-')
    numbers.splice(11, 0, ' ')

    return numbers.join('')
  }

  address (client) {
    if (client && client.address) {
      const { address } = client
      const parts = [
        address.country,
        address.region,
        address.area,
        address.city,
        address.street ? `ул. ${address.street}` : '',
        address.house ? `д. ${address.house}` : '',
        address.building ? `стр. ${address.building}` : '',
        address.housing ? `корп. ${address.housing}` : '',
        address.flat ? `кв. ${address.flat}` : '',
      ]

      return parts.filter(Boolean).join(', ')
    }

    return ''
  }

  idCard (client) {
    if (client && client.idCard) {
      const { idCard } = client
      const parts = [
        idCard.title,
        idCard.title,
        idCard.series,
        idCard.number,
        idCard.whoIssue,
        idCard.issueDate ? formatter.formatValue(idCard.issueDate, ['date:localDate']) : '',
      ]

      return parts.filter(Boolean).join(', ')
    }

    return ''
  }

  /**
   * @param {string} phone
   * @return {string}
   */
  phone (phone) {
    if (!phone.length) { return '' }
    if (phone.startsWith('+')) { return phone }

    return `+${Services.phoneMask.setMask(phone)}`
  }

  shortInfo (client) {
    const fullName = client.fullName || client.full_name || this.fullName(client)
    const birthdate = client.birthDate || client.birthdate

    return [
      fullName,
      birthdate && Utils.getFormattedDate(birthdate),
      this.phone(client.phone),
    ]
      .filter(Boolean)
      .join(', ')
  }
}

ClientFormatter.namespace = 'client'
