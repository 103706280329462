import { doctorEndpoint } from '@/api_entities/doctors/doctor_endpoint'
import { getItemAttributeFromArrById } from '@/vue_components/store/composables/get_item_attribute_from_array_by_id'
import { clinicPresenter } from '@/api_entities/clinics/clinicPresenter'
import { snakeToCamel } from '@/_api/_requests/helpers'

const MAX_LIMIT = 50

export const doctorStore = {
  namespaced: true,
  state: () => ({
    doctors: [],
    doctorsIsLoaded: false,
    offset: 0,

    doctorClinics: (gon.application.current_user_clinics || [])
      .filter((clinic) => clinic.status === 'active'),

    doctorClinicsIsLoaded: false,

    currentDoctor: snakeToCamel(gon.application.current_user),
  }),

  getters: {
    getDoctorsIsLoaded: (state) => state.doctorsIsLoaded,

    getDoctorsInCurrentClinic: (state, getters, rootState, rootGetters) => state.doctors.filter(
      (doctor) => (doctor.clinic_ids || []).includes(rootGetters.GET_APP_CONF_CURRENT_CLINIC_ID)
    ),

    getDoctors: (state) => state.doctors || [],

    getDoctor: (state) => (id, attribute = 'full_name') =>
      getItemAttributeFromArrById(state.doctors, id, attribute),

    getDoctorShortName: (state, getters) => (id, attribute = 'short_name') =>
      getters.getDoctor(id, attribute),

    vxGetDoctorClinics: (state) => state.doctorClinics,

    vxGetDoctorClinicsEgiszReady: (state) => state.doctorClinics.filter((item) => item.lpu_id),

    vxGetDoctorClinicsIds: (state) => state.doctorClinics.map((clinic) => clinic.id),

    vxGetCurrentDoctor: (state) => state.currentDoctor,
  },

  mutations: {
    addDoctors (state, doctors) {
      state.doctors = [...state.doctors, ...doctors]
    },

    setDoctorsIsLoaded (state) {
      state.doctorsIsLoaded = true
    },

    vxSetClinics (state, clinics) {
      state.doctorClinics = clinics
      state.doctorClinicsIsLoaded = true
    },
  },

  actions: {
    loadDoctors ({ commit, rootGetters, dispatch, state }, offset = 0) {
      if (state.doctorsIsLoaded) { return }

      return doctorEndpoint.getAll({ offset, limit: MAX_LIMIT })
        .then(({ data, total_items: totalItems }) => {
          if (!data) { return }

          commit('addDoctors', data)

          const nextOffset = offset + MAX_LIMIT
          totalItems && totalItems > nextOffset
            ? dispatch('loadDoctors', nextOffset)
            : commit('setDoctorsIsLoaded')
        })
        .catch(Utils.reportError(
          'store:doctorStore:loadDoctors()',
          t('utils_report_error.catalogs_message', { entity: t('utils_report_error.entities.doctors') }))
        )
    },

    vxLoadClinics ({ commit, state }, userId) {
      if (state.doctorClinicsIsLoaded) { return }
      if (!userId) { throw new Error('userId is required') }

      return clinicPresenter.fetchActiveClinicsByUserId(userId)
        .then((data) => { commit('vxSetClinics', data) })
    },
  },
}
