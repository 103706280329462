import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MSmsTemplateAdapter } from '@/_api/Deliveries/MSmsTemplateAdapter'
import { camelToSnake } from '@/_api/_requests/helpers'

export class MSmsConfigurationsPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: { one () {}, all () {} },
      entity: 'configurationsList',
      location: 'MSmsConfigurations',
    })
  }

  fetchSmsConfigurations () {
    const options = {
      url: Routes.messages_sms_configuration_path(),
    }

    const notification = new MRequestNotification(
      'MSmsConfigurationsPresenter:fetchSmsConfigurations',
      'fetchMessage',
      'configurationsList'
    )

    return this.get(options).promise
      .then(MRequestSuccess.onResponse(MSmsTemplateAdapter.toClient))
      .catch(MRequestError.withDefault('', notification))
  }

  updateSmsConfigurations (configurations) {
    const options = {
      url: Routes.messages_sms_configuration_path(),
      data: camelToSnake(configurations),
    }

    const notification = new MRequestNotification(
      'MSmsConfigurationsPresenter:updateSmsConfigurations',
      'updateMessage',
      'configurationsList'
    )

    return this.patch(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault('', notification))
  }
}
