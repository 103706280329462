export const MIN_DURATION = 5
export const MAX_DURATION = 720
export const EMPTY_VALUE_TEXT = '-'
export const APPOINTMENT_STATUS = Object.freeze({
  NEED_APPROVAL: 'need_approval',
  APPROVED: 'approved',
  ARRIVED: 'arrived',
  SERVICED: 'serviced',
  BILLED: 'billed',
  PAID: 'paid',
  CANCELED: 'canceled',
  FAILED: 'failed',
  CONFIRMED_BY_ADMINISTRATOR: 'confirmed_by_administrator',
})
