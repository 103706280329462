import { orNull } from '@/_medods_standart_library/msl'

export const getLegalsFormatted = (legalsList) => {
  return legalsList.map((legal) => ({
    legal_representative_id: legal.id,
    relation_type_id: legal.relation_type_id,
    relation_type_title: legal.relation_type_title,
  }))
}

export const clientAdapter = {
  toServer (formData) {
    const useNSIIdCardTypesDictionary = gon.application.nsi.idCardTypesDictionary
    const idCardId = {
      nsi_document_id: (useNSIIdCardTypesDictionary && (formData.documentType || '')) || undefined,
      id_card_title_id: (!useNSIIdCardTypesDictionary && (formData.documentType || '')) || undefined,
    }

    return {
      additional: formData.additional,
      birthdate: formData.birthdate,
      client_group_ids: formData.groups.length
        ? formData.groups
        : [''],
      company_id: formData.company && formData.company.id,
      deny_sms_notifications: formData.noSms,
      deny_whatsapp_messages: formData.noWhatsApp,
      deny_sms_dispatches: formData.noSmsDispatches,
      deny_calls: formData.noCalls,
      deny_email: formData.noEmail,
      department: formData.department,
      discount: formData.personalDiscount,
      email: formData.email,
      inn: formData.inn,
      legal_representatives: JSON.stringify(getLegalsFormatted(formData.legalRepresentatives)),
      name: formData.name,
      oms_number: formData.oms,
      online_access: formData.onlineAccess,
      // при вставке демо клиента телефон в форме значение получается с форматированием
      // возможно это будет случаться и в других условиях,
      // поэтому перед отправкой удаляем всё лишнее
      phone: Services.phoneMask.removeMask(formData.phone),
      position: formData.position,
      nsi_profession_id: orNull(formData.nsiProfession?.id),
      second_name: formData.secondName,
      service_card: formData.serviceCard,
      sex_id: formData.sex,
      snils: formData.snils,
      send_data_to_egisz: formData.sendDataToEgisz,
      surname: formData.surname,
      doctor_id: formData.treatingDoctor && formData.treatingDoctor.id,
      patient_card_number: gon.application.changeable_medcard_numbers ? formData.patientCardNumber : undefined,

      address: {
        index: formData.index,
        country: formData.country,
        region: formData.region,
        area: formData.area,
        city: formData.city,
        street: formData.street,
        house: formData.house,
        flat: formData.flat,
        nsi_russian_subject_id: formData.nsiRussianSubjectId,
      },

      id_card: {
        ...idCardId,
        series: formData.documentSeries || '',
        number: formData.documentNumber || '',
        who_issue: formData.whoIssue || '',
        issue_date: formData.issueDate || '',
      },
    }
  },

  toClient (response) {
    const useNSIIdCardTypesDictionary = gon.application.nsi.idCardTypesDictionary
    const documentType = useNSIIdCardTypesDictionary
      ? response.id_card.nsi_document_id
      : response.id_card.id_card_title_id

    const clientAddressData = response.address
      ? {
          index: response.address.index,
          country: response.address.country,
          region: response.address.region,
          area: response.address.area,
          city: response.address.city,
          street: response.address.street,
          house: response.address.house,
          flat: response.address.flat,
          documentType,
          documentSeries: response.id_card.series,
          documentNumber: response.id_card.number,
          whoIssue: response.id_card.who_issue,
          issueDate: response.id_card.issue_date,
        }
      : {}

    return {
      additional: response.additional,
      birthdate: response.birthdate,
      company: response.company,
      department: response.department,
      email: response.email,
      groups: (response.client_groups || []).map((group) => group.id),
      legalRepresentatives: response.legal_representatives.map((item) => {
        return {
          ...item,
          label: item.short_info,
        }
      }),
      inn: response.inn,
      oms: response.oms_number,
      onlineAccess: response.online_access,
      name: response.name,
      advDistribution: response.adv_distribution,
      noSms: response.deny_sms_notifications,
      noWhatsApp: response.deny_whatsapp_messages,
      noSmsDispatches: response.deny_sms_dispatches,
      noCalls: response.deny_calls,
      noEmail: response.deny_email,
      personalDiscount: parseFloat(response.discount),
      phone: response.phone,
      position: response.position,
      nsiProfession: {
        id: response.nsi_profession_id,
      },
      secondName: response.second_name,
      serviceCard: response.service_card,
      sex: response.sex_id,
      snils: response.snils,
      sendDataToEgisz: response.send_data_to_egisz,
      surname: response.surname,
      fullName: response.fullname,
      treatingDoctor: response.treating_doctor_object,
      ...clientAddressData,
    }
  },
}

export const clientSummaryAdapter = {
  toClient (response) {
    return {
      clientId: response.client_id,
      medicalRecordId: response.medical_record_id,
      paidSum: parseFloat(response.paid_sum),
      creditDebt: parseFloat(response.credit_debt),
      lastVisit: response.last_visit,
      previousAppointmentFailed: response.previous_appointment_failed,
      lastVisitToTheSameDoctorDaysAgo: response.last_visit_to_the_same_doctor_days_ago,
      lastVisitToTheSameDoctor: response.last_visit_to_the_same_doctor,
      advDistribution: response.adv_distribution,
      clientGroups: response.client_groups,
      clientGroupBlackList: response.client_group_black_list,
      clientGroupVip: response.client_group_vip,
      serviceCard: response.service_card,
      attractionSourceId: response.attraction_source_id,
      activeMedicalPoliciesTitles: response.active_medical_policies_titles || '',
    }
  },
}

export const clientSearchSelect2 = {
  toClient ({ results }) {
    return results.map((option) => {
      const [surname, name, secondName] = option.text.split(',')[0].split(' ')

      return {
        id: option.id,
        label: option.text,
        surname,
        name,
        second_name: secondName,
        relation_type_id: null,
        relation_type_title: '',
      }
    })
  },
}
