
export const getCorrectDoctorScheduleClinicId = (currentClinicId) => {
  if (!currentClinicId || !gon.application.current_user_clinics.length) {
    return gon.application.current_clinic.id
  }

  return gon.application.current_user_clinics.find(({ id }) => id === currentClinicId)
    ? currentClinicId
    : gon.application.current_user_clinics[0]?.id
}
