import { MLocalStorage } from '@/_api/_storage/MLocalStorage'

export class DoctorClinicsCache extends MLocalStorage {
  constructor (userId, timeout = 3_600_000) {
    super(`DoctorClinicsCache:${userId}`)
    this.timeout = timeout
  }

  save (data) {
    const cacheData = {
      exp: new Date().getTime() + this.timeout,
      data,
    }

    super.save(cacheData)
  }

  restore () {
    const restoredData = super.restore()

    if (!restoredData) { return undefined }
    if (typeof restoredData !== 'object') { return null }

    try {
      const { exp, data } = restoredData

      if (!exp) { return null }
      if (exp <= new Date().getTime()) { return null }
      if (!Array.isArray(data)) { return null }
      if (!data.every(
        (item) => typeof item === 'object' && item.id && item.title)
      ) { return null }

      return data
    } catch (e) {
      return null
    }
  }
}
