import { orUndefined } from '@/_medods_standart_library/msl'

export const getVaccinations = (vaccinations) => vaccinations.map((v) => ({
  id: orUndefined(v.dbId),
  date: v.date,
  number: v.number,
  mibpDiseasesGroupId: v.mibpDiseasesGroupId,
  _destroy: orUndefined(v._destroy),
}))

export const personalCheckupVaccinationAdapter = {
  toServer ({ ownerId, vaccinations }) {
    return {
      personalCheckup: {
        id: ownerId,
        vaccinationsAttributes: getVaccinations(vaccinations),
      },
    }
  },
}
