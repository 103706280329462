import { camelToSnake } from '@/_api/_requests/helpers'

export const smsCategoriesAdapter = {
  toClient ({ catalogs, items }) {
    return [
      ...catalogs,
      ...items.map((item) => {
        item.leaf = true

        return item
      }),
    ]
  },

  toServer (data) {
    return camelToSnake({ smsTemplate: { ...data } })
  },
}
