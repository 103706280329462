import { filtersBase } from '@/vue_components/store/modules/filters_base'
import {
  getAppointmentStatuses,
  getCallConfirmationStatuses,
} from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/filters'
import { MSpecialtyPresenter } from '@/_api/MSpecialty/MSpecialtyPresenter'
import { fetchAppointmentUsers } from '@/vue_components/doctor_schedules/rest'
import { MAppointmentPresenter } from '@/_api/MAppointment/MAppointmentPresenter'
import { CALL_CONFIRMATION_STATUS } from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/const'
import { APPOINTMENT_STATUS } from '@/vue_components/appointment/const'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { inArrayOrUndefined } from '@/_medods_standart_library/msl'

export const appointmentCallLogStore = {
  namespaced: true,
  modules: { filtersBase },

  state: () => ({
    doctors: [],
    specialties: [],
    statuses: getAppointmentStatuses(),
    callStatuses: getCallConfirmationStatuses(),

    /** @type {callLogAppointment[]} */
    appointments: [],
  }),

  getters: {
    vxGetDoctors: (state) => state.doctors,
    vxGetSpecialties: (state) => state.specialties,
    vxGetStatuses: (state) => state.statuses,
    vxGetCallStatuses: (state) => state.callStatuses,

    /**
     * @param state
     * @return {callLogAppointment[]}
     */
    vxGetAppointments: (state) => state.appointments,
  },

  mutations: {
    vxSetCallLogDoctors (state, value = []) {
      state.doctors = value
    },

    vxSetCallLogSpecialties (state, value = []) {
      state.specialties = value.map((item) => ({ id: item[0], title: item[1] }))
    },

    /**
     * @param state
     * @param {callLogAppointment[]} value
     */
    vxSetAppointments (state, value = []) {
      state.appointments = value
    },

    /**
     * @param state
     * @param {number} appointmentId
     * @param {TCallLogStatus} callConfirmationStatus
     */
    vxSetCallConfirmationStatus (state, { appointmentId, callConfirmationStatus }) {
      const appointment = state.appointments.find(({ id }) => id === appointmentId)
      if (!appointment) { return }

      appointment.callConfirmationStatus = callConfirmationStatus.id
    },

    vxSetStatus (state, { appointmentId, status }) {
      const appointment = state.appointments.find(({ id }) => id === appointmentId)
      if (!appointment) { return }

      appointment.status = status
    },

    /**
     * @param state
     * @param {{ id: number, note: string }} payload
     */
    vxSetAppointmentNote (state, payload) {
      const appointment = state.appointments.find(({ id }) => id === payload.id)
      if (!appointment) { return }
      appointment.note = payload.note
    },

    /**
     * Обновление аппоинтмента от внешних компонентов
     * @param state
     * @param updatedAppointment
     */
    vxSetAppointmentDataByAppointmentId (state, updatedAppointment) {
      const appointment = state.appointments.find(({ id }) => id === updatedAppointment.id)
      if (!appointment) { return }

      const camelClient = snakeToCamel(updatedAppointment.client || {})
      appointment.client = {
        ...appointment.client,
        surname: camelClient.surname,
        name: camelClient.name,
        secondName: camelClient.secondName,
        maskedPhone: camelClient.maskedPhone,
        formattedPhone: camelClient.formattedPhone,
      }

      appointment.entryTypes = snakeToCamel(updatedAppointment.entry_types)
      appointment.status = updatedAppointment.status
      appointment.note = updatedAppointment.note
    },
  },

  actions: {
    async vxFetchCallLogDoctors ({ commit }, payload) {
      try {
        commit(
          'vxSetCallLogDoctors',
          await fetchAppointmentUsers({ clinic_ids: inArrayOrUndefined(payload) }, snakeToCamel)
        )
      } catch (err) {
        commit('vxSetCallLogDoctors', [])
      }
    },

    async vxFetchCallLogSpecialties ({ commit }, payload) {
      commit(
        'vxSetCallLogSpecialties',
        await new MSpecialtyPresenter().timeFinderList(payload)
      )
    },

    async vxFetchAppointments ({ commit }, payload) {
      const { errors, data, totalPages } =
        await new MAppointmentPresenter().fetchAppointmentsWithFilters(payload)

      if (errors) { return }

      commit('vxSetAppointments', data)
      commit('filtersBase/vxSetTotalPages', totalPages)
    },

    /**
     * @param commit
     * @param dispatch
     * @param {{appointmentId: number, callConfirmationStatus: TCallLogStatus }} payload
     * @return {Promise<void>}
     */
    async vxUpdateCallConfirmationStatus ({ commit, dispatch }, payload) {
      const { errors } =
        await new MAppointmentPresenter().updateCallConfirmationStatus(payload)

      if (errors) { return }

      await dispatch('__vxUpdateStatusByCallConfirmationStatus', payload)
      commit('vxSetCallConfirmationStatus', payload)
    },

    /**
     * Реализация БЛ:
     * 1) если клиент подтвердил запись, статус --> "Подтверждён"
     * 2) если клиент отменил запись, статус --> "Отменён"
     * @param commit
     * @param {{appointmentId: number, callConfirmationStatus: TCallLogStatus }} payload
     * @return {Promise<void>}
     */
    async __vxUpdateStatusByCallConfirmationStatus ({ commit }, payload) {
      if (!(
        payload.callConfirmationStatus.id === CALL_CONFIRMATION_STATUS.CONFIRMED ||
        payload.callConfirmationStatus.id === CALL_CONFIRMATION_STATUS.CLIENT_CANCELED
      )) { return }

      const data = {
        appointmentId: payload.appointmentId,
        status: payload.callConfirmationStatus.id === CALL_CONFIRMATION_STATUS.CONFIRMED
          ? APPOINTMENT_STATUS.APPROVED
          : APPOINTMENT_STATUS.CANCELED,
      }

      const { errors } = await new MAppointmentPresenter().updateStatus(data)
      if (errors) { return }

      commit('vxSetStatus', data)
    },

    /**
     * @param commit
     * @param {{ id: number, note: string }} payload
     * @return {Promise<void>}
     */
    async vxUpdateAppointmentNote ({ commit }, payload) {
      const { errors } = await new MAppointmentPresenter().updateNote(payload)
      if (errors) { return }
      commit('vxSetAppointmentNote', payload)
    },
  },
}
