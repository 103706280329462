import { MTableCell } from '@/vue_present/_base/Tables/MTableGenerator/MTableCell'

export class MListServiceItem {
  id: number | null = null

  __selectable: boolean = true

  __deletable: boolean = true

  __selected: boolean = false

  __deleted: boolean = false

  __disabled: boolean = false

  __notDeletableReason: string | null = null

  getValues () {
    return {
      id: this.id,
      ...Object.keys(this).reduce((acc, key) => {
        acc[key] = this[key] instanceof MTableCell
          ? this[key].value
          : this[key]

        return acc
      }, {}),
    }
  }
}
