import { loadEmployessRating } from '@/vue_components/client_feedbacks/employees_rating/employees_rating_rest.js'

const state = {
  doctorsRating: [],
}

const getters = {
  GET_DOCTORS_RATING (state) {
    return state.doctorsRating
  },
}

const mutations = {
  SET_DOCTORS_RATING (state, doctorsRating) {
    state.doctorsRating = doctorsRating
  },
}

const actions = {
  LOAD_DOCTORS_RATING ({ commit }, params) {
    return loadEmployessRating(params)
      .then(
        (res) => {
          commit('SET_DOCTORS_RATING', res.doctors_rating)

          return res
        }
      )
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
