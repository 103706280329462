import { orUndefined } from '@/_medods_standart_library/msl'
import {
  PersonalCheckupResearchResultsItemListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupResearchResults/PersonalCheckupResearchResultsItemListItem'

export const getResearchResults = (researchResults: PersonalCheckupResearchResultsItemListItem[]) =>
  researchResults
    .map((rr) => ({
      id: orUndefined(rr.dbId),
      date: Utils.getBaseFormattedDate(rr.date, Utils.momentDateFormat),
      researchId: rr.research.id,
      medicalAssessmentId: rr.medicalAssessment.id,
      _destroy: orUndefined(rr._destroy),
    }))

export const personalCheckupInstrumentalResearchResultsAdapter = {
  toServer ({ personalCheckupId, instrumentalResearchResults }) {
    return {
      personalCheckup: {
        id: personalCheckupId,
        instrumentalResearchResultsAttributes: getResearchResults(instrumentalResearchResults),
      },
    }
  },
}
