/**
 * This file exports a formatter prepared to use in reports.
 * It has certain aliases that may be useful to shorten the code
 * in report adapters.
 */
import locator from './FormattersLocator'
import ValueFormatter from './ValueFormatter'
import OrderFormatter from './OrderFormatter'
import ClientFormatter from './ClientFormatter'
import UserFormatter from './UserFormatter'
import ObjectListFormatter from './ObjectListFormatter'
import AddressFormatter from './AddressFormatter'

locator.define(OrderFormatter)
locator.define(ClientFormatter)
locator.define(UserFormatter)
locator.define(ObjectListFormatter)
locator.define(AddressFormatter)

const formatter = new ValueFormatter()

// ALIASES

formatter.alias('currency', ['number:formatNumberLocal'])
formatter.alias('decimal', ['number:formatNumberLocal'])
formatter.alias('preserveCell', [['replaceEmpty', '-']])
formatter.alias('price', [['replaceNull', '-'], 'number:formatNumberLocal'])
formatter.alias('percent-no-sign', [['replaceNull', '-'], 'number:formatNumberLocal'])

export default formatter
