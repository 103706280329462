import {
  idCardTypesDictionaryStore,
} from '@/vue_components/store/modules/globalCatalogs/nsi/idCardTypesDictionary/idCardTypesDictionaryStore'
import {
  russianSubjectsDictionaryStore,
} from '@/vue_components/store/modules/globalCatalogs/nsi/russianSubjectsDictionaryStore/russianSubjectsDictionaryStore'

export const nsiStore = {
  namespaced: true,
  modules: {
    idCardTypesDictionaryStore,
    russianSubjectsDictionaryStore,
  },
}
