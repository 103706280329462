<template>
  <img
    v-bind="$attrs"
    ref="vueUserAvatar"
    class="vue-user-avatar"
    :src="src"
    :style="styles"
    loading="lazy"
    v-on="$listeners"
  >
</template>

<script>
export default {
  name: 'UserAvatar',

  props: {
    userId: {
      type: [Number, Object],
      default: null,
    },

    defaultAvatar: Boolean,

    params: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    src () {
      return this.defaultAvatar || !this.userId
        ? Routes.user_default_avatar_path({ ...this.params, sex: 'unisex' })
        : Routes.user_avatar_path(this.userId, this.params)
    },

    styles () {
      const size = parseInt((this.params.version || '').replace(/\D/g, '')) || 0

      return { width: `${size}px`, height: `${size}px` }
    },
  },
}
</script>
