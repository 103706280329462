import { recordingPointsEndpoint } from '@/api_entities/recordingPoints/recordingPointsEndpoint'
import { recordingPointsFetchAllAdapter } from '@/api_entities/recordingPoints/recordingPointsAdapters'

export const recordingPointsPresenter = {
  fetchAll (filters, catalogs) {
    const __filters = recordingPointsFetchAllAdapter.toServer(filters, catalogs)

    return recordingPointsEndpoint.fetchAll(__filters)
      .then((response) => Promise.resolve(recordingPointsFetchAllAdapter.toClient(response, catalogs)))
      .catch((err) => {
        Utils.reportError('recordingPointsPresenter.fetchAll()')(err)
        throw new Error('')
      })
  },
}
