
export const MESSAGE_DELIVERY_STATUSES = Object.freeze({
  FAILED: { status: 'failed', transport: 'general' },
  STARTED: { status: 'started', transport: 'general' },
  SENT: { status: 'sent', transport: 'whatsapp' },
  SENT_TO_TRANSPORT: { status: 'sent_to_transport', transport: 'general' },
  DELIVERED: { status: 'delivered', transport: 'whatsapp' },
  READ: { status: 'read', transport: 'whatsapp' },
})

export const SMS_DELIVERY_STATUSES = Object.freeze({
  SENT_TO_TRANSPORT: { status: 'sent_to_transport', transport: 'general' },
  FAILED: { status: 'failed', transport: 'general' },
  STARTED: { status: 'started', transport: 'general' },
})

export const FLASH_CALL_DELIVERY_STATUSES = Object.freeze({
  SENT_TO_TRANSPORT: { status: 'sent_to_transport', transport: 'general' },
  FAILED: { status: 'failed', transport: 'general' },
})

export const MESSAGE_DELIVERY_STATUS_ICONS = Object.freeze({
  [MESSAGE_DELIVERY_STATUSES.STARTED.status]: { icon: 'startedMessage', color: 'primary' },
  [MESSAGE_DELIVERY_STATUSES.FAILED.status]: { icon: 'failedMessage', color: 'danger' },
  [MESSAGE_DELIVERY_STATUSES.SENT_TO_TRANSPORT.status]: { icon: 'sent_to_transportMessage', color: 'primary' },
  [MESSAGE_DELIVERY_STATUSES.SENT.status]: { icon: 'sentMessage', color: 'success' },
  [MESSAGE_DELIVERY_STATUSES.DELIVERED.status]: { icon: 'deliveredMessage', color: 'grey-dark' },
  [MESSAGE_DELIVERY_STATUSES.READ.status]: { icon: 'readMessage', color: 'grey-dark' },
})

export const MESSAGE_DELIVERY_TYPE = Object.freeze({
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
  FLASH_CALL: 'flash_call',
})

export const MESSAGE_TYPES = Object.freeze({
  APPOINTMENT_CONFIRMATION: 'appointment_confirmation',
  APPOINTMENT_NOTIFY: 'appointment_notify',
  APPOINTMENT_REMIND: 'appointment_remind',
  CLIENTS_BIRTHDAY: 'clients_birthday',
  CLIENTS_DISPATCH: 'clients_dispatch',
  CLIENTS_FEEDBACK: 'clients_feedback',
  CUSTOM_MESSAGE: 'custom_message',
  CONFIRMATION_CODE: 'confirmation_code',
  SERVICES_STATUS: 'services_status',
  AGGREGATOR_FEEDBACK: 'aggregator_feedback',
})

export const MESSAGE_ERRORS_COMMON = Object.freeze({
  SERVER_ERROR: 'server_error',
  UNKNOWN_ERROR: 'unknown_error',
  UNEXPECTED_ERROR: 'unexpected_error',
  STATUS_CHANGING_TIMEOUT: 'status_changing_timeout',
})

export const MESSAGE_ERRORS_SMS = Object.freeze({
  PARAMS_ERROR: 'params_error',
  AUTHORIZATION_ERROR: 'authorization_error',
  NOT_ENOUGH_MONEY: 'not_enough_money',
  IP_BLOCKED: 'ip_blocked',
  WRONG_DATE_FORMAT: 'wrong_date_format',
  MESSAGE_IS_BLOCKED: 'message_is_blocked',
  WRONG_PHONE_NUMBER_FORMAT: 'wrong_phone_number_format',
  CAN_NOT_BE_DELIVERED_ON_THIS_PHONE_NUMBER: 'can_not_be_delivered_on_this_phone_number',
  DUPLICATED_MESSAGE_ERROR: 'duplicated_message_error',
  MESSAGE_PARSING_ERROR: 'message_parsing_error',
  PHONE_ERROR: 'phone_error',
  ABONENT_BLOCKED: 'abonent_blocked',
  UNSUPPORTED_SERVICE: 'unsupported_service',
  QUEUE_OVERFLOW: 'queue_overflow',
  ABONENT_BUSY: 'abonent_busy',
  CONTRACT_ERROR: 'contract_error',
  DISPATCHES_FORBIDDEN: 'dispatches_forbidden',
  STATUS_NOT_RECIEVED: 'status_not_recieved',
  MESSAGE_LIMIT: 'message_limit',
  NO_ROUTE: 'no_route',
  WRONG_PHONE_FORMAT: 'wrong_phone_format',
  NUMBER_FORBIDDEN_BY_SETTINGS: 'number_forbidden_by_settings',
  MESSAGE_ONE_LIMIT: 'message_one_limit',
  NUMBER_FORBIDDEN: 'number_forbidden',
  BLOCKED_BY_SPAM_FILTER: 'blocked_by_spam_filter',
  UNREGISTERED_SENDER_ID: 'unregistered_sender_id',
  REJECTED_BY_OPERATOR: 'rejected_by_operator',
})

export const MESSAGE_ERRORS_WHATSAPP = Object.freeze({
  MESSAGE_ONLY_TEXT_OR_CONTENT: 'message_only_text_or_content',
  MESSAGE_NOTHING_TO_SEND: 'message_nothing_to_send',
  MESSAGE_TEXT_TOO_LONG: 'message_text_too_long',
  MESSAGE_CONTENT_CAN_NOT_BE_BLANK: 'message_content_can_not_be_blank',
  MESSAGE_CONTENT_SIZE_EXCEEDED: 'message_content_size_exceeded',
  MESSAGE_TEXT_CAN_NOT_BE_BLANK: 'message_text_can_not_be_blank',
  CHANNEL_NOT_FOUND: 'channel_not_found',
  CHANNEL_BLOCKED: 'channel_blocked',
  MESSAGE_DOWNLOAD_CONTENT_ERROR: 'message_download_content_error',
  MESSAGES_NOT_TEXT_FIRST: 'messages_not_text_first',
  MESSAGES_IS_SPAM: 'messages_is_spam',
  CHAT_WRONG_CHAT_TYPE: 'chat_wrong_chat_type',
  CHAT_MISSING_CHAT_TYPE: 'chat_missing_chat_type',
  CHAT_INCORRECT_CHAT_ID_WHATSAPP: 'chat_incorrect_chat_id_whatsapp',
  CHAT_MISSING_CHAT_ID: 'chat_missing_chat_id',
  VALIDATION_ERROR: 'validation_error',
  ACCOUNT_NOT_FOUND: 'account_not_found',
  CONTACT_NOT_FOUND: 'contact_not_found',
  CHANNEL_NO_MONEY: 'channel_no_money',
  MESSAGE_CHANNEL_UNAVAILABLE: 'message_channel_unavailable',
  CONTACT_DETAIL_NOT_FOUND: 'contact_detail_not_found',
  MESSAGES_ABNORMAL_SEND: 'messages_abnormal_send',
  MESSAGES_INVALID_CONTACT_TYPE: 'messages_invalid_contact_type',
  MESSAGES_CAN_NOT_ADD: 'messages_can_not_add',
  REFERENCE_MESSAGE_NOT_FOUND: 'reference_message_not_found',
  MESSAGE_WRONG_CONTENT_TYPE: 'message_wrong_content_type',
  BAD_CONTACT: 'bad_contact',
})

export const MESSAGE_ERRORS_FLASH_CALL = Object.freeze({
  WRONG_PHONE: 'wrong_phone',
  UNDEFINED_ERROR: 'undefined_error',
  ACCOUNT_ACTIVATION_NEEDED: 'account_activation_needed',
  TOO_MANY_REQUESTS: 'too_many_requests',
})
