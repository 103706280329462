import { APPOINTMENT_STATUS } from '@/vue_components/appointment/const'

export const DEFAULT_FILTRATION_DEBOUNCE = 200

export const APPOINTMENT_ICONS_BY_STATUS = Object.freeze({
  [APPOINTMENT_STATUS.NEED_APPROVAL]: 'needApproval',
  [APPOINTMENT_STATUS.APPROVED]: 'approved',
  [APPOINTMENT_STATUS.ARRIVED]: 'arrived',
  [APPOINTMENT_STATUS.SERVICED]: 'serviced',
  [APPOINTMENT_STATUS.BILLED]: 'billed',
  [APPOINTMENT_STATUS.PAID]: 'paid',
  [APPOINTMENT_STATUS.CANCELED]: 'canceled',
  [APPOINTMENT_STATUS.FAILED]: 'failed',
  [APPOINTMENT_STATUS.CONFIRMED_BY_ADMINISTRATOR]: 'confirmedByAdministrator',
})

export const CALL_CONFIRMATION_STATUS = Object.freeze({
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  CLIENT_CANCELED: 'client_canceled',
  FAILED_TO_CONNECT: 'failed_to_connect',
})

export const CALL_CONFIRMATION_STATUS_ICONS = Object.freeze({
  [CALL_CONFIRMATION_STATUS.PENDING]: 'pending',
  [CALL_CONFIRMATION_STATUS.CONFIRMED]: 'confirmed',
  [CALL_CONFIRMATION_STATUS.CLIENT_CANCELED]: 'clientCanceled',
  [CALL_CONFIRMATION_STATUS.FAILED_TO_CONNECT]: 'failedToConnect',
})

export const FILTERS = Object.freeze({
  CLINIC: 'clinic',
  DATE: 'date',
  SPECIALTY: 'specialty',
  USER: 'user',
  STATUS: 'status',
  CALL_CONFIRMATION_STATUS: 'callConfirmationStatus',
})

export const APPOINTMENT_CALL_LOG_CACHE_KEY = 'appointmentCallLogCacheKey'
