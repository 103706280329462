export const listAdapter = {
  toClient (response, { permittedValues }) {
    if (permittedValues) {
      response.data = response.data
        .filter((item) => permittedValues.includes(item.title))
    }

    return response
  },
}
