
export const NSI_DICTIONARIES = Object.freeze({
  /* global */
  RUSSIAN_SUBJECTS: 'russian_subjects',
  ID_CARD_TYPES: 'id_card_types',
  PROFESSIONS: 'professions',

  /* local */
  MKB10: 'mkb_diagnosis_groups',
  CASE_VISIT_TYPES: 'case_visit_types',
  MEDICAL_CARE_PLACES: 'medical_care_places',
  PATIENT_CONDITIONS: 'patient_conditions',
  IDENTIFIED_PATHOLOGIES: 'mkb_diagnosis_groups',
  MEDCARD_TYPES: 'medcard_types',
  PRIVACY_LEVELS: 'privacy_levels',
  FOUNDATION_DOCUMENT_TYPES: 'foundation_document_types',
  SEMD_TYPES: 'semd_types',
  MEDICAL_ASSESSMENTS: 'medical_assessments',
  POSITIONS: 'positions',
  VACCINATIONS: 'vaccinations',
  GENDERS: 'genders',
  INSURANCE_COMPANIES: 'insurance_companies',
  MKB_DIAGNOSIS_GROUPS: 'mkb_diagnosis_groups',
  PAYMENT_SOURCES: 'payment_sources',
  OMS_TYPES: 'oms_types',
  PROFILES_OF_LABORATORY_RESEARCHES: 'profiles_of_laboratory_researches',
  INSTRUMENTAL_DIAGNOSTIC_RESEARCHES: 'instrumental_diagnostic_researches',
  MIBP_DISEASES_GROUPS: 'mibp_diseases_groups',
  CHECKUPS_FACTORS: 'checkups_factors',
  CODABLE_FIELDS_OF_CDA_DOCUMENTS: 'codable_fields_of_cda_documents',
  EMD_SECTIONS: 'emd_sections',
  USERS_SIGNATURE_ROLES: 'users_signature_roles',
  DOCUMENTED_EVENTS_TYPES: 'documented_events_types',
  SEMD_KINDS: 'semd_kinds',
  EMDS: 'emds',
  UNITS: 'units',
  DETECTED_PATHOLOGIES: 'detected_pathologies',
  ECHO_IMAGE_QUALITIES: 'echo_image_qualities',
  ULTRASOUND_MODES: 'ultrasound_modes',
})
