
export const CALCULATED_ENTRY_STATUS = Object.freeze({
  ACTIVE: 'active',
  CANCELED: 'canceled',
})

export const CALCULATED_ENTRY_STATUS_ICONS = Object.freeze({
  [CALCULATED_ENTRY_STATUS.ACTIVE]: 'close',
  [CALCULATED_ENTRY_STATUS.CANCELED]: 'send',
})

const CALCULATED_ENTRY_STATUS_STATES = Object.freeze({
  [CALCULATED_ENTRY_STATUS.ACTIVE]: {
    status: CALCULATED_ENTRY_STATUS.CANCELED,
    icon: CALCULATED_ENTRY_STATUS_ICONS[CALCULATED_ENTRY_STATUS.CANCELED],
  },
  [CALCULATED_ENTRY_STATUS.CANCELED]: {
    status: CALCULATED_ENTRY_STATUS.ACTIVE,
    icon: CALCULATED_ENTRY_STATUS_ICONS[CALCULATED_ENTRY_STATUS.ACTIVE],
  },
})

/**
 *
 * @param {string} status
 * @return {{ status: 'active' | 'canceled', icon: string }}
 */
export const getCalculatedEntryNextState = (status) => CALCULATED_ENTRY_STATUS_STATES[status]

export const getCustomCheckupEntryType = (options = {}) => ({
  period: options.period ?? 'P1Y',
  status: options.status ?? CALCULATED_ENTRY_STATUS.ACTIVE,

  checkupEntryType: {
    id: options.id,
    kind: options.kind,
    title: options.title,
  },
})
