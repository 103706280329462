import { MExpirableCache } from '@/_api/_storage/MExpirableCache'
import { MNSIPresenter } from '@/_api/AppConfiguration/MNSIPresenter'
import { GLOBAL_TIMES } from '@/_global_scripts/GLOBAL_CONSTS'
import { NSI_DICTIONARIES } from '@/vue_present/Reuse/Lists/NSI/nsiDictionaries'
import { defaultSearchFilter } from '@/helpers/lambda'

const MAX_LIMIT = 50

const cache = new MExpirableCache('NSIRussianSubjects', {
  timeout: GLOBAL_TIMES.ONE_HOUR,
})

export const russianSubjectsDictionaryStore = {
  namespaced: true,
  state: {
    russianSubjects: [],
    loading: false,
  },

  getters: {
    vxGetRussianSubjects: (state) => state.russianSubjects,
    vxGetRussianSubjectMethod: (state) => (title) => {
      if (!title) {
        console.error('Не указан искомый регион')

        return null
      }

      return state.russianSubjects.find((subject) => {
        if (title === 'Алтай') {
          return subject.title === 'Республика Алтай'
        }

        if (title === 'Коми') {
          return subject.title === 'Республика Коми'
        }

        if (title === 'Саха /Якутия/') {
          return defaultSearchFilter('Республика Саха')(subject)
        }

        if (title === 'Омская') {
          return subject.title === 'Омская область'
        }

        return defaultSearchFilter(title?.slice(0, 10))(subject)
      })
    },
  },

  mutations: {
    vxSetRussianSubjectsLoading (state, loading) {
      state.loading = loading
    },

    vxSetRussianSubjects (state, russianSubjects) {
      state.russianSubjects = russianSubjects
    },

    vxAppendRussianSubjects (state, russianSubjects) {
      state.russianSubjects = [
        ...state.russianSubjects,
        ...russianSubjects,
      ]
    },

    vxSetRussianSubjectsParams (state) {
      state.loading = false
      cache.save(state.russianSubjects)
    },
  },

  actions: {
    async vxLoadRussianSubjects ({ commit, dispatch, state }, offset = 0) {
      if (!gon.application.nsi?.russianSubjectsDictionary) { return }
      if (state.loading && !offset) { return }

      commit('vxSetRussianSubjectsLoading', true)

      const restoredData = cache.restore()
      if (restoredData && !offset) {
        commit('vxSetRussianSubjects', restoredData)

        return
      }

      const { errors, data, totalItems } = await new MNSIPresenter()
        .list({ offset, limit: MAX_LIMIT, dictionary: NSI_DICTIONARIES.RUSSIAN_SUBJECTS })

      if (errors) { return }

      commit('vxAppendRussianSubjects', data)

      const nextOffset = offset + MAX_LIMIT
      totalItems && totalItems > nextOffset
        ? dispatch('vxLoadRussianSubjects', nextOffset)
        : commit('vxSetRussianSubjectsParams')
    },
  },
}
