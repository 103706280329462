import BaseFormatter from './BaseFormatter'

const DEFAULT_CURRENCY_DIGITS = 2
const CURRENCY_WITH_SIGN_DIGITS = 2
const NUMBER_GROUP_SIZE = 3
const CURRENCY_SIGN_BEFORE = 'before'

export default class NumberFormatter extends BaseFormatter {
  /**
   * @param {FormatterOptions & {
   *   currencySign?: string, currencySignPosition?: 'before'|'after'
   * }} [options]
   */
  constructor (options = {}) {
    super(options)
    const {
      symbol_character: currencySign,
      currency_symbol_position: currencySignPosition,
      grouping_character: groupingCharacter,
    } = gon.localization.currency
    this.currencySign = options.currencySign || currencySign
    this.currencySignPosition = options.currencySignPosition || currencySignPosition
    this.groupingCharacter = groupingCharacter
  }

  /**
   * @template T
   * @param {T} value
   * @return {number|T}
   */
  maybeParseNumber (value) {
    const numberCopy = parseFloat(value)

    return !isNaN(numberCopy) ? numberCopy : value
  }

  /**
   * @template T
   * @param {T} value
   * @return {string|T}
   */
  currencyWithSign (value) {
    value = this.maybeParseNumber(value)
    if (typeof value !== 'number') {
      return value
    }

    const values = [
      this.formatNumberLocal(value, CURRENCY_WITH_SIGN_DIGITS),
      this.currencySign,
    ]

    if (this.currencySignPosition === CURRENCY_SIGN_BEFORE) {
      values.reverse()
    }

    return values.join(' ')
  }

  /**
   * @template T
   * @param {T} value
   * @param {number} digits
   * @return {string|T}
   */
  formatNumberLocal (value, digits = DEFAULT_CURRENCY_DIGITS) {
    value = this.maybeParseNumber(value)
    if (typeof value !== 'number') {
      return value
    }

    const numbers = value.toFixed(digits).split('')
    const decimalPointIndex = numbers.indexOf('.')
    const wholePartLength = decimalPointIndex !== -1
      ? decimalPointIndex
      : numbers.length
    const decimalPointIndexFromEnd = decimalPointIndex !== -1
      ? numbers.length - decimalPointIndex
      : 0

    for (let i = NUMBER_GROUP_SIZE; i < wholePartLength; i += NUMBER_GROUP_SIZE) {
      numbers.splice(wholePartLength - i, 0, this.groupingCharacter)
    }

    if (decimalPointIndexFromEnd) {
      numbers.splice(
        numbers.length - decimalPointIndexFromEnd,
        1,
        this.decimalCharacter
      )
    }

    if (numbers[0] === '-' && numbers[1] === this.groupingCharacter) {
      numbers.splice(1, 1)
    }

    return numbers.join('')
  }

  rateType (value) {
    if (value === 'currency' || value === 'fixed') {
      return gon.localization.currency.symbol_character
    }

    return '%'
  }
}

NumberFormatter.namespace = 'number'
