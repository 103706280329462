import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { camelToSnake } from '@/_api/_requests/helpers'

export class MSpecialtyPresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'specialty',
      location: 'MSpecialtyPresenter',
      routes: { one: Routes.specialty_path, all: Routes.specialties_path, list: Routes.list_specialties_path },
    })
  }

  timeFinderList (data) {
    const config = {
      url: Routes.doctor_schedules_fetch_specialties_path(),
      data: camelToSnake({ clinicId: data }),
    }

    const notification = new MRequestNotification(
      'MSpecialtyPresenter::timeFinderList',
      'fetchAllMessage',
      'specialties'
    )

    return this.get(config).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault([], notification))
  }

  search (params) {
    const options = {
      url: Routes.search_specialties_path(),
      data: { title: params.searchQuery },
    }

    return this.get(options).promise
      .then(MRequestSuccess.onResponse((data) => data.result))
  }
}
