import { MRequestHandler } from '@/_api/_requests/MRequestHandler'
import { MRequestWrapper } from '@/_api/_requests/MRequestWrapper'
import { CRUD_OPS } from '@/_api/_requests/const'
import { camelToSnake, extractRequestData, extractRequestParams, getLocationTemplate } from '@/_api/_requests/helpers'
import { omit } from 'lodash'

/**
 * @typedef {{
 * routes: { one: (id) => string, all: () => string, list?: () => string },
 * entity: string,
 * location: string
 * }} MPresenterBaseConfig
 */

/**
 * @typedef {{
 *   data?: any,
 *   toJson?: boolean,
 *   toClientAdapter?: function,
 *   toServerAdapter?: function,
 * }} PresenterMethodConfig
 */

export class MPresenterBase extends MRequestHandler {
  /**
   * @param {MPresenterBaseConfig} config
   */
  constructor (config) {
    super()

    this.__validateBaseConfig(config)

    this.routes = config.routes
    this.entity = config.entity
    this.location = config.location
  }

  /**
   * @param {MPresenterBaseConfig} config
   */
  __validateBaseConfig (config) {
    if ([
      config?.routes?.one && config?.routes?.all,
      config.location,
      config.entity,
    ].every(Boolean)) { return }

    throw new Error('Неверная конфигурация MPresenterBase')
  }

  /**
   * @param __config
   * @returns {Promise<*|RequestErrors>}
   * @private
   */
  __execQuery (__config) {
    return new MRequestWrapper({
      ...__config,
      data: camelToSnake(__config?.data || {}),
    }).buildQuery()
  }

  /**
   * Получение всех записей
   * @param data
   * @param {PresenterMethodConfig} config
   * @return {Promise<*|RequestErrors>}
   */
  fetchAll (data, config = {}) {
    const __config = {
      ...config,
      data,
      location: getLocationTemplate(this.location, this.entity, CRUD_OPS.FETCH_ALL),
      url: this.routes.all(),
    }

    return this.__execQuery(__config)
  }

  /**
   * Список записей из сорт-сервиса
   * @param listData
   * @param {PresenterMethodConfig} config
   * @returns {Promise<TListServiceResult> | Promise<*|RequestErrors>}
   */
  list (listData, config = {}) {
    const sortParams = { sort_params: extractRequestData(listData, config) }

    const { data, headers } = extractRequestParams(sortParams, config)

    const __config = {
      ...omit(config, ['toJson']),
      data,
      location: getLocationTemplate(this.location, this.entity, CRUD_OPS.LIST),
      url: this.routes.list(),
      ...headers,
    }

    return this.__execQuery(__config)
  }

  /**
   * Получение по id
   * @param id
   * @param {PresenterMethodConfig} config
   * @return {Promise<*|RequestErrors>}
   */
  fetch (id, config = {}) {
    const __config = {
      ...config,
      location: getLocationTemplate(this.location, this.entity, CRUD_OPS.FETCH),
      url: config.url || this.routes.one(id),
    }

    return this.__execQuery(__config)
  }

  /**
   * Создание
   * @param createData
   * @param {PresenterMethodConfig} config
   * @return {Promise<*|RequestErrors>}
   */
  create (createData, config = {}) {
    const { data, headers } =
      extractRequestParams(extractRequestData(createData, config), config)

    const __config = {
      ...config,
      data,
      location: getLocationTemplate(this.location, this.entity, CRUD_OPS.CREATE),
      url: config.url || this.routes.all(),
      ...headers,
    }

    return this.__execQuery(__config)
  }

  /**
   * Обновление
   * @param updateData
   * @param {PresenterMethodConfig} config
   * @return {Promise<*|RequestErrors>}
   */
  update (updateData, config = {}) {
    const { data, headers } =
      extractRequestParams(extractRequestData(updateData, config), config)

    const __config = {
      ...config,
      data,
      location: getLocationTemplate(this.location, this.entity, CRUD_OPS.UPDATE),
      url: config.url || this.routes.one(updateData.id),
      ...headers,
    }

    return this.__execQuery(__config)
  }

  /**
   * Сохранение
   * @param data
   * @param {PresenterMethodConfig} config
   * @return {Promise<*|RequestErrors>}
   */
  submit (data, config = {}) {
    return data?.id
      ? this.update(data, config)
      : this.create(data, config)
  }

  /**
   * Удаление по id.
   * Если требуются дополнительные данные, то передайте их в параметре config.data
   * @param id
   * @param {PresenterMethodConfig} config
   * @return {Promise<*|RequestErrors>|null}
   */
  destroy (id, config = {}) {
    const __config = {
      ...config,
      location: getLocationTemplate(this.location, this.entity, CRUD_OPS.DESTROY),
      url: config.url || this.routes.one(id),
    }

    return this.__execQuery(__config)
  }
}
