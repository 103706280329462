import { extractId, orNull, orUndefined } from '@/_medods_standart_library/msl'
import {
  PersonalCheckupListCellItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupListCellItem'
import {
  PersonalCheckupListTotalCell,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckup/PersonalCheckupListTotalCell'
import {
  getCheckupEntries,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupCheckupEntriesPresenter/personalCheckupCheckupEntriesAdapter'
import {
  getVaccinations,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupVaccinationPresenter/personalCheckupVaccinationAdapter'
import {
  getInfectiousDiseases,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupInfectiousDiseasesPresenter/personalCheckupInfectiousDiseasesAdapter'
import {
  getDoctorResults,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupDoctorResultsPresenter/personalCheckupDoctorResultsAdapter'
import {
  getDiagnosesPersonal,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupDiagnosesPersonalPresenter/personalCheckupDiagnosesPersonalAdapter'
import {
  getResearchResults,
} from '@/vue_apps/CheckupsModule/api/PersonalCheckupInstrumentalResearchResultsPresenter/personalCheckupInstrumentalResearchResultsAdapter'

export const personalCheckupAdapter = {
  /**
   * @param {IPersonalCheckup} pc
   */
  toServer (pc) {
    return {
      personalCheckup: {
        id: orUndefined(pc.id),
        clientId: orUndefined(pc.client?.id),
        status: orUndefined(pc.status),
        kind: orUndefined(pc.kind),
        responsibleId: orUndefined(pc.responsible?.id),
        companyId: orUndefined(pc.company?.id),
        clinicId: orUndefined(pc.clinic?.id),
        dateStart: pc.dateStart,
        dateEnd: pc.dateEnd,
        nextDate: pc.nextDate,

        companyCheckupId: orUndefined(pc.companyCheckupId),
        department: pc.department,
        position: pc.position,
        nsiProfessionId: getNsiProfession(pc.nsiProfession),
        healthGroup: pc.healthGroup,
        positionProfile: orNull(pc.positionProfile?.title),
        comment: pc.comment,
        orderId: orUndefined(pc.orderId),
        medicalAssessmentId: orNull(pc.medicalAssessment?.id),

        recommendationIds: orUndefined(pc.recommendations?.map(extractId)),

        contraindicationFactorIds: orUndefined(pc.contraindicationsFactors?.map(extractId)),

        checkupFactorIds: orUndefined(pc.factors?.map(extractId)),

        diagnosesPersonalAttributes: getDiagnosesPersonal(pc.diagnosesPersonal),

        checkupEntries: getCheckupEntries(pc.checkupEntries),
        vaccinationsAttributes: getVaccinations(pc.vaccinations),

        infectiousDiseasesAttributes: getInfectiousDiseases(pc.infectiousDiseases),

        doctorResultsAttributes: getDoctorResults(pc.doctorResults),

        laboratoryResearchResultsAttributes: getResearchResults(pc.laboratoryResearchResults),

        instrumentalResearchResultsAttributes: getResearchResults(pc.instrumentalResearchResults),
      },
    }
  },

  listFiltersToServer (filtersData) {
    return {
      offset: filtersData.offset,
      limit: filtersData.limit,
      clinicId: orUndefined(filtersData.clinic?.id),
      status: orUndefined(filtersData.status),
      period: orUndefined(filtersData.period),
      companyId: orUndefined(filtersData.company?.id),
      responsibleId: orUndefined(filtersData.responsible?.id),
      companyCheckupId: orUndefined(filtersData.companyCheckupId),
      kind: orUndefined(filtersData.kind),
      searchQuery: orUndefined(filtersData.searchQuery),
      withOrder: orUndefined(filtersData.withOrder),
    }
  },

  toClient (response) {
    response.data = response.data.map((item) => new PersonalCheckupListCellItem(item))
    response.total = new PersonalCheckupListTotalCell(response)

    return response
  },
}

/**
 * nsiProfession = undefined -> убрать поле из запроса,
 * чтобы не было обнуления профессии при частичном обновлении
 * orNull(nsiProfession?.id) = null -> обнулить поле профессии если оно действительно очищено
 * orNull(nsiProfession?.id) = "начение" -> обновить поле професии
 * @param nsiProfession
 * @returns {undefined|number|null}
 */
const getNsiProfession = (nsiProfession) => {
  return nsiProfession === undefined
    ? undefined
    : orNull(nsiProfession?.id)
}
