<template>
  <div
    ref="container"
    :class="className"
  >
    <div
      ref="header"
      class="header"
    >
      <slot name="header" />
    </div>
    <epic-spinner
      :visibility="spinner"
    />
    <div
      ref="body"
      class="body"
      @scroll="$emit('scroll', $event)"
    >
      <div
        v-if="error && !spinner"
        class="error"
      >
        <span class="error-message">
          {{ errorMessage }}
        </span>
      </div>

      <div
        v-else-if="notFound && !spinner"
        class="not-found"
      >
        <div class="not-found-message">
          <slot name="not-found-message">
            {{ notFoundMessage }}
          </slot>
        </div>
      </div>

      <slot
        v-else
        name="body"
      />
    </div>

    <div
      ref="footer"
      class="footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import EpicSpinner from '../../epic_spinner/epic_spinner.vue'

export default {
  name: 'SiTable',

  components: {
    EpicSpinner,
  },

  props: {
    // set this to `true` you want to make a horizontally scrolling table
    horizontal: Boolean,

    striped: {
      type: Boolean,
      default: true,
    },

    error: Boolean,
    notFound: Boolean,
    spinner: Boolean,

    errorMessage: {
      type: String,
      default: t('si_table.error_message'),
    },

    notFoundMessage: {
      type: String,
      default: t('si_table.not_found_message'),
    },
  },

  computed: {
    className () {
      return ['si-table', 'table', { horizontal: this.horizontal, striped: this.striped }]
    },
  },

  watch: {
    spinner (visible) {
      if (visible) { return }

      this.scrollTop()

      // шаблон отчёта может меняться после загрузки, обновим ширину здесь,
      // чтобы не делать это в каждом таком отчёте
      if (!this.horizontal) { return }
      this.updateMinWidth()
    },
  },

  mounted () {
    if (!this.horizontal) { return }

    this.updateMinWidth()
    window.addEventListener('resize', this.updateMinWidth)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.updateMinWidth)
  },

  methods: {
    scrollTop () {
      (this.horizontal ? this.$refs.container : this.$refs.body).scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },

    updateMinWidth () {
      const maxWidth = Math.max(this.$refs.header.clientWidth, this.$refs.header.scrollWidth)
      const finalWidth = `${maxWidth}px`

      this.$refs.header.style.minWidth = finalWidth
      this.$refs.body.style.minWidth = finalWidth
      this.$refs.footer.style.minWidth = finalWidth
    },
  },
}
</script>
