import { MWorkplacePresenter } from '@/_api/Workplace/MWorkplacePresenter'
import { MSignCertificatePresenter } from '@/_api/Workplace/MSignCertificatePresenter'

export const workplacesStore = {
  namespaced: true,
  state: {
    workplaces: [],
    certificates: [],
    workplacesLoading: false,
    certificatesLoading: false,
  },

  getters: {
    vxGetWorkplaces: (state) => state.workplaces,
    vxGetCertificates: (state) => state.certificates,
    vxGetWorkplacesIsLoading: (state) => state.workplacesLoading,
    vxGetCertificatesIsLoading: (state) => state.certificatesLoading,
  },

  mutations: {
    vxSetWorkplaces (state, list) {
      state.workplaces = list
    },

    vxSetCertificates (state, list) {
      state.certificates = list
    },

    vxSetWorkplaceLoading (state, value) {
      state.workplacesLoading = value
    },

    vxSetCertificatesLoading (state, value) {
      state.certificatesLoading = value
    },
  },

  actions: {
    async vxFetchWorkplaces ({ commit }, payload) {
      commit('vxSetWorkplaceLoading', true)
      const result = await new MWorkplacePresenter().list(payload)
      commit('vxSetWorkplaceLoading', false)
      if (result?.errors) { return }
      commit('vxSetWorkplaces', result.data)
    },

    async vxFetchCertificates ({ commit }, payload) {
      commit('vxSetCertificatesLoading', true)
      const result = await new MSignCertificatePresenter().list(payload)
      commit('vxSetCertificatesLoading', false)
      if (result?.errors) { return }
      commit('vxSetCertificates', result.data)
    },
  },
}
