const FORM_SELECTOR = '#clients_form'
const LEGAL_REPRESENTATIVES = 'legal_representatives'

const getLegalRepresentativesHtml = (value) =>
  `<input type="hidden" name="client[${LEGAL_REPRESENTATIVES}]" value='${value}'>` // value - одинарные ковычки обязательно!

// иньекция legal_representatives в clients_form
export const addLegalRepresentativesToForm = (relations, relationsSelector) => {
  const relationJson = JSON.stringify(relations).replace(/\\"/g, '\\"')

  relationsSelector
    .parents(FORM_SELECTOR)
    .children(`[name="client[${LEGAL_REPRESENTATIVES}]"]`)
    .remove()

  relationsSelector
    .parents(FORM_SELECTOR)
    .append(getLegalRepresentativesHtml(relationJson))
}
