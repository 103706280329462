<template>
  <el-select
    class="base-select"
    v-bind="$attrs"
    v-on="$listeners"
    @clear="handleCleaning"
  >
    <template v-slot:prefix>
      <slot name="prefix" />
    </template>
    <slot />
  </el-select>
</template>

<script>
export default {
  name: 'BaseSelect',

  props: {
    resetValue: {
      type: [Boolean, String, Number],
      default: '',
    },
  },

  methods: {
    handleCleaning () {
      this.$emit('input', this.resetValue)
    },
  },
}
</script>
