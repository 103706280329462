export const EGISZ_STATUSES = Object.freeze({
  NOT_SENT: 'not_sent',
  FAILURE: 'failure',
  SENDING_ERROR: 'sending_error',
  SUCCESS: 'success',
  SUCCESSFULLY_SENT: 'successfully_sent',
  READY_TO_SEND: 'ready_to_send',
  NOT_REGISTERED: 'not_registered',
  NEED_PERSONAL_SIGNATURE: 'need_personal_signature',
  NEED_CLINIC_SIGNATURE: 'need_clinic_signature',
  NEED_SIGNATURES: 'need_signatures',
  MED_DOCUMENT_SUCCESS: 'success',
})

export const EGISZ_STATUSES_ENUM = Object.freeze({
  [EGISZ_STATUSES.NOT_REGISTERED]: 0,
  [EGISZ_STATUSES.NEED_SIGNATURES]: 1,
  [EGISZ_STATUSES.NEED_PERSONAL_SIGNATURE]: 0,
  [EGISZ_STATUSES.NEED_CLINIC_SIGNATURE]: 0,
  [EGISZ_STATUSES.NOT_SENT]: 1,
  [EGISZ_STATUSES.READY_TO_SEND]: 1,
  [EGISZ_STATUSES.FAILURE]: 2,
  [EGISZ_STATUSES.SUCCESS]: 3,
})

export const EGISZ_CLIENTS_STATUSES_LIST = Object.freeze([
  Object.freeze({
    value: EGISZ_STATUSES_ENUM[EGISZ_STATUSES.NOT_REGISTERED],
    title: t('egisz.common_statuses.not_registered'),
  }),

  Object.freeze({
    value: 1,
    title: t('egisz.common_statuses.failure'),
  }),

  Object.freeze({
    value: 2,
    title: t('egisz.common_statuses.success'),
  }),
])

export const EGISZ_MED_CASE_STATUSES_LIST = Object.freeze([
  Object.freeze({
    value: EGISZ_STATUSES_ENUM[EGISZ_STATUSES.READY_TO_SEND],
    title: t('egisz.common_statuses.ready_to_send'),
  }),

  Object.freeze({
    value: EGISZ_STATUSES_ENUM[EGISZ_STATUSES.SUCCESS],
    title: t('egisz.common_statuses.success'),
  }),

  Object.freeze({
    value: EGISZ_STATUSES_ENUM[EGISZ_STATUSES.FAILURE],
    title: t('egisz.common_statuses.failure'),
  }),
])

export const EGISZ_MED_DOCUMENTS_STATUSES_LIST = Object.freeze([
  Object.freeze({
    value: 1,
    title: t('egisz.common_statuses.need_signatures'),
  }),

  Object.freeze({
    value: 2,
    title: t('egisz.common_statuses.ready_to_send'),
  }),

  Object.freeze({
    value: 3,
    title: t('egisz.common_statuses.failure'),
  }),

  Object.freeze({
    value: 4,
    title: t('egisz.common_statuses.success'),
  }),
])

export const EGISZ_CASE_CLOSED_STATUSES = Object.freeze([
  Object.freeze({ value: false, title: t('egisz.components.egisz_cases_filters.is_open') }),
  Object.freeze({ value: true, title: t('egisz.components.egisz_cases_filters.is_closed') }),
])

export const EGISZ_STATUSES_CSS = {
  CLOSED: 'fad fa-lock-open grey',
  READY_TO_SEND: 'fad fa-external-link-square primary',
  SUCCESS: 'fad fa-check success',
  SUCCESSFULLY_SENT: 'fad fa-share success',
  FAILURE: 'fad fa-exclamation-circle red',
  SENDING_ERROR: 'fad fa-exclamation-triangle warning',
  NEED_SIGNATURES: 'fad fa-file-signature grey-dark',
}

export const EGISZ_STATUSES_CLIENT_CSS = (status) => ({
  [EGISZ_STATUSES_CSS.READY_TO_SEND]: !status,
  [EGISZ_STATUSES_CSS.SUCCESS]: status === EGISZ_STATUSES.SUCCESS,
  [EGISZ_STATUSES_CSS.SUCCESSFULLY_SENT]: status === EGISZ_STATUSES.SUCCESSFULLY_SENT,
  [EGISZ_STATUSES_CSS.FAILURE]: status === EGISZ_STATUSES.FAILURE,
  [EGISZ_STATUSES_CSS.SENDING_ERROR]: status === EGISZ_STATUSES.SENDING_ERROR,
})

export const EGISZ_STATUSES_CASES_CSS = (closed, status) => ({
  [EGISZ_STATUSES_CSS.CLOSED]: !closed,
  [EGISZ_STATUSES_CSS.READY_TO_SEND]: closed && status === EGISZ_STATUSES.READY_TO_SEND,
  [EGISZ_STATUSES_CSS.SUCCESS]: closed && status === EGISZ_STATUSES.SUCCESS,
  [EGISZ_STATUSES_CSS.FAILURE]: closed && status === EGISZ_STATUSES.FAILURE,
})

export const EGISZ_STATUSES_EMD_CSS = (status) => ({
  [EGISZ_STATUSES_CSS.NEED_SIGNATURES]: status === EGISZ_STATUSES.NEED_CLINIC_SIGNATURE || status === EGISZ_STATUSES.NEED_PERSONAL_SIGNATURE,
  [EGISZ_STATUSES_CSS.READY_TO_SEND]: status === EGISZ_STATUSES.READY_TO_SEND,
  [EGISZ_STATUSES_CSS.SUCCESS]: status === EGISZ_STATUSES.SUCCESS,
  [EGISZ_STATUSES_CSS.FAILURE]: status === EGISZ_STATUSES.FAILURE,
})
