import { getDefaultCompanyData } from '@/vue_components/companies/components/consts'

function modifyFetchedCompany (fetchedCompany) {
  const companyData = getDefaultCompanyData()

  const company = {
    ...fetchedCompany,
    address_formal: companyData.address_formal,
    address_fact: companyData.address_fact,
    company_type_ids: fetchedCompany.company_types.map((type) => type.id),
    registration_date: fetchedCompany.registration_date ? fetchedCompany.registration_date : '',
    addresses: { fact: {}, formal: {} },
  }

  fetchedCompany.addresses.forEach((address) => {
    if (address.address_type === 1) {
      company.address_formal = address
    } else if (address.address_type === 2) {
      company.address_fact = address
    }
  })

  return company
}

export default modifyFetchedCompany
