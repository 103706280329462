import { orUndefined } from '@/_medods_standart_library/msl'

export const getInfectiousDiseases = (infectiousDiseases) => infectiousDiseases.map((i) => ({
  id: orUndefined(i.dbId),
  date: i.date,
  nsiDiagnosisId: i.nsiDiagnosisId,
  _destroy: orUndefined(i._destroy),
}))

export const personalCheckupInfectiousDiseasesAdapter = {
  toServer ({ ownerId, infectiousDiseases }) {
    return {
      personalCheckup: {
        id: ownerId,
        infectiousDiseasesAttributes: getInfectiousDiseases(infectiousDiseases),
      },
    }
  },
}
