import { filtersBase } from '@/vue_components/store/modules/filters_base'
import { FILTERS_MAP, getFilterTypes } from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/const/filters'
import { fetchAppointmentUsers } from '@/vue_components/doctor_schedules/rest'
import { recordingPointsPresenter } from '@/api_entities/recordingPoints/recordingPointsPresenter'
import { cloneDeep, isEmpty } from 'lodash'
import { saveToCache } from '@/vue_apps/DoctorSchedules/AppointmentTimeFinder/logic/cache'
import { MSpecialtyPresenter } from '@/_api/MSpecialty/MSpecialtyPresenter'

export const appointmentTimeFinder = {
  namespaced: true,
  modules: { filtersBase },

  state: () => ({
    filterTypes: getFilterTypes(),
    doctors: [],
    specialties: [],
    recordingPoints: [],
  }),

  getters: {
    vxGetFilterTypes: (state) => state.filterTypes,
    vxGetDoctors: (state) => state.doctors,
    vxGetSpecialties: (state) => state.specialties,
    vxGetRecordingPoints: (state) => state.recordingPoints,
  },

  mutations: {
    vxSetDoctors (state, value = []) {
      state.doctors = value
    },

    vxSetSpecialties (state, value = []) {
      state.specialties = value.map((item) => ({ id: item[0], title: item[1] }))
    },

    vxSetRecordingPoints (state, value = []) {
      state.recordingPoints = value
    },
  },

  actions: {
    async vxFetchDoctors ({ commit }, payload) {
      try {
        commit('vxSetDoctors', await fetchAppointmentUsers({ clinic_ids: payload }))
      } catch (err) {
        commit('vxSetDoctors', [])
      }
    },

    async vxFetchSpecialties ({ commit }, payload) {
      const presenter = new MSpecialtyPresenter()
      commit('vxSetSpecialties', await presenter.timeFinderList(payload))
    },

    async vxSearchRecordingPoints ({ commit, rootGetters }, filters) {
      const isFilterTypeEmpty = isEmpty(filters[FILTERS_MAP[filters.filterType]])

      if (isFilterTypeEmpty) {
        commit('vxSetRecordingPoints', [])
        commit('filtersBase/vxResetPagination')

        return
      }

      const cachedFilters = cloneDeep(filters)
      const catalogs = {
        clinics: Utils.arrayToMap(rootGetters['globalCatalogs/doctorStore/vxGetDoctorClinics']),
        doctors: Utils.arrayToMap(rootGetters['appointmentTimeFinder/vxGetDoctors']),
        specialties: Utils.arrayToMap(rootGetters['appointmentTimeFinder/vxGetSpecialties']),
      }

      const response = await recordingPointsPresenter.fetchAll(filters, catalogs)
      commit('vxSetRecordingPoints', response.recordingPoints)

      saveToCache(cachedFilters)

      const { limit } = rootGetters['appointmentTimeFinder/filtersBase/vxGetFilters']
      commit('filtersBase/vxSetTotalPages', Utils.getTotalPages(response.total, limit))
    },
  },
}
