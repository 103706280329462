import AbstractFormatter from './AbstractFormatter'

export class HtmlFormatter extends AbstractFormatter {
  constructor () {
    super()
    this.textarea = document.createElement('textarea')
  }

  evaluateEntities (value) {
    this.textarea.innerHTML = value
    const text = this.textarea.textContent
    // save memory
    this.textarea.innerHTML = ''

    return text
  }
}
HtmlFormatter.namespace = 'html'
