
export const mutations = {
  SET_CATALOGS_LOADED (state) {
    state.catalogs.isLoaded = true
  },

  SET_DOCTORS (state, doctors) {
    state.catalogs.doctors = doctors
  },

  SET_SPECIALTIES (state, specialties) {
    state.catalogs.specialties = specialties
  },

  SET_CASE_CATALOGS_LOADED (state) {
    state.caseCatalogs.isLoaded = true
  },

  SET_RELATIVE_TYPES (state, relativeTypes) {
    state.caseCatalogs.relativeTypes = relativeTypes
  },

  SET_PAYMENT_TYPES (state, paymentTypes) {
    state.caseCatalogs.paymentTypes = paymentTypes
  },

  SET_VISIT_TYPES (state, visitTypes) {
    state.caseCatalogs.visitTypes = visitTypes
  },

  SET_CASE_TYPES (state, caseTypes) {
    state.caseCatalogs.caseTypes = caseTypes
  },

  SET_CONFIDENTIALITY_LEVELS (state, confidentialityLevels) {
    state.caseCatalogs.confidentialityLevels = confidentialityLevels
  },

  SET_DISEASE_RESULTS (state, diseaseResults) {
    state.caseCatalogs.diseaseResults = diseaseResults
  },

  SET_STEP_TYPES (state, stepTypes) {
    state.caseCatalogs.stepTypes = stepTypes
  },

  SET_STEP_PLACES (state, stepPlaces) {
    state.caseCatalogs.stepPlaces = stepPlaces
  },

  SET_STEP_PURPOSES (state, stepPurposes) {
    state.caseCatalogs.stepPurposes = stepPurposes
  },

  SET_MED_RECORD_DIAGNOSIS_TYPES (state, diagnosisTypes) {
    state.caseCatalogs.medRecordDiagnosisTypes = diagnosisTypes
  },
}
