import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { smsPreviewAdapter } from '@/_api/Deliveries/smsPreviewAdapter'
import { smsCategoriesAdapter } from '@/_api/Deliveries/smsCategoriesAdapter'
import { deliveryListAdapter } from '@/_api/Deliveries/deliveryListAdapter'

const SMS_SETTINGS_CATEGORY_TYPE = 13

export class MDeliveryPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: { one () {}, all: Routes.messages_preview_path, list: Routes.deliveries_sort_path },
      entity: 'message',
      location: 'MDeliveryPresenter',
    })
  }

  /**
   * @param {TMessageToServer} data
   * @param config
   * @returns {Promise<TMessagesList>}
   */
  list (data, config = {}) {
    const finalData = deliveryListAdapter.toServer(data)

    return super.list(finalData, config)
      .then((data) => Promise.resolve(deliveryListAdapter.toClient(data)))
  }

  /**
   * @param {Object} data
   * @param {Function} toServerAdapter
   * @return {Promise<*>}
   */
  fetchPreview (data, toServerAdapter = camelToSnake) {
    const options = {
      url: Routes.messages_preview_path(),
      data: toServerAdapter({ text: data }),
    }

    const notification = new MRequestNotification(
      'MDeliveryPresenter:fetchPreview',
      'fetchMessage',
      'message'
    )

    return this.post(options).promise
      .then(MRequestSuccess.onResponse(smsPreviewAdapter.toClient))
      .catch(MRequestError.withDefault('', notification))
  }

  fetchSmsTemplates (id) {
    const options = {
      url: Routes.messages_templates_categories_path(),
      data: camelToSnake({
        id,
        categoryType: SMS_SETTINGS_CATEGORY_TYPE,
        kind: false,
      }),
    }

    const notification = new MRequestNotification(
      'MDeliveryPresenter:fetchSmsTemplates',
      'fetchMessage',
      'template'
    )

    return this.post(options, smsCategoriesAdapter.toClient).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault('', notification))
  }

  createSmsTemplate (data, config = {}) {
    const options = {
      url: Routes.messages_templates_path(),
      data: camelToSnake(data),
    }

    const notification = new MRequestNotification(
      'MDeliveryPresenter:createSmsTemplate',
      'createMessage',
      'template'
    )

    return this.post(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.withDefault('', notification))
  }

  fetchSmsBalance ({ login, password }) {
    const options = {
      url: Routes.fetch_info_messages_sms_configuration_path(),
      data: {
        login,
        password,
      },
    }

    return this.post(options).promise
      .then((data) => {
        Utils.reportSuccess(t('sms.successAuthorization'))()

        return data
      })
      .catch((err) => {
        Utils.reportError('messageStore:vxFetchSmsBalance', err.responseJSON.error)()

        return null
      })
  }

  saveSmsConfigurations (configurations) {
    const options = {
      url: Routes.messages_sms_configuration_path(),
      data: camelToSnake(configurations),
    }

    const notification = new MRequestNotification(
      'MDeliveryPresenter:saveSmsConfigurations',
      'updateMessage',
      'configurationsList'
    )

    return this.patch(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault('', notification))
  }

  sendCustomSms (configurations) {
    const options = {
      url: Routes.messages_deliveries_sms_custom_delivery_path(),
      data: camelToSnake(configurations),
    }

    const notification = new MRequestNotification(
      'MDeliveryPresenter:sendCustomSms',
      'sendMessage',
      'message'
    )

    return this.post(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.withDefault('', notification))
  }

  sendCustomWhatsappMessage (configuration) {
    const options = {
      url: Routes.messages_deliveries_whatsapp_custom_delivery_path(),
      data: camelToSnake(configuration),
    }

    const notification = new MRequestNotification(
      'MDeliveryPresenter:sendCustomWhatsappMessage',
      'sendMessage',
      'message'
    )

    return this.post(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }
}
