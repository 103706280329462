import Storage from './storage.js'

const PUB_SUB_EXCHANGE_GATEWAY = 'PUB_SUB_EXCHANGE_GATEWAY'

const useDebug = typeof new URL(location).searchParams.get('useDebug') === 'string' ||
  gon?.application?.env === 'development'

const DEBUG_MESSAGE_TEMPLATE = ['%cEpicPubSub publish:', 'background: #ddd; color: black; padding: 4px; border-radius: 4px']

export class EpicPubSub {
  constructor () {
    /** @type {BroadcastChannel} */
    this.broadcastChannel = null
    this.storage = Storage
    this.subscribersTable = new Map()
    window.addEventListener('storage', this._multiTabEventHandler.bind(this))
    this._registerBroadcastChannelAPI()
  }

  _subscribersPriorityComparator (s1, s2) {
    return s2.priority - s1.priority
  }

  _multiTabEventHandler (storageEvent) {
    if (storageEvent.key !== PUB_SUB_EXCHANGE_GATEWAY) return
    const { target, event } = this.storage.get(PUB_SUB_EXCHANGE_GATEWAY)

    this.emit(target, event)
  }

  /**
   * Общение между вкладками
   * @private
   */
  _registerBroadcastChannelAPI () {
    if (this.broadcastChannel) {
      this.broadcastChannel.close()
    }

    try {
      this.broadcastChannel = new BroadcastChannel('epic-pub-sub')
      this.broadcastChannel.onmessage = (messageEvent) => {
        this.emit(`broadcast:${messageEvent?.data?.target}`, messageEvent?.data?.event)
      }
    } catch (e) {
      console.warn(e)
      const error = () => { console.error('BroadcastChannel is not supported') }
      this.broadcastChannel = {
        onmessage: error,
        close: error,
        postMessage: error,
      }
    }
  }

  subscribe (target, callback, priority = 0) {
    if (!this.subscribersTable.has(target)) {
      this.subscribersTable.set(target, [])
    }

    const subscribers = this.subscribersTable.get(target)
    subscribers.unshift({ callback, priority })
    subscribers.sort(this._subscribersPriorityComparator)
  }

  unsubscribe (target, callback) {
    if (!this.subscribersAvailable(target)) return

    const subscribers = this
      .subscribersTable
      .get(target)
      .filter((subscriber) => subscriber.callback !== callback)

    this.subscribersTable.set(target, subscribers)
  }

  unsubscribeAll (target) {
    this.reset(target)
  }

  emit (target, event = null, multiTabEvent = false) {
    if (useDebug) {
      console.debug(...DEBUG_MESSAGE_TEMPLATE, 'target:', target, 'event:', event)
    }

    if (multiTabEvent) {
      this.broadcastChannel.postMessage({ target, event })
    }

    if (!this.subscribersAvailable(target)) {
      console.warn(`There are no subscribers for this target '${target}'`)

      return
    }

    this.subscribersTable.get(target).map((subscriber) => subscriber.callback(event))

    if (!multiTabEvent) { return }

    this.storage.set(PUB_SUB_EXCHANGE_GATEWAY, {
      random: Math.random(),
      target,
      event,
    })
  }

  emitAsync (target, event, multiTabEvent = false, delay = 0) {
    window.setTimeout(() => this.emit(target, event, multiTabEvent), delay)
  }

  subscribersAvailable (target) {
    return this.subscribersTable.has(target) && this.subscribersTable.get(target).length > 0
  }

  reset (target) {
    this.subscribersTable.delete(target)
  }

  resetScope (scope) {
    Array
      .from(this.subscribersTable.keys())
      .filter((key) => key.startsWith(scope))
      .forEach((key) => this.subscribersTable.delete(key))
  }

  resetAll () {
    this.subscribersTable = new Map()
  }
}

const instance = new EpicPubSub()

export default instance
