export const MSmsTemplateAdapter = {
  toClient (response) {
    // 🩼🩼🩼 для нормальной замены переменных в тексте редактора шаблонов смс
    const replaceBottomLineName = (str) => str.replaceAll('_name', 'Name')

    response.notifyTemplate = replaceBottomLineName(response?.notifyTemplate || '')
    response.remindTemplate = replaceBottomLineName(response?.remindTemplate || '')
    response.appointmentConfirmationTemplate = replaceBottomLineName(response?.appointmentConfirmationTemplate || '')
    response.clientsBirthdayTemplate = replaceBottomLineName(response?.clientsBirthdayTemplate || '')
    response.servicesStatusTemplate = replaceBottomLineName(response?.servicesStatusTemplate || '')
    response.clientsFeedbackTemplate = replaceBottomLineName(response?.clientsFeedbackTemplate || '')

    return response
  },
}
