<template>
  <div
    ref="si"
    class="si"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Si',
  props: {
    showTooltips: {
      default: false,
      type: Boolean,
    },
  },

  updated () {
    if (this.showTooltips && this.$refs.si) {
      Utils.updateTooltips(this.$refs.si)
    }
  },
}
</script>
