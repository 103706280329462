import { medDocumentTypeEndpoint } from '@/api_entities/egisz/catalogs/med_document_types/med_document_type'
import { medDocumentEndpoint } from '@/api_entities/egisz/med_documents/med_document_endpoint'
import { cloneDeep } from 'lodash'
import { egiszErrorsNotify, reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'

export const actions = {
  // по идее это должна быть мутация, однако нужен был rootState
  SET_ONLY_CURRENT_USER ({ state, rootState }, isChecked) {
    state.filtersBase.filters.author_id = isChecked
      ? rootState.appConf.current_user.id
      : null
  },

  getMedDocuments ({ commit, state, rootGetters, getters }, { filters, standAlone }) {
    const tempFilters = cloneDeep(filters || getters['filtersBase/vxGetFilters'])

    if (standAlone) {
      tempFilters.without_medical_cases = true
    }

    return medDocumentEndpoint.getAll(tempFilters)
      .then(({ data, total_pages: totalPages }) => {
        commit('SET_MED_DOCUMENTS', data)
        commit('filtersBase/vxSetTotalPages', totalPages)
      })
  },

  deleteMedDocument ({ state, commit }, medDocumentId) {
    commit('SET_MED_DOCUMENT_LOADER_STATE', true)

    return medDocumentEndpoint.destroy(medDocumentId)
      .then(() => {
        commit('DELETE_MED_DOCUMENT', medDocumentId)
        commit('SET_SELECTED_MED_DOCUMENT')

        if (state.activeEntryEmd && state.activeEntryEmd.id === medDocumentId) {
          commit('SET_ACTIVE_ENTRY_EMD', null)
        }
      })
      .catch(Utils.reportError(
        'store:egiszEmd:deleteMedDocument()',
        reportErrorText('delete_message', 'emd')
      ))
      .finally(() => {
        commit('SET_MED_DOCUMENT_LOADER_STATE', false)
      })
  },

  signMedDocument ({ commit }, { medDocumentId, payload }) {
    return medDocumentEndpoint.sign(medDocumentId, payload)
      .then(() => {
        commit('vxUpdateSignStatus', { medDocumentId, holderScope: payload.holder_scope })
        Notificator.success(t('egisz.messages.success.document_signed'))
      })
      .catch(Utils.reportError(
        'store:egiszEmd:signMedDocument()',
        t('utils_report_error.sign_message'))
      )
  },

  registerMedDocument ({ commit, rootGetters }, { medDocumentId, clientId, typeId }) {
    commit('SET_MED_DOCUMENT_LOADER_STATE', true)

    const data = {
      medical_document_id: medDocumentId,
      client_id: clientId,
      clinic_id: rootGetters.GET_APP_CONF_CURRENT_CLINIC_ID,
      type_id: typeId,
    }

    return medDocumentEndpoint.addMedDocument(data)
      .then(() => {
        commit('REGISTER_MED_DOCUMENT', medDocumentId)
        commit('SET_SELECTED_MED_DOCUMENT')
        Notificator.success(t('egisz.messages.success.document_registered'))
      })
      .catch((err) => {
        commit('REGISTER_MED_DOCUMENT_FAILED', medDocumentId)
        egiszErrorsNotify('store:egiszEmd:registerMedDocument()')(err)
      })
      .finally(() => {
        commit('SET_MED_DOCUMENT_LOADER_STATE', false)
      })
  },

  loadMedDocumentTypes ({ commit, state }) {
    if (state.emdTypes) { return }
    commit('SET_MED_DOCUMENT_TYPES', [])

    return medDocumentTypeEndpoint.getAll()
      .then((data) => commit('SET_MED_DOCUMENT_TYPES', data))
      .catch(Utils.reportError(
        'store:egiszEmd:loadMedDocumentTypes()',
        reportErrorText('catalogs_message', 'medical_document_types')
      ))
  },
}
