import { orUndefined } from '@/_medods_standart_library/msl'
import {
  PersonalCheckupDiagnosesPersonalListItem,
} from '@/vue_apps/CheckupsModule/classes/PersonalCheckupDiagnosesPersonal/PersonalCheckupDiagnosesPersonalListItem'

export const getDiagnosesPersonal = (diagnoses: PersonalCheckupDiagnosesPersonalListItem[]) => diagnoses.map((d) => ({
  id: orUndefined(d.dbId),
  diagnosisId: d.diagnosisId,
  diagnosisType: d.diagnosisType,
  diagnosisDate: Utils.getBaseFormattedDate(d.diagnosisDate, Utils.momentDateFormat),
  _destroy: orUndefined(d._destroy),
}))

export const personalCheckupDiagnosesPersonalAdapter = {
  toServer ({ ownerId, diagnosesPersonal }) {
    return {
      personalCheckup: {
        id: ownerId,
        diagnosesPersonalAttributes: getDiagnosesPersonal(diagnosesPersonal),
      },
    }
  },
}
