import { getters } from '@/vue_components/store/modules/medCases/medCasesGetters'
import { mutations } from '@/vue_components/store/modules/medCases/medCasesMutations'
import { actions } from '@/vue_components/store/modules/medCases/medCasesActions'
import { filtersBase } from '@/vue_components/store/modules/filters_base'

export const medCasesState = {
  namespaced: true,
  state: () => ({
    catalogs: {
      isLoaded: false,
      doctors: [],
      specialties: [],
    },

    caseCatalogs: {
      isLoaded: false,
      relativeTypes: [],
      paymentTypes: [],
      doctors: [],
      visitTypes: [],
      caseTypes: [],
      confidentialityLevels: [],

      diseaseResults: [],

      stepTypes: [],
      stepPlaces: [],
      stepPurposes: [],

      medRecordDiagnosisTypes: [],
    },
  }),

  getters,
  mutations,
  actions,

  modules: {
    filtersBase,
  },
}
