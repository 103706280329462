class Storage {
  set (key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  get (key) {
    try {
      return JSON.parse(window.localStorage.getItem(key))
    } catch (_) {
      return null
    }
  }

  remove (key) {
    window.localStorage.removeItem(key)
  }
}

const instance = new Storage()

export default instance
