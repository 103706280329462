const netrikaErrorFields = t('egisz.netrika_error_fields')
const getText = (fieldName) => {
  const text = netrikaErrorFields[fieldName]

  return text || fieldName
}

export const extractDataByNetrikaFields = (netrikaErrorsObject) => {
  if (typeof netrikaErrorsObject !== 'object') { return }

  const messages = Object.entries(netrikaErrorsObject).reduce((acc, item) => {
    acc.push(`${getText(item[0])}: ${item[1]}`)

    return acc
  }, [])

  return {
    title: t('egisz.messages.errors.request.netrika_errors'),
    message: messages.join('; '),
  }
}
