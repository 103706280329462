import { MMessagesConfigurationPresenter } from '@/_api/Deliveries/MMessagesConfigurationPresenter'
import { MSmsConfigurationsPresenter } from '@/_api/Deliveries/MSmsConfigurationsPresenter'
import { whatsappConfigurationsStore } from '@/api_entities/messages/whatsappConfigurationsStore'
import { MDeliveryPresenter } from '@/_api/Deliveries/MDeliveryPresenter'
import { flashCallsConfigurationsStore } from '@/api_entities/messages/flashCallsConfigurationStore'

export const messagesStore = {
  namespaced: true,
  modules: {
    whatsappConfigurationsStore,
    flashCallsConfigurationsStore,
  },

  state: () => ({
    messagesConfigurations: {},
    smsConfigurations: {},
    messagesPriorities: [],
    smsBalance: {},
  }),

  getters: {
    vxGetMessagesConfigurations: (state) => state.messagesConfigurations || {},
    vxGetSmsConfigurations: (state) => state.smsConfigurations || {},
    vxGetMessagesPriorities: (state) => state.messagesPriorities || [],

    vxGetSendingTimeLimit: (state) => ({
      sendFromTime: state.messagesConfigurations.sendFromTime || '',
      sendToTime: state.messagesConfigurations.sendToTime || '',
    }),

    vxGetAppointmentNotifySwitches: (state) => ({
      notify: state.messagesConfigurations.notify,
      notifyDefault: state.messagesConfigurations.notifyDefault,
    }),

    vxGetAppointmentRemindSwitches: (state) => ({
      remind: state.messagesConfigurations.remind,
      remindDefault: state.messagesConfigurations.remindDefault,
    }),

    vxGetAppointmentConfirmationSwitches: (state) => ({
      appointmentConfirmation: state.messagesConfigurations.appointmentConfirmation,
      appointmentConfirmationDefault: state.messagesConfigurations.appointmentConfirmationDefault,
    }),

    vxGetAppointmentNotifySmsTemplate: (state) => state.messagesConfigurations.notifyTemplate,

    vxGetAppointmentRemindSmsTemplate: (state) => state.messagesConfigurations.remindTemplate,

    vxGetAppointmentConfirmationSmsTemplate: (state) => state.messagesConfigurations.appointmentConfirmationTemplate,

    vxGetClientsBirthdaySwitch: (state) => state.messagesConfigurations.clientsBirthday,

    vxGetClientsBirthdaySmsTemplate: (state) => state.messagesConfigurations.clientsBirthdayTemplate,

    vxGetServicesStatusSwitch: (state) => state.messagesConfigurations.servicesStatus,

    vxGetServicesStatusSmsTemplate: (state) => state.messagesConfigurations.servicesStatusTemplate,

    vxGetServicesStatusTriggerType: (state) => state.messagesConfigurations.servicesStatusTriggerType,

    vxGetClientWidgetMessagesLimit: (state) => state.messagesConfigurations.clientWidgetLimitPerHour,

    vxGetClientsFeedbackSwitch: (state) => state.messagesConfigurations.clientsFeedback,

    vxGetClientsFeedbackSmsTemplate: (state) => state.messagesConfigurations.clientsFeedbackTemplate,

    vxGetClientsFeedbackSendingDelay: (state) => state.messagesConfigurations.clientsFeedbackSendingDelay,

    vxGetClientsFeedbackSendingFrequency: (state) => state.messagesConfigurations.clientsFeedbackSendingFrequency,

    vxGetDenySeveralMessages: (state) => state.messagesConfigurations.clientsFeedbackDenySeveralMessages,

    // AggregatorFeedbackSettings
    vxGetAggregatorFeedbackSwitch: (state) => state.messagesConfigurations.aggregatorFeedback,

    vxGetAggregatorFeedbackRequiredScore: (state) => state.messagesConfigurations.aggregatorFeedbackRequiredScore,

    vxGetAggregatorFeedbackRequiredTextLength: (state) => state.messagesConfigurations.aggregatorFeedbackRequiredTextLength,

    vxGetAggregatorFeedbackSendingFrequency: (state) => state.messagesConfigurations.aggregatorFeedbackSendingFrequency,

    vxGetAggregatorFeedbackTemplate: (state) => state.messagesConfigurations.aggregatorFeedbackTemplate,

    // CommonSmsSettings
    vxGetSmsSettingsLogin: (state) => state.smsConfigurations.login,

    vxGetSmsSettingsPassword: (state) => state.smsConfigurations.password,

    vxGetSmsBalance: (state) => state.smsBalance,

    vxGetBalanceNotification: (state) => state.smsConfigurations.balanceNotification,

    vxGetBalanceNotificationValue: (state) => state.smsConfigurations.balanceNotificationValue,

    vxGetBalanceNotifyUsers: (state) => state.smsConfigurations.balanceNotifyUsers,

    vxGetSmsSendFromTime: (state) => state.smsConfigurations.sendFromTime,

    vxGetSmsSendToTime: (state) => state.smsConfigurations.sendToTime,

    vxGetAppointmentRemindAfter: (state) => state.messagesConfigurations.remindAfterHours,

    vxGetAppointmentSendAfter: (state) => state.messagesConfigurations.appointmentConfirmationSendAfterHours,

    vxGetClientWidgetWaitingTime: (state) => state.messagesConfigurations.clientWidgetWaitingTime,
  },

  mutations: {
    vxSetMessagesConfigurations (state, configurations) {
      state.messagesConfigurations = configurations
    },

    vxSetSmsConfigurations (state, configurations) {
      state.smsConfigurations = configurations
    },

    vxSetMessagesConfigurationsPriorities (state, configurations) {
      state.messagesPriorities = configurations
    },

    vxSetSendingTimeLimit (state, { sendFromTime, sendToTime }) {
      state.messagesConfigurations.sendFromTime = sendFromTime
      state.messagesConfigurations.sendToTime = sendToTime
    },

    vxSetAppointmentNotifySwitchesNotify (state, value) {
      state.messagesConfigurations.notify = value
    },

    vxSetAppointmentNotifySwitchesNotifyDefault (state, value) {
      state.messagesConfigurations.notifyDefault = value
    },

    vxSetAppointmentRemindSwitchesRemind (state, value) {
      state.messagesConfigurations.remind = value
    },

    vxSetAppointmentRemindSwitchesRemindDefault (state, value) {
      state.messagesConfigurations.remindDefault = value
    },

    vxSetAppointmentConfirmationSwitchesConfirmation (state, value) {
      state.messagesConfigurations.appointmentConfirmation = value
    },

    vxSetAppointmentConfirmationSwitchesConfirmationDefault (state, value) {
      state.messagesConfigurations.appointmentConfirmationDefault = value
    },

    vxSetAppointmentNotifySmsTemplate (state, value) {
      state.messagesConfigurations.notifyTemplate = value
    },

    vxSetAppointmentRemindSmsTemplate (state, value) {
      state.messagesConfigurations.remindTemplate = value
    },

    vxSetAppointmentConfirmationSmsTemplate (state, value) {
      state.messagesConfigurations.appointmentConfirmationTemplate = value
    },

    vxSetClientsBirthdaySmsTemplate (state, value) {
      state.messagesConfigurations.clientsBirthdayTemplate = value
    },

    vxSetClientsBirthdaySwitch (state, value) {
      state.messagesConfigurations.clientsBirthday = value
    },

    vxSetServicesStatusSwitch (state, value) {
      state.messagesConfigurations.servicesStatus = value
    },

    vxSetServicesStatusSmsTemplate (state, value) {
      state.messagesConfigurations.servicesStatusTemplate = value
    },

    vxSetServicesStatusTriggerType (state, value) {
      state.messagesConfigurations.servicesStatusTriggerType = value?.id
    },

    vxSetClientWidgetLimitPerHour (state, value) {
      state.messagesConfigurations.clientWidgetLimitPerHour = value
    },

    vxSetClientsFeedbackSwitch (state, value) {
      state.messagesConfigurations.clientsFeedback = value
    },

    vxSetClientsFeedbackSmsTemplate (state, value) {
      state.messagesConfigurations.clientsFeedbackTemplate = value
    },

    vxSetClientsFeedbackSendingDelay (state, value) {
      state.messagesConfigurations.clientsFeedbackSendingDelay = value
    },

    vxSetDenySeveralMessages (state, value) {
      state.messagesConfigurations.clientsFeedbackDenySeveralMessages = value
    },

    vxSetClientsFeedbackSendingFrequency (state, value) {
      state.messagesConfigurations.clientsFeedbackSendingFrequency = value
    },

    // AggregatorFeedbackSettings
    vxSetAggregatorFeedbackSwitch (state, value) {
      state.messagesConfigurations.aggregatorFeedback = value
    },

    vxSetAggregatorFeedbackRequiredScore (state, value) {
      state.messagesConfigurations.aggregatorFeedbackRequiredScore = value
    },

    vxSetAggregatorFeedbackRequiredTextLength (state, value) {
      state.messagesConfigurations.aggregatorFeedbackRequiredTextLength = value
    },

    vxSetAggregatorFeedbackSendingFrequency (state, value) {
      state.messagesConfigurations.aggregatorFeedbackSendingFrequency = value
    },

    vxSetAggregatorFeedbackTemplate (state, value) {
      state.messagesConfigurations.aggregatorFeedbackTemplate = value
    },

    // CommonSmsSettings
    vxSetSmsSettingsLogin (state, value) {
      state.smsConfigurations.login = value
    },

    vxSetSmsSettingsPassword (state, value) {
      state.smsConfigurations.password = value
    },

    vxSetSmsBalance (state, value) {
      state.smsBalance = value
    },

    vxSetBalanceNotification (state, value) {
      state.smsConfigurations.balanceNotification = value
    },

    vxSetBalanceNotificationValue (state, value) {
      state.smsConfigurations.balanceNotificationValue = value
    },

    vxSetBalanceNotifyUsers (state, value) {
      state.smsConfigurations.balanceNotifyUsers = value
    },

    vxSetSmsSendFromTime (state, value) {
      state.smsConfigurations.sendFromTime = value
    },

    vxSetSmsSendToTime (state, value) {
      state.smsConfigurations.sendToTime = value
    },

    vxSetAppointmentRemindAfter (state, value) {
      state.messagesConfigurations.remindAfterHours = value
    },

    vxSetAppointmentSendAfter (state, value) {
      state.messagesConfigurations.appointmentConfirmationSendAfterHours = value
    },

    vxSetClientWidgetWaitingTime (state, value) {
      state.messagesConfigurations.clientWidgetWaitingTime = value
    },
  },

  actions: {
    async vxFetchMessagesConfigurations ({ commit }) {
      const presenter = new MMessagesConfigurationPresenter()
      const configurations = await presenter.fetch()
      if (configurations?.errors) { return }

      commit('vxSetMessagesConfigurations', configurations)
    },

    async vxFetchSmsConfigurations ({ commit }) {
      const presenter = new MSmsConfigurationsPresenter()
      const configurations = await presenter.fetchSmsConfigurations()
      if (configurations?.errors) { return }

      commit('vxSetSmsConfigurations', configurations)
    },

    async vxFetchMessagesPriorities ({ commit }, priorityType) {
      commit('vxSetMessagesConfigurationsPriorities', [])

      const presenter = new MMessagesConfigurationPresenter()
      const configurations = await presenter.fetchPriorities(priorityType)
      if (configurations?.errors) { return }

      commit('vxSetMessagesConfigurationsPriorities', configurations)
    },

    async vxFetchSmsBalance ({ commit }, payload) {
      const balanceValue = await new MDeliveryPresenter().fetchSmsBalance(payload)

      commit('vxSetSmsBalance', balanceValue)
    },
  },
}
