import { get, pick } from 'lodash/object'
import {
  CABINET_SCHEDULE,
  DOCTOR_SCHEDULE,
  DOCTOR_SCHEDULES_SIDEBAR_STORAGE,
  DOCTOR_SCHEDULES_STORAGE,
  SIDEBAR_CALENDAR,
} from '@/vue_components/doctor_schedules/consts.js'
import {
  fetchAppointmentById,
  fetchAttractionSourcesList,
  scheduleInitData,
} from '@/vue_components/doctor_schedules/rest.js'
import {
  MODE_CLIENT_INSERT,
  MODE_MOVE,
  MODE_PHONE_INSERT,
  PASTE_MODE_ARRAY,
} from '@/plugins/schedule/scheduleCommon/const'
import { getCorrectDoctorScheduleClinicId } from '@/vue_components/store/modules/doctor_schedules/helpers'
import { doctorPresenter } from '@/api_entities/doctors/doctorPresenter'

const state = {
  currentClinicId: null,
  usersList: [],
  currentUserClinics: [],
  schedule: {
    date: null,
    duration: null,
    daysCount: null,
    mode: null,
    insertParams: getDefaultInsertParams(),
    activeAppointment: null,
    appointmentFromCall: null,
    waitingItemFromCall: null,
  },

  scheduleData: {
    appointmentDurationsItems: [],
    gridSteps: [],
    appointmentSources: [],
    appointmentSystemSource: {},
    appointmentTypes: [],
    appointmentTypeIdDefault: null,
    attractionSources: [],
    contextReady: false,
    sendConfirmationMessage: false,
    smsNotifyDefault: false,
    smsRemindDefault: false,
  },

  sidebar: {
    hideUsersWithoutSchedule: false,
    hideCabinetsWithoutSchedule: false,
    hideAppointmentsSchedule: false,
    showOnlyWorkingDays: false,
    showOnlyNewPatients: false,
    filtrationAppointmentsStatuses: [],
    filtrationAppointmentsTypes: [],
    filtrationClientGroups: [],
    clinicsWorkload: {},
  },

  ui: {
    activeSchedule: null,
    showLeftPanel: false,
    activeSidebar: null,
    showRightPanel: false,
    showCancelButton: false,
    loading: false,
    loadingAppointments: false,
    loadingRight: false,
  },
}

const getters = {
  GET_CURRENT_USER_CLINICS_LIST (state) {
    return state.currentUserClinics
      .map((clinic) => ({ id: clinic.id, title: clinic.title }))
  },

  GET_SCHEDULE_CURRENT_CLINIC_ID (state) {
    return state.currentClinicId
  },

  GET_LOADING_DOCTOR_SCHEDULE (state) {
    return state.ui.loading
  },

  GET_LOADING_APPOINTMENTS (state) {
    return state.ui.loadingAppointments
  },

  GET_LOADING_RIGHT_SIDEBAR (state) {
    return state.ui.loadingRight
  },

  GET_CONTEXT_READY (state) {
    return state.scheduleData.contextReady
  },

  GET_MODE_GRID_SCHEDULE (state) {
    return state.schedule.mode
  },

  GET_ACTIVE_APPOINTMENT (state) {
    return state.schedule.activeAppointment
  },

  GET_INSERT_PARAMS_APPOINTMENT (state) {
    return state.schedule.insertParams
  },

  GET_APPOINTMENT_DURATIONS (state) {
    return state.scheduleData.appointmentDurationsItems
  },

  GET_APPOINTMENT_DURATIONS_ITEMS (state, getters) {
    return getters.GET_APPOINTMENT_DURATIONS.map((duration) => duration.duration)
  },

  GET_GRID_STEPS (state) {
    return state.scheduleData.gridSteps
  },

  GET_APPOINTMENT_SOURCES (state) {
    return state.scheduleData.appointmentSources
  },

  GET_APPOINTMENT_SYSTEM_SOURCE (state) {
    return state.scheduleData.appointmentSystemSource
  },

  GET_APPOINTMENT_TYPES (state) {
    return state.scheduleData.appointmentTypes
  },

  GET_APPOINTMENT_TYPE_ID_DEFAULT (state) {
    return state.scheduleData.appointmentTypeIdDefault
  },

  GET_ATTRACTION_SOURCES (state) {
    return state.scheduleData.attractionSources || []
  },

  GET_ACTIVE_SCHEDULE (state) {
    return state.ui.activeSchedule
  },

  IS_ACTIVE_SCHEDULE_DOCTORS (state, getters) {
    return getters.GET_ACTIVE_SCHEDULE === DOCTOR_SCHEDULE
  },

  IS_ACTIVE_SCHEDULE_CABINETS (state, getters) {
    return getters.GET_ACTIVE_SCHEDULE === CABINET_SCHEDULE
  },

  GET_SHOW_LEFT_PANEL_STATE (state) {
    return state.ui.showLeftPanel
  },

  GET_ACTIVE_SIDEBAR (state) {
    return state.ui.activeSidebar
  },

  GET_SHOW_RIGHT_PANEL_STATE (state) {
    return state.ui.showRightPanel
  },

  GET_SHOW_CANCEL_BUTTON_STATE (state) {
    return PASTE_MODE_ARRAY.includes(state.schedule.mode)
  },

  GET_SCHEDULE_DATE (state) {
    return state.schedule.date
  },

  GET_SCHEDULE_DURATION (state) {
    return state.schedule.duration
  },

  GET_SCHEDULE_DAYS_COUNT (state) {
    return state.schedule.daysCount
  },

  GET_SIDEBAR_HIDE_USERS_WITHOUT_SCHEDULE_STATE (state) {
    return state.sidebar.hideUsersWithoutSchedule
  },

  GET_SIDEBAR_HIDE_CABINETS_WITHOUT_SCHEDULE_STATE (state) {
    return state.sidebar.hideCabinetsWithoutSchedule
  },

  GET_SIDEBAR_HIDE_APPOINTMENTS_SCHEDULE_STATE (state) {
    return state.sidebar.hideAppointmentsSchedule
  },

  GET_SIDEBAR_SHOW_ONLY_WORKING_DAYS_STATE (state) {
    return state.sidebar.showOnlyWorkingDays
  },

  GET_SIDEBAR_SHOW_ONLY_NEW_PATIENTS_STATE (state) {
    return state.sidebar.showOnlyNewPatients
  },

  GET_SIDEBAR_FILTRATION_APPOINTMENTS_STATUSES (state) {
    return state.sidebar.filtrationAppointmentsStatuses
  },

  GET_SIDEBAR_FILTRATION_APPOINTMENTS_TYPES () {
    return state.sidebar.filtrationAppointmentsTypes
  },

  GET_SIDEBAR_FILTRATION_CLIENT_GROUPS () {
    return state.sidebar.filtrationClientGroups
  },

  GET_SMS_CONFIRMATION_DEFAULT (state) {
    return state.scheduleData.sendConfirmationMessage
  },

  GET_SMS_NOTIFY_DEFAULT (state) {
    return state.scheduleData.smsNotifyDefault
  },

  GET_SMS_REMIND_DEFAULT (state) {
    return state.scheduleData.smsRemindDefault
  },

  GET_UIS_CONFIRMATION_DEFAULT (state) {
    return state.scheduleData.uisConfirmDefault
  },

  GET_UIS_NOTIFY_DEFAULT (state) {
    return state.scheduleData.uisNotifyDefault
  },

  GET_UIS_REMIND_DEFAULT (state) {
    return state.scheduleData.uisRemindDefault
  },

  GET_DEFAULT_ATTRACTION_SOURCE_ID (state) {
    if (gon.specific.do_not_show_default_attraction_source) { return null }

    return state.scheduleData.appointmentSystemSource.default_attraction_source_id
  },

  GET_APPOINTMENT_FROM_CALL (state) {
    return state.schedule.appointmentFromCall
  },

  GET_WAITING_ITEM_FROM_CALL (state) {
    return state.schedule.waitingItemFromCall
  },

  getUserById (state) {
    /**
     * @param {number} id
     * @param {string[]} [fields]
     */
    return (id, fields) => {
      const user = state.usersList.find((user) => user.id === id)

      if (user && fields) {
        return pick(user, fields)
      }

      return user
    }
  },

  vxGetClinicsWorkload: (state) => state.sidebar.clinicsWorkload,
}

const mutations = {
  SET_CURRENT_USER_CLINICS_LIST (state, clinics) {
    state.currentUserClinics = clinics
  },

  SET_SCHEDULE_CURRENT_CLINIC_ID (state, clinicId) {
    state.currentClinicId = clinicId
  },

  SET_LOADING_DOCTOR_SCHEDULE (state, flag) {
    state.ui.loading = flag
  },

  SET_LOADING_APPOINTMENTS (state, flag) {
    state.ui.loadingAppointments = flag
  },

  SET_LOADING_RIGHT_SIDEBAR (state, flag) {
    state.ui.loadingRight = flag
  },

  SET_CONTEXT_READY (state, flag) {
    state.scheduleData.contextReady = flag
  },

  SET_MODE_GRID_SCHEDULE (state, mode) {
    state.schedule.mode = mode
  },

  SET_ACTIVE_APPOINTMENT (state, appointment) {
    state.schedule.activeAppointment = appointment
  },

  SET_INSERT_PARAMS_APPOINTMENT (state, params) {
    state.schedule.insertParams = params
  },

  SET_APPOINTMENT_DURATIONS_ITEMS (state, flag) {
    state.scheduleData.appointmentDurationsItems = flag
  },

  SET_GRID_STEPS (state, steps) {
    state.scheduleData.gridSteps = steps
  },

  SET_APPOINTMENT_SOURCES (state, flag) {
    state.scheduleData.appointmentSources = flag
  },

  SET_APPOINTMENT_SYSTEM_SOURCE (state, flag) {
    state.scheduleData.appointmentSystemSource = flag
  },

  SET_APPOINTMENT_TYPES (state, flag) {
    state.scheduleData.appointmentTypes = flag
  },

  SET_APPOINTMENT_TYPE_ID_DEFAULT (state, flag) {
    state.scheduleData.appointmentTypeIdDefault = flag
  },

  SET_ATTRACTION_SOURCES (state, sources) {
    state.scheduleData.attractionSources = sources
  },

  SET_ACTIVE_SCHEDULE (state, schedule) {
    state.ui.activeSchedule = schedule
  },

  SET_SHOW_LEFT_PANEL_STATE (state, show) {
    state.ui.showLeftPanel = show
  },

  SET_ACTIVE_SIDEBAR (state, sidebar) {
    state.ui.activeSidebar = sidebar
  },

  SET_SHOW_RIGHT_PANEL_STATE (state, show) {
    state.ui.showRightPanel = show
  },

  SET_SHOW_CANCEL_BUTTON_STATE (state, flag) {
    state.ui.showCancelButton = flag
  },

  SET_SCHEDULE_DATE (state, date) {
    state.schedule.date = date
  },

  SET_SCHEDULE_DURATION (state, step) {
    state.schedule.duration = step
  },

  SET_SCHEDULE_DAYS_COUNT (state, count) {
    state.schedule.daysCount = count
  },

  SET_SIDEBAR_HIDE_USERS_WITHOUT_SCHEDULE_STATE (state, flag) {
    state.sidebar.hideUsersWithoutSchedule = flag
  },

  SET_SIDEBAR_HIDE_CABINETS_WITHOUT_SCHEDULE_STATE (state, flag) {
    state.sidebar.hideCabinetsWithoutSchedule = flag
  },

  SET_SIDEBAR_HIDE_APPOINTMENTS_SCHEDULE_STATE (state, flag) {
    state.sidebar.hideAppointmentsSchedule = flag
  },

  SET_SIDEBAR_SHOW_ONLY_WORKING_DAYS_STATE (state, flag) {
    state.sidebar.showOnlyWorkingDays = flag
  },

  SET_SIDEBAR_SHOW_ONLY_NEW_PATIENTS_STATE (state, flag) {
    state.sidebar.showOnlyNewPatients = flag
  },

  SET_SIDEBAR_FILTRATION_APPOINTMENTS_STATUSES (state, flag) {
    state.sidebar.filtrationAppointmentsStatuses = flag
  },

  SET_SIDEBAR_FILTRATION_APPOINTMENTS_TYPES (state, flag) {
    state.sidebar.filtrationAppointmentsTypes = flag
  },

  SET_SIDEBAR_FILTRATION_CLIENT_GROUPS (state, flag) {
    state.sidebar.filtrationClientGroups = flag
  },

  SET_SMS_CONFIRMATION_DEFAULT (state, flag) {
    state.scheduleData.sendConfirmationMessage = flag
  },

  SET_SMS_NOTIFY_DEFAULT (state, flag) {
    state.scheduleData.smsNotifyDefault = flag
  },

  SET_SMS_REMIND_DEFAULT (state, flag) {
    state.scheduleData.smsRemindDefault = flag
  },

  SET_UIS_CONFIRM_DEFAULT (state, flag) {
    state.scheduleData.uisConfirmDefault = flag
  },

  SET_UIS_NOTIFY_DEFAULT (state, flag) {
    state.scheduleData.uisNotifyDefault = flag
  },

  SET_UIS_REMIND_DEFAULT (state, flag) {
    state.scheduleData.uisRemindDefault = flag
  },

  SET_SCHEDULE_USERS_LIST (state, users) {
    state.usersList = users
  },

  SET_APPOINTMENT_FROM_CALL (state, appointment) {
    state.schedule.appointmentFromCall = appointment
  },

  SET_WAITING_ITEM_FROM_CALL (state, client) {
    state.schedule.waitingItemFromCall = client
  },

  vxSetClinicsWorkload (state, payload) {
    state.sidebar.clinicsWorkload = payload
  },
}

const actions = {
  initialScheduleStore ({ state, getters, dispatch }) {
    const storageSchedule = JSON.parse(localStorage.getItem(DOCTOR_SCHEDULES_STORAGE)) || {}
    const storageSidebar = JSON.parse(localStorage.getItem(DOCTOR_SCHEDULES_SIDEBAR_STORAGE)) || {}

    state.currentUserClinics = gon.application.current_user_clinics
    state.schedule.date = extractScheduleDate(get(storageSchedule, 'scheduleDate'), getters.GET_LOCALIZATION_DATE_FORMAT)

    dispatch('setScheduleCurrentClinicId', getCorrectDoctorScheduleClinicId(storageSchedule.currentClinicId))

    state.schedule.duration = get(storageSchedule, 'scheduleDuration', 5)
    state.schedule.daysCount = get(storageSchedule, 'scheduleDaysCount', 1)

    state.sidebar.hideUsersWithoutSchedule = get(storageSidebar, 'hideUsersWithoutSchedule', false)
    state.sidebar.hideCabinetsWithoutSchedule = get(storageSidebar, 'hideCabinetsWithoutSchedule', false)
    state.sidebar.hideAppointmentsSchedule = get(storageSidebar, 'hideAppointmentsSchedule', false)
    state.sidebar.showOnlyWorkingDays = get(storageSidebar, 'showOnlyWorkingDays', false)
    state.sidebar.showOnlyNewPatients = get(storageSidebar, 'showOnlyNewPatients', false)
    state.sidebar.filtrationAppointmentsStatuses = get(storageSidebar, 'filtrationAppointmentsStatuses', [])
    state.sidebar.filtrationAppointmentsTypes = get(storageSidebar, 'filtrationAppointmentsTypes', [])
    state.sidebar.filtrationClientGroups = get(storageSidebar, 'filtrationClientGroups', [])

    state.ui.activeSchedule = get(storageSchedule, 'activeSchedule', DOCTOR_SCHEDULE)
    state.ui.showLeftPanel = get(storageSchedule, 'showLeftPanel', false)
    state.ui.activeSidebar = get(storageSchedule, 'activeSidebar', SIDEBAR_CALENDAR)
    state.ui.showRightPanel = get(storageSchedule, 'showRightPanel', false)
  },

  changeScheduleClinic ({ state }) {
    const isSyncChangeClinic = gon.application.synchronous_change_current_clinic
    const gonCurrentClinicId = gon.application.current_clinic.id
    if (!isSyncChangeClinic || gonCurrentClinicId === state.currentClinicId) { return }

    return doctorPresenter.selectClinic(gon.application.current_user.id, state.currentClinicId)
  },

  /**
   * начинает перемещение записи, указанной в объекте appointment
   * или по указанному id.
   *
   * @param commit
   * @param {number} [appointmentId]
   * @param {number} [mode]
   * @param {object} [appointment]
   */
  startMovingAppointment ({ commit }, {
    appointmentId,
    mode = MODE_MOVE,
    appointment,
  }) {
    if (appointment) {
      commit('SET_MODE_GRID_SCHEDULE', mode)
      commit('SET_ACTIVE_APPOINTMENT', appointment)
    } else {
      commit('SET_LOADING_DOCTOR_SCHEDULE', true)
      fetchAppointmentById(appointmentId)
        .then((appointment) => {
          commit('SET_LOADING_DOCTOR_SCHEDULE', false)
          commit('SET_MODE_GRID_SCHEDULE', mode)
          commit('SET_ACTIVE_APPOINTMENT', appointment)
        })
    }
  },

  setScheduleCurrentClinicId ({ commit }, currentClinicId) {
    commit('SET_SCHEDULE_CURRENT_CLINIC_ID', currentClinicId)
    updateStorage(DOCTOR_SCHEDULES_STORAGE, { currentClinicId })
  },

  loadInitialDataSchedule ({ commit }, clinicId) {
    commit('SET_LOADING_DOCTOR_SCHEDULE', true)

    scheduleInitData({ clinic_id: clinicId })
      .then((response) => {
        commit('SET_APPOINTMENT_DURATIONS_ITEMS', response.appointment_durations)
        commit('SET_GRID_STEPS', response.grid_steps)
        commit('SET_APPOINTMENT_SOURCES', response.appointment_sources)
        commit('SET_APPOINTMENT_SYSTEM_SOURCE', response.appointment_system_source)
        commit('SET_APPOINTMENT_TYPES', response.appointment_types)
        commit('SET_APPOINTMENT_TYPE_ID_DEFAULT', response.default_appointment_type_id)
        commit('SET_DOCUMENT_TYPES', response.id_card_titles)
        commit('SET_CLIENT_GROUPS', response.client_groups)
        commit('SET_SMS_CONFIRMATION_DEFAULT', gon.application.messages_defaults.confirmation_default)
        commit('SET_SMS_NOTIFY_DEFAULT', gon.application.messages_defaults.notify_default)
        commit('SET_SMS_REMIND_DEFAULT', gon.application.messages_defaults.remind_default)
        commit('SET_LOADING_DOCTOR_SCHEDULE', false)
      })
  },

  loadAttractionSources ({ commit }) {
    fetchAttractionSourcesList()
      .then((response) => {
        commit('SET_ATTRACTION_SOURCES', response)
      })
  },

  createAppointmentFromOutside ({ commit }, params) {
    const mode = params.clientPhone ? MODE_PHONE_INSERT : MODE_CLIENT_INSERT

    commit('SET_MODE_GRID_SCHEDULE', mode)
    commit('SET_INSERT_PARAMS_APPOINTMENT', { ...params })
  },

  editAppointmentFromCall ({ commit }, appointment) {
    commit('SET_APPOINTMENT_FROM_CALL', appointment)
  },

  createWaitingItemFromCall ({ commit }, client) {
    commit('SET_WAITING_ITEM_FROM_CALL', client)
  },

  moveAppointmentFromCall ({ commit }, appointment) {
    commit('SET_ACTIVE_APPOINTMENT', appointment)
    commit('SET_MODE_GRID_SCHEDULE', $.scheduleGrid.MODE_MOVE)
  },

  setScheduleDate ({ commit }, scheduleDate) {
    commit('SET_SCHEDULE_DATE', scheduleDate)
    updateStorage(DOCTOR_SCHEDULES_STORAGE, { scheduleDate })
  },

  setScheduleDuration ({ commit }, scheduleDuration) {
    commit('SET_SCHEDULE_DURATION', scheduleDuration)
    updateStorage(DOCTOR_SCHEDULES_STORAGE, { scheduleDuration })
  },

  setScheduleDaysCount ({ commit }, scheduleDaysCount) {
    commit('SET_SCHEDULE_DAYS_COUNT', scheduleDaysCount)
    updateStorage(DOCTOR_SCHEDULES_STORAGE, { scheduleDaysCount })
  },

  setHideUsersWithoutSchedule ({ commit }, flag) {
    commit('SET_SIDEBAR_HIDE_USERS_WITHOUT_SCHEDULE_STATE', flag)
    updateStorage(DOCTOR_SCHEDULES_SIDEBAR_STORAGE, { hideUsersWithoutSchedule: flag })
  },

  setHideCabinetsWithoutSchedule ({ commit }, flag) {
    commit('SET_SIDEBAR_HIDE_CABINETS_WITHOUT_SCHEDULE_STATE', flag)
    updateStorage(DOCTOR_SCHEDULES_SIDEBAR_STORAGE, { hideCabinetsWithoutSchedule: flag })
  },

  setHideAppointmentsSchedule ({ commit }, flag) {
    commit('SET_SIDEBAR_HIDE_APPOINTMENTS_SCHEDULE_STATE', flag)
    updateStorage(DOCTOR_SCHEDULES_SIDEBAR_STORAGE, { hideAppointmentsSchedule: flag })
  },

  setShowOnlyWorkingDays ({ commit }, flag) {
    commit('SET_SIDEBAR_SHOW_ONLY_WORKING_DAYS_STATE', flag)
    updateStorage(DOCTOR_SCHEDULES_SIDEBAR_STORAGE, { showOnlyWorkingDays: flag })
  },

  setShowOnlyNewPatients ({ commit }, flag = false) {
    commit('SET_SIDEBAR_SHOW_ONLY_NEW_PATIENTS_STATE', flag)
    updateStorage(DOCTOR_SCHEDULES_SIDEBAR_STORAGE, { showOnlyNewPatients: flag })
  },

  setFiltrationAppointmentsStatuses ({ commit }, values = []) {
    commit('SET_SIDEBAR_FILTRATION_APPOINTMENTS_STATUSES', values)
    updateStorage(DOCTOR_SCHEDULES_SIDEBAR_STORAGE, { filtrationAppointmentsStatuses: values })
  },

  setFiltrationAppointmentsTypes ({ commit }, values = []) {
    commit('SET_SIDEBAR_FILTRATION_APPOINTMENTS_TYPES', values)
    updateStorage(DOCTOR_SCHEDULES_SIDEBAR_STORAGE, { filtrationAppointmentsTypes: values })
  },

  setFiltrationClientGroups ({ commit }, values = []) {
    commit('SET_SIDEBAR_FILTRATION_CLIENT_GROUPS', values)
    updateStorage(DOCTOR_SCHEDULES_SIDEBAR_STORAGE, { filtrationClientGroups: values })
  },

  setActiveScheduleStore ({ commit }, schedule) {
    commit('SET_ACTIVE_SCHEDULE', schedule)
    updateStorage(DOCTOR_SCHEDULES_STORAGE, { activeSchedule: schedule })
  },

  setShowLeftPanelStore ({ commit }, show) {
    commit('SET_SHOW_LEFT_PANEL_STATE', show)
    updateStorage(DOCTOR_SCHEDULES_STORAGE, { showLeftPanel: show })
  },

  setActiveSidebarStore ({ commit }, sidebar) {
    commit('SET_ACTIVE_SIDEBAR', sidebar)
    updateStorage(DOCTOR_SCHEDULES_STORAGE, { activeSidebar: sidebar })
  },

  setShowRightPanelStore ({ commit }, show) {
    commit('SET_SHOW_RIGHT_PANEL_STATE', show)
    updateStorage(DOCTOR_SCHEDULES_STORAGE, { showRightPanel: show })
  },

  resetInsertParams ({ commit }) {
    commit('SET_INSERT_PARAMS_APPOINTMENT', getDefaultInsertParams())
  },
}

/**
 * Обновляет данные в localStorage после коммита
 * @param {string} key
 * @param {object} data
 */
function updateStorage (key, data) {
  localStorage.setItem(key, JSON.stringify({
    ...JSON.parse(localStorage.getItem(key)),
    ...data,
  }))
}

export function getDefaultInsertParams () {
  return {
    type: null,
    callId: null,
  }
}

/**
 * @param {string} date
 * @param {string} endFormat
 * @returns {string}
 */
function extractScheduleDate (date, endFormat) {
  if (date) {
    return moment(date, [Utils.rubyDateFormat, endFormat]).format(endFormat)
  }

  return moment().format(endFormat)
}

export default {
  state,
  getters,
  mutations,
  actions,
}
