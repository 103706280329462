import { request } from '@/lib/transport/request'
import { getAllAdapter } from '@/api_entities/egisz/med_documents/med_document_adapters'
import { cloneDeep } from 'lodash'

const ROOT_KEY = 'medical_document'

export const medDocumentEndpoint = {
  name: 'medDocumentEndpoint',
  getAll (filters) {
    const options = {
      type: 'post',
      url: Routes.list_egisz_medical_documents_path(),
      data: {
        sort_params: getAllAdapter.toServer(cloneDeep(filters)),
      },
    }

    return request(options, getAllAdapter.toClient).promise
  },

  get (id) {
    /* реализация получения записи по id */
  },

  submit (data) {
    return data.id
      ? medDocumentEndpoint.update(data)
      : medDocumentEndpoint.create(data)
  },

  create (data) {
    const options = {
      type: 'post',
      url: Routes.egisz_medical_documents_path(),
      data: {
        [ROOT_KEY]: data,
      },
    }

    return request(options).promise
  },

  update (data) {
    const options = {
      type: 'patch',
      url: Routes.egisz_medical_document_path(data.id),
      data: {
        [ROOT_KEY]: data,
      },
    }

    return request(options).promise
  },

  destroy (id) {
    const options = {
      type: 'delete',
      url: Routes.egisz_medical_document_path(id),
    }

    return request(options).promise
  },

  restore (id) {
    /* реализация восстановления записи по id */
  },

  addMedDocument (data) {
    const options = {
      type: 'post',
      url: Routes.egisz_iemk_add_med_record_path(),
      data,
    }

    return request(options).promise
  },

  sign (medDocumentId, data) {
    const options = {
      type: 'post',
      url: Routes.sign_egisz_medical_document_path(medDocumentId),
      data,
    }

    return request(options).promise
  },
}
