import AbstractFormatter from '@/lib/formatters/AbstractFormatter'

export default class UserFormatter extends AbstractFormatter {
  name (doctor) {
    if (!doctor) return ''

    const middleName = doctor.secondName || doctor.second_name

    return [
      doctor.surname,
      doctor.name ? doctor.name.charAt(0).toUpperCase() + '.' : '',
      middleName ? middleName.charAt(0).toUpperCase() + '.' : '',
    ]
      .filter(Boolean)
      .join(' ')
  }

  /**
   * Алиас для name
   * @param doctor
   * @return {string|string}
   */
  shortName (doctor) { return this.name(doctor) }

  fullName (doctor) {
    if (!doctor) return ''
    const middleName = doctor.secondName || doctor.second_name

    return [doctor.surname, doctor.name, middleName].join(' ').trim()
  }

  specialties (doctor) {
    if (!doctor) return ''

    return doctor.specialties.map((specialty) => specialty.title).join(', ')
  }
}

UserFormatter.namespace = 'user'
