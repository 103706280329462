import { doctorEndpoint } from '@/api_entities/doctors/doctor_endpoint'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { fetchAttendingDoctorsAdapter } from '@/api_entities/doctors/doctor_adapters'

export const doctorPresenter = {
  /**
   * Выбор клиники в users/select_clinic
   * @param userId
   * @param clinicId
   * @returns {Promise<*>}
   */
  selectClinic (userId, clinicId) {
    if (!userId || !clinicId) {
      throw new Error('userId or clinicId not found')
    }

    const data = {
      user: { current_clinic_id: clinicId },
    }

    return doctorEndpoint.selectClinic(userId, data)
      .catch(Utils.reportError(reportErrorText('failed', 'select_clinic')))
  },

  /**
   * @param clinicId
   * @returns {Promise<*>}
   */
  filterByClinicId (clinicId) {
    const data = { ...Utils.getQueryParams(), clinic_id: clinicId, page: 1 }
    if (!clinicId || clinicId === true) { delete data.clinic_id }

    return Promise.resolve(Turbolinks.visit(Routes.users_path(data)))
  },

  fetchRoles (userId) {
    const dataAdapter = (response) => Array.from(new Set(response.map((item) => item.id)))

    return doctorEndpoint.fetchRoles(
      { user_id: userId },
      dataAdapter
    )
      .catch(Utils.reportError(
        'doctorPresenter.fetchRoles()',
        reportErrorText('list_message', 'roles'))
      )
  },

  fetchAll (sortParams, toClientAdapter = (data) => data) {
    return doctorEndpoint.getAll(sortParams)
      .then((data) => Promise.resolve(toClientAdapter(data)))
  },

  fetchAttendingDoctors (params) {
    const __params = camelToSnake(params)

    return doctorEndpoint.fetchAttendingDoctors(__params).promise
      .then((response) => Promise.resolve(fetchAttendingDoctorsAdapter.toClient(snakeToCamel(response))))
      .catch((err) => {
        Utils.reportError(
          'doctorPresenter::fetchAttendingDoctors()',
          reportErrorText('list_message', 'attendingDoctors'))(err)

        return Promise.resolve([])
      })
  },

  fetchUsers (params) {
    const __params = camelToSnake(params)

    return doctorEndpoint.fetchUsers(__params).promise
      .then((response) => Promise.resolve(snakeToCamel(response)))
      .catch((err) => {
        Utils.reportError(
          'doctorPresenter::fetchAttendingDoctors()',
          reportErrorText('list_message', 'attendingDoctors'))(err)

        return Promise.resolve([])
      })
  },
}
