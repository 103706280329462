import { request } from '@/lib/transport/request'

export const companiesEndpoint = {
  find (params) {
    return request({
      type: 'GET',
      url: Routes.search_companies_path(),
      data: params,
    }).promise
  },

  get (id, params) {
    return request({
      type: 'GET',
      url: Routes.fetch_company_company_path(id),
      data: params,
    })
  },

  create (company) {
    return request({
      type: 'POST',
      url: Routes.companies_path(),
      data: { company },
    }).promise
  },

  update (id, company) {
    return request({
      type: 'PATCH',
      url: Routes.company_path(id),
      data: { company },
    }).promise
  },

  destroy (id) {
    return request({
      type: 'DELETE',
      url: Routes.company_path(id),
    }).promise
  },

  getCompanyTypes () {
    return request({
      type: 'GET',
      url: Routes.fetch_all_company_types_path(),
    }).promise
  },

  getOrders (companyId, params) {
    return request({
      type: 'GET',
      url: Routes.fetch_company_orders_company_path(companyId),
      data: params,
    }).promise
  },

  getPayments (companyId, params) {
    return request({
      type: 'GET',
      url: Routes.fetch_payments_company_path(companyId),
      data: params,
    }).promise
  },

  /**
   * @param {number|string} companyId
   * @param {object} [params]
   * @return {Promise<*>}
   */
  getDocuments (companyId, params) {
    return request({
      type: 'GET',
      url: Routes.fetch_documents_company_path(companyId),
      data: params,
    }).promise
  },
}
