import AbstractFormatter from '@/lib/formatters/AbstractFormatter'

export default class OrderFormatter extends AbstractFormatter {
  paidStatus (value) {
    const { paidStatuses } = gon.specific

    return t(`_order_statuses.${Object.keys(paidStatuses).find((key) => paidStatuses[key] === value)}`)
  }
}

OrderFormatter.namespace = 'order'
