import {
  APPOINTMENT_ICONS_BY_STATUS,
  CALL_CONFIRMATION_STATUS,
  CALL_CONFIRMATION_STATUS_ICONS,
  FILTERS,
} from '@/vue_apps/DoctorSchedules/AppointmentCallLog/store/const'
import { APPOINTMENT_STATUS } from '@/vue_components/appointment/const'

export const getAppointmentStatuses = () => {
  return Object.entries(APPOINTMENT_STATUS)
    .map(([key, value]) => ({
      id: APPOINTMENT_STATUS[key],
      icon: APPOINTMENT_ICONS_BY_STATUS[value],
      title: t(value, { scope: 'enums.appointment.status' }),
      iconCss: `appointment-status-icon__${APPOINTMENT_STATUS[key]}`,
    }))
}

export const getCallConfirmationStatuses = () => {
  return Object.entries(CALL_CONFIRMATION_STATUS)
    .map(([key, value]) => ({
      id: CALL_CONFIRMATION_STATUS[key],
      icon: CALL_CONFIRMATION_STATUS_ICONS[value],
      title: t(value, { scope: 'enums.appointment.call_confirmation_status' }),
      iconCss: `appointment-call-status-icon__${CALL_CONFIRMATION_STATUS[key]}`,
    }))
}

export const getFilters = () => {
  const today = moment().startOf('day')

  const appointmentStatuses = getAppointmentStatuses()
  const callConfirmationStatuses = getCallConfirmationStatuses()

  return {
    [FILTERS.CLINIC]: null,
    [FILTERS.DATE]: [today.toDate(), today.toDate()],
    [FILTERS.SPECIALTY]: null,
    [FILTERS.USER]: null,
    [FILTERS.STATUS]: [
      appointmentStatuses.find(({ id }) => id === APPOINTMENT_STATUS.NEED_APPROVAL),
      appointmentStatuses.find(({ id }) => id === APPOINTMENT_STATUS.CONFIRMED_BY_ADMINISTRATOR),
    ].filter(Boolean),
    [FILTERS.CALL_CONFIRMATION_STATUS]: [
      callConfirmationStatuses.find(({ id }) => id === CALL_CONFIRMATION_STATUS.PENDING),
      callConfirmationStatuses.find(({ id }) => id === CALL_CONFIRMATION_STATUS.FAILED_TO_CONNECT),
    ].filter(Boolean),
  }
}
