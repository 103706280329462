import { clinicEndpoint } from '@/api_entities/clinics/clinic_endpoint'
import { reportErrorText } from '@/vue_components/egisz/egisz_module/const/egisz_errors/egisz_errors'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { DoctorClinicsCache } from '@/api_entities/doctors/DoctorClinicsCache/DoctorClinicsCache'

export const clinicPresenter = {
  fetchActiveClinicsByUserId (userId) {
    const cache = new DoctorClinicsCache(userId)

    const restoredData = cache.restore()
    if (restoredData) { return Promise.resolve(restoredData) }

    const params = {
      user_id: userId,
      only_active: true,
    }

    return clinicEndpoint.fetchAll(params)
      .then((response) => {
        cache.save(response.data)

        return Promise.resolve(response.data)
      })
      .catch(Utils.reportError(
        'clinicPresenter:fetchActiveClinicsByUserId()',
        reportErrorText('list_message', 'clinics')
      ))
  },

  fetchLicensesNumber () {
    return clinicEndpoint.fetchLicensesNumber()
      .then((response) => Promise.resolve(parseInt(response)))
      .catch(Utils.reportError(
        'clinicPresenter:fetchLicensesNumber()',
        reportErrorText('read_message', 'licenses_number')
      ))
  },

  fetchClinicAddress (clinicId) {
    return clinicEndpoint.fetchClinicAddress(clinicId)
      .then(MRequestSuccess.onResponse(snakeToCamel))
      .catch(MRequestError.withDefault({}))
  },
}
