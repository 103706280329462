import { cloneDeep } from 'lodash'

export const state = {
  isReady: false,
  /** @type {TGonApplication | {}} */
  appConf: {},
  /** @type {TGonLocalization} */
  localization: {
    locale: '',
    country: '',
    date_format: '',
    currency: {
      id: -1,
      code_number: -1,
      code_string: '',
      currency_symbol_position: '',
      decimal_character: '',
      symbol_character: '',
      title: '',
    },
  },
  specific: {},
  documentTypes: [],
  clientGroups: [],
}

export const getters = {
  vxGetIsChangeableMedcardNumbers: (state) => state.appConf.changeable_medcard_numbers,

  GET_APP_CONF (state) {
    return state.appConf
  },

  GET_APP_CONF_CURRENT_CLINIC_ID (state) {
    return state.appConf.current_clinic.id
  },

  GET_APP_CONF_CURRENT_CLINIC_LPU_ID (state) {
    return state.appConf.current_clinic.lpu_id
  },

  GET_APP_CONF_CLINIC_LPU_ID_BY_CLINIC_ID: (state) =>
    (clinicId) => {
      const clinic = state.appConf.current_user_clinics.find((clinic) => clinic.id === clinicId)
      if (!clinic) { return null }

      return clinic.lpu_id
    },

  GET_APP_CONF_CURRENT_USER_ID (state) {
    return state.appConf.current_user.id
  },

  GET_LOCALIZATION_DATE_FORMAT (state) {
    return state.localization.date_format.toUpperCase()
  },

  GET_LOCALIZATION_DATE_TIME_FORMAT (state) {
    return state.localization.date_format.toUpperCase() + ' HH:mm'
  },

  GET_LOCALIZATION_DATEPICKER_FORMAT (state) {
    return state.localization.date_format.replace('mm', 'MM')
  },

  GET_LOCALIZATION_LOCALE (state) {
    return state.localization.locale
  },

  GET_LOCALIZATION_COUNTRY (state) {
    return state.localization.country
  },

  GET_LOCALIZATION_CURRENCY_CODE_STRING (state) {
    return state.localization.currency.code_string
  },

  GET_APP_CONF_CLIENT_WIDGET_MODULE_ENABLED (state) {
    return state.appConf.client_widget_enabled
  },

  GET_APP_CONF_SMS_MODULE_ENABLED (state) {
    return state.appConf.sms_module_enabled
  },

  GET_APP_CONF_WHATSAPP_MODULE_ENABLED (state) {
    return state.appConf.whatsapp_module_enabled
  },

  GET_APP_CONF_TELEPHONY_MODULE_ENABLED (state) {
    const selectedProvider = state.appConf?.telephony.selected_provider
    if (!selectedProvider) { return false }

    return state.appConf?.telephony[selectedProvider]
  },

  vxGetAppConfWhatsappModuleEnabled: (state) => state.appConf?.whatsapp_module_enabled,

  GET_APP_CONF_BRANCH (state) {
    return state.appConf.branch
  },

  GET_APP_CONF_ASSIGN_BONUS_POINTS (state) {
    return state.appConf.assign_bonus_points
  },

  GET_APP_CONF_COMMENT_FOR_ASSIGN_BONUS_POINTS_REQUIRED (state) {
    return state.appConf.comment_for_assign_bonus_points_required
  },

  GET_LOCALIZATION_LOCALE_COUNTRY (state) {
    return `${state.localization.locale}-${state.localization.country}`
  },

  GET_LOCALIZATION_CURRENCY_PARAMS (state, getters) {
    return {
      code: getters.GET_LOCALIZATION_CURRENCY_CODE_STRING,
      locale: getters.GET_LOCALIZATION_LOCALE_COUNTRY,
    }
  },

  GET_LOCALIZATION_CURRENCY_SYMBOL (state) {
    return state.localization.currency.symbol_character
  },

  GET_LOCALIZATION_DAYS_WEEK_START (state) {
    return state.localization.day_week_start
  },

  GET_DOCUMENT_TYPES (state) {
    return state.documentTypes
  },

  GET_CLIENT_GROUPS (state) {
    return state.clientGroups
  },

  ENV_IS_DEVELOPMENT (state) {
    return state.appConf.env === 'development'
  },

  GET_APP_CONF_MESSAGES_CONFIRM (state) {
    return state.appConf.messages.appointment_confirmation
  },

  GET_APP_CONF_MESSAGES_NOTIFY (state) {
    return state.appConf.messages.notify
  },

  GET_APP_CONF_MESSAGES_REMIND (state) {
    return state.appConf.messages.remind
  },

  GET_APP_CONF_CURRENT_CLINIC_NAME: (state) => state.appConf.current_clinic.title,

  GET_APP_CONF_EGISZ_ENABLED: (state) => state.appConf.egisz_module_enabled,
}

export const mutations = {
  SET_DOCTORS (state, doctors) {
    state.doctors = doctors
  },
  SET_SPECIALTIES (state, specialties) {
    state.specialties = specialties
  },
  SET_CURRENT_USER_CLINICS (state, clinics) {
    state.currentUserClinics = clinics
  },
  SET_APP_CONF (state, payload) {
    state.appConf = payload
  },

  SET_LOCALIZATION (state, payload) {
    state.localization = payload
  },

  SET_SPECIFIC (state, payload) {
    state.specific = payload
  },

  SET_DOCUMENT_TYPES (state, payload) {
    state.documentTypes = payload
  },

  SET_CLIENT_GROUPS (state, payload) {
    state.clientGroups = payload
  },

  SET_IS_READY (state, isReady) {
    state.isReady = isReady
  },
}

export const actions = {
  CONFIGURE_STORE ({ commit, state, dispatch }) {
    if (!state.isReady) {
      // YAGNI говорит, что этот флаг здесь сейчас не нужен, но мы запасливые
      commit('SET_IS_READY', false)
      commit('SET_APP_CONF', cloneDeep(gon.application))
      commit('SET_LOCALIZATION', cloneDeep(gon.localization))
      commit('SET_SPECIFIC', cloneDeep(gon.specific))
      dispatch('windowStore/vxSubscribeOnWindowEvents')
      commit('SET_IS_READY', true)
    }
  },
}
