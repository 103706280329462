import { onWsEntryActions } from '@/vue_components/store/modules/egisz/ws/actions'

export const EGISZ_CHANNEL = 'egisz'

export const ACTIONS = Object.freeze({
  CREATED: 'created',
  UPDATED: 'updated',
  DELETED: 'deleted',
})

export const ENTITIES = Object.freeze({
  PDF_GENERATED: 'pdf_generated',
  PDF_REMOVED: 'pdf_removed',
  ADD_PATIENT_FAILURE: 'add_patient_failure',
  MED_DOCUMENT: 'Egisz::MedicalRecord::MedicalDocument',
  MED_CASE: 'MedicalCase::Ambulatory',
  CLIENT: 'Client',
  SIGNATURE: 'Signature',
  ENTRY: 'Entry',
  USER: 'User',
  SUCCESSFULLY_SENT: 'successfully_sent',
  REGISTERED_IN_EGISZ: 'registered_in_egisz',
  MASS_SIGN: 'mass_sign',
  SENDING_ERROR: 'sending_error',
  ADDED_TO_QUEUE: 'added_to_queue',
  ADD_PATIENT: 'add_patient',
  IS_NOT_ACTUAL: 'is_not_actual',
  ORGANIZATION_SIGNATURES_REMOVED: 'organization_signatures_removed',
  ALL_SIGNATURES_REMOVED: 'all_signatures_removed',
})

export const TRIGGERS_MAP = Object.freeze({
  [ENTITIES.PDF_GENERATED]: 'pdf',
  [ENTITIES.MED_DOCUMENT]: 'medDocument',
  [ENTITIES.MED_CASE]: 'medCase',
  [ENTITIES.CLIENT]: 'client',
  [ENTITIES.SIGNATURE]: 'signature',
  [ENTITIES.ENTRY]: 'entry',
  [ENTITIES.USER]: 'user',
})

const EXCEPT_TRIGGERS = [ENTITIES.PDF_GENERATED, ENTITIES.PDF_REMOVED]

const state = {
  egiszConnection: null,
  wsEntities: {
    pdf: {
      id: 0,
      action: '',
    },

    medDocument: {
      id: 0,
      action: '',
    },

    medCase: {
      id: 0,
      action: '',
    },

    client: {
      id: 0,
      action: '',
    },

    signature: {
      id: 0,
      action: '',
    },

    entry: {
      id: 0,
      action: '',
    },

    user: {
      id: 0,
      action: '',
    },
  },
}

const getters = {
  vxGetWsEntities: (state) => state.wsEntities,
  vxGetLastPdf: (state) => state.wsEntities.pdf,
  vxGetLastMedDocument: (state) => state.wsEntities.medDocument,
  vxGetLastMedCase: (state) => state.wsEntities.medCase,
  vxGetLastClient: (state) => state.wsEntities.client,
  vxGetLastEntry: (state) => state.wsEntities.entry,
  vxGetLastUser: (state) => state.wsEntities.user,
}

const mutations = {
  vxSetWSConnection (state, payload) {
    state.egiszConnection = payload
  },

  vxSetEntityValues (state, { entity, values }) {
    state.wsEntities[entity] = values
  },
}

const actions = {
  vxEgiszWSConnect ({ commit, state }, channel) {
    if (state.egiszConnection) { return }

    const connection = Services.wsSubscriptions[channel].connect(({ data, meta, action }) => {
      console.info('egisz ws:', { data, meta, action })
      const metaObject = EXCEPT_TRIGGERS.includes(action)
        ? action
        : meta?.object

      const entity = TRIGGERS_MAP[metaObject]

      if (!entity) {
        console.info('Unknown received method')

        return
      }

      switch (metaObject) {
        case ENTITIES.ENTRY: onWsEntryActions(data); break
        default: break
      }

      if (data.deleted_at && action === ACTIONS.UPDATED) { return }

      const payload = { entity, values: { action, ...data } }
      commit('vxSetEntityValues', payload)
    })

    commit('vxSetWSConnection', connection)
  },
}

export const egiszWs = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
