export const loadEmployessRating = (params) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      url: '/client_feedbacks/feedbacks/employees_rating_sort',
      data: params,
      success (data) {
        resolve(data)
      },
      error: (error) => reject(error),
    })
  })
}
