import { MLocalStorage } from '@/_api/_storage/MLocalStorage'
import { GLOBAL_TIMES } from '@/_global_scripts/GLOBAL_CONSTS'

export class MExpirableCache extends MLocalStorage {
  /**
   * @param {string} key - ключ для localStorage
   * @param config
   * @param {{
   *   timeout?: number
   * }} [config] - время жизни в ms
   */
  constructor (key, config = {
    timeout: GLOBAL_TIMES.ONE_HOUR,
  }) {
    super(key)
    this.timeout = config?.timeout || GLOBAL_TIMES.ONE_HOUR
  }

  save (data) {
    const cacheData = {
      exp: new Date().getTime() + this.timeout,
      data,
    }

    super.save(cacheData)
  }

  restore () {
    const restoredData = super.restore()

    if (!restoredData) { return null }
    if (typeof restoredData !== 'object') { return null }

    try {
      const { exp, data } = restoredData

      if (!exp) { return null }
      if (exp <= new Date().getTime()) {
        this.drop()

        return null
      }

      return data
    } catch (e) {
      return null
    }
  }

  cleanExpired (keyTemplate = '') {
    if (!keyTemplate) { return }
    Object
      .keys(localStorage)
      .filter((key) => key.includes(keyTemplate))
      .forEach((key) => {
        try {
          if (JSON.parse(super.get(key)).exp < new Date().getTime()) { super.drop(key) }
        } catch (e) { super.drop(key) }
      })
  }
}
