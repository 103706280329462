import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { camelToSnake } from '@/_api/_requests/helpers'

export class MMessagesConfigurationPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: { one: Routes.messages_configurations_path, all () {} },
      entity: 'configurationsList',
      location: 'MMessagesConfigurations',
    })
  }

  fetchPriorities (priorityType) {
    const options = {
      url: Routes.fetch_priorities_messages_configurations_path(),
      data: { priority_type: priorityType },
    }

    const notification = new MRequestNotification(
      'MMessagesConfigurationPresenter:fetchPriorities',
      'fetchMessage',
      'prioritiesList'
    )

    return this.get(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault([], notification))
  }

  savePriorities (data) {
    const options = {
      url: Routes.update_priorities_messages_configurations_path(),
      data: camelToSnake(data),
    }

    const notification = new MRequestNotification(
      'MMessagesConfigurationPresenter:savePriorities',
      'updateMessage',
      'prioritiesList'
    )

    return this.post(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault([], notification))
  }
}
