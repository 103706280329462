const isNotObject = (something) => {
  return typeof something !== 'object' || Array.isArray(something) || something === null
}

export const flatObject = (obj) => {
  if (!obj) { return }

  const result = {}
  const keys = Object.keys(obj)

  keys.forEach((key) => {
    if (isNotObject(obj[key])) {
      result[key] = obj[key]

      return
    }

    const childObjKeys = Object.keys(obj[key])
    childObjKeys.forEach((childKey) => {
      result[`${key}_${childKey}`] = obj[key][childKey]
    })
  })

  return result
}
