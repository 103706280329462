import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { appointmentCallLogAdapter } from '@/_api/MAppointment/appointmentCallLogAdapter'
import { camelToSnake } from '@/_api/_requests/helpers'

export class MAppointmentPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: { one () {}, all () {} },
      location: 'MAppointmentPresenter',
      entity: 'appointment',
    })
  }

  /**
   * @legacy
   * @param filters
   */
  fetchAppointmentsWithFilters (filters) {
    const options = {
      url: Routes.fetch_appointments_with_filters_appointments_path(),
      data: appointmentCallLogAdapter.toServer(filters),
    }

    const notification = new MRequestNotification(
      this.location,
      'fetchMessage',
      this.entity
    )

    return this.get(options).promise
      .then(MRequestSuccess.onResponse(appointmentCallLogAdapter.toClient))
      .catch(MRequestError.withDefault([], notification))
  }

  /**
   * @legacy
   * @param {number} appointmentId
   * @param {TCallLogStatus} callConfirmationStatus
   * @return {Promise<*>}
   */
  updateCallConfirmationStatus ({ appointmentId, callConfirmationStatus }) {
    const options = {
      url: Routes.appointment_path(appointmentId),
      data: camelToSnake({
        appointment: {
          callConfirmationStatus: callConfirmationStatus.id,
        },
      }),
    }

    const notification = new MRequestNotification(
      this.location,
      'updateMessage',
      'callConfirmationStatus'
    )

    return this.patch(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }

  /**
   * @legacy
   * @param {number} appointmentId
   * @param {TCallLogStatus} callConfirmationStatus
   * @return {Promise<*>}
   */
  updateStatus ({ appointmentId, status }) {
    const options = {
      url: Routes.appointment_path(appointmentId),
      data: camelToSnake({
        appointment: { status },
      }),
    }

    const notification = new MRequestNotification(
      this.location,
      'updateMessage',
      'appointmentStatus'
    )

    return this.patch(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }

  /**
   * @param {{ id: number, note: string }} payload
   * @return {Promise<*>}
   */
  updateNote ({ id, note }) {
    const options = {
      url: Routes.appointment_path(id),
      data: camelToSnake({
        appointment: { note },
      }),
    }

    const notification = new MRequestNotification(
      this.location,
      'updateMessage',
      'appointmentNote'
    )

    return this.patch(options).promise
      .then(MRequestSuccess.withNotify(undefined, notification))
      .catch(MRequestError.onRequest(notification))
  }
}
