import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { camelToSnake } from '@/_api/_requests/helpers'

export class MCallsConfigurationsPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: { one () {}, all () {} },
      entity: 'configurationsList',
      location: 'MCallsConfigurationsPresenter',
    })
  }

  fetchCallsConfigurations () {
    const options = {
      url: Routes.api_internal_messages_flash_call_configuration_path(),
    }

    const notification = new MRequestNotification(
      'MCallsConfigurationsPresenter:fetchCallsConfigurations',
      'fetchMessage',
      'configurationsList'
    )

    return this.post(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault('', notification))
  }

  fetchBalanceCallsConfigurations (configurations) {
    const options = {
      url: Routes.fetch_balance_api_internal_messages_flash_call_configuration_path(),
      data: camelToSnake(configurations),
    }

    return this.post(options).promise
      .then((data) => {
        Utils.reportSuccess(t('whatsapp.successAuthorisation'))()

        return data
      })
      .catch(() => {
        Utils.reportError('messageStore:vxFetchSmsBalance', t('whatsapp.incorrectAuthorisationMessage'))()

        return null
      })
  }

  fetchPriorities () {
    const options = {
      url: Routes.fetch_widget_priorities_api_internal_messages_configurations_path(),
    }

    const notification = new MRequestNotification(
      'MCallsConfigurationsPresenter:fetchPriorities',
      'fetchMessage',
      'prioritiesList'
    )

    return this.get(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault([], notification))
  }

  saveConfigurations (configurations) {
    const options = {
      url: Routes.api_internal_messages_flash_call_configuration_path(),
      data: camelToSnake({ configuration: configurations }),
    }

    const notification = new MRequestNotification(
      'MCallsConfigurationsPresenter:saveConfigurations',
      'submitMessage',
      'configurationsList'
    )

    return this.patch(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault('', notification))
  }
}
