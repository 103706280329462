import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { DEFAULT_TOTAL_PAGES } from '@/vue_components/store/modules/filters_base'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { camelToSnake, snakeToCamel } from '@/_api/_requests/helpers'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { MListService } from '@/_api/_requests/MListService'

export class MWorkplacePresenter extends MPresenterBase {
  constructor () {
    super({
      entity: 'workplace',
      location: 'MWorkplacePresenter',
      routes: {
        one () {},
        all () {},
        list: Routes.list_workplaces_path,
      },
    })
  }

  /**
   * @param data
   * @param config
   * @return {Promise<{totalItems: *, data: *, totalPages: number} | { errors: Record<string, string[]> }>}
   */
  list (data = true, config = {}) {
    const notification = new MRequestNotification(
      this.location,
      'fetchAllMessage',
      'workplaces'
    )

    const options = {
      url: this.routes.list(),
      data: camelToSnake({ connected: data.connected }),
    }

    return this.get(options, snakeToCamel)
      .promise
      .then((data) => {
        return {
          data,
          totalItems: data.length,
          totalPages: DEFAULT_TOTAL_PAGES,
        }
      })
      .catch(MRequestError.withDefault(MListService.getDefaultList(), notification))
  }
}
