import { MPresenterBase } from '@/_api/_requests/MPresenterBase'
import { MRequestNotification } from '@/_api/_requests/MRequestNotification'
import { MRequestSuccess } from '@/_api/_requests/MRequestSuccess'
import { MRequestError } from '@/_api/_requests/MRequestError'
import { camelToSnake } from '@/_api/_requests/helpers'
import { MWazzupAdapter } from '@/_api/Deliveries/MWazzupAdapter'

export class MWazzupPresenter extends MPresenterBase {
  constructor () {
    super({
      routes: { one () {}, all () {} },
      entity: 'whatsappMessages',
      location: 'MWhatsappPresenter',
    })
  }

  fetchWazzupChannels () {
    const options = {
      url: Routes.messages_wazzup_channels_list_path(),
    }

    const notification = new MRequestNotification(
      'MWazzupPresenter:fetchWazzupChannels',
      'fetchAllMessage',
      'channels'
    )

    return this.get(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault('', notification))
  }

  fetchWazzupTemplates () {
    const options = {
      url: Routes.messages_wazzup_templates_list_path(),
    }

    const notification = new MRequestNotification(
      'MWazzupPresenter:fetchWazzupTemplates',
      'fetchAllMessage',
      'template'
    )

    return this.get(options).promise
      .then(MRequestSuccess.onResponse(MWazzupAdapter.toClient))
      .catch(MRequestError.withDefault([], notification))
  }

  setWebHookKey (apiKey) {
    const options = {
      url: Routes.messages_wazzup_set_web_hook_key_path(),
      data: camelToSnake(apiKey),
    }

    const notification = new MRequestNotification(
      'MWazzupPresenter:setWebHookKey',
      'setWebHookKey',
      'template'
    )

    return this.post(options).promise
      .then(MRequestSuccess.onResponse())
      .catch(MRequestError.withDefault([], notification))
  }
}
